import {
  Badge,
  Button,
  createStyles,
  Divider,
  Drawer,
  Grid,
  List,
  MenuItem,
  Select,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
  WithStyles,
  withTheme
} from '@material-ui/core';
import { Event } from '@material-ui/icons';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import AssignmentTwoToneIcon from '@material-ui/icons/AssignmentTwoTone';
import BallotIcon from '@material-ui/icons/Ballot';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HeadsetMicTwoToneIcon from '@material-ui/icons/HeadsetMicTwoTone';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import InsertChartTwoToneIcon from '@material-ui/icons/InsertChartTwoTone';
import LanguageTwoToneIcon from '@material-ui/icons/LanguageTwoTone';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import clsx from 'clsx';
import translate from 'counterpart';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import Translate from 'react-translate-component';
import history from '../../../config/history';
import { locales } from '../../../config/translation';
import LanguageDialog from '../dialog/LanguageDialog';
import SupportDialog from '../dialog/SupportDialog';
import UseWarrantyModalWindow from '../dialog/warranty/UseWarrantyModalWindow';
import WarrantyModalWindow from '../dialog/warranty/WarrantyModalWindow';
import MainMenuItem from './MainMenuItem';
import PointItem from './PointItem';
import { loadCutsNumber } from '../../../reducers/balances';
import {
  countNotificationsByPoint,
  fetchNotificationsByPoint,
} from '../../../reducers/notifications';
import { generateWeek } from '../../../utils/date-utils';

const devMode: boolean = process.env.NODE_ENV !== 'production';

const drawerWidth = 260;

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      paddingTop: '48px'
    },
    userNameContainer: {
      height: '64px',
      borderBottom: '1px solid ' + theme.colors.gray100,
      alignItems: 'center',
      padding: '16px',
      fontSize: '14px'
    },
    languageSelect: {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.colors.black + ' !important'
    },
    logoutButton: {
      fontSize: '14px',
      color: theme.colors.blue
    },
    userMenuList: {
      paddingTop: 0
    },
    menuButtons: {
      width: '100%',
      height: 56
    },
    menuButtonsContainer: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: '100%',
      margin: 0
    }
  });

var stompClientBooking = null;
var stompClientPrint = null;

interface MainMenuProps extends RouteComponentProps, WithStyles<typeof styles> {
  open: boolean;
  roles: string[];
  closeMenu: () => void;
  onLocaleChange: (lang) => void;
  currentLocale: string;
  username: string;
  point: any;
  organizationId: number;
  loadCutsNumber: ((organization) => any);
  countNotificationsByPoint: ((pointId, dateFrom, dateTo) => any);
  fetchNotificationsByPoint: ((pointId, dateFrom, dateTo) => any);
  numberOfNotifications: number;
  notifications: any;
}

const MainMenu: React.FunctionComponent<MainMenuProps> = (
  props: MainMenuProps
) => {
  const {
    open,
    currentLocale,
    onLocaleChange,
    closeMenu,
    username,
    roles,
    classes,
    location,
    point,
    organizationId,
    loadCutsNumber,
    countNotificationsByPoint,
    fetchNotificationsByPoint,
    notifications
  } = props;
  const [warrantyShow, setWarrantyShow] = useState(false);
  const [useWarrantyShow, setUseWarrantyShow] = useState(false);
  const [languageDialogOpen, setLanguageDialogOpen] = useState<boolean>(false);
  const [supportDialogOpen, setSupportDialogOpen] = useState<boolean>(false);
  const theme = useTheme();
  const dates: Date[] = generateWeek();
  const dispatch = useDispatch();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const menuAction = (action: () => void) => {
    action();
    if (isXs) {
      closeMenu();
    }
  };
  let numberOfNotifications = 0;
  notifications.map((notification: any) => {
    numberOfNotifications += notification.count;
  })
  useEffect(() => {
    if(roles.includes('ROLE_USER')) {
      connectBooking();
      connectPrint();
    }
    fetchNotificationsByPoint(point.id, dates[0], dates[6]);
  }, []);
  const connectPrint = () => {
    const Stomp = require('stompjs');
    let SockJS = require('sockjs-client');
    SockJS = new SockJS(devMode ? 'http://localhost:9080/api/ws' :'/api/ws' );
    stompClientPrint = Stomp.over(SockJS);
    stompClientPrint.connect({}, onConnectedPrint, onError);
  };
  const connectBooking = () => {
    const Stomp = require('stompjs');
    let SockJS = require('sockjs-client');
    SockJS = new SockJS(devMode ? 'http://localhost:9080/api/ws' :'/api/ws' );
    stompClientBooking = Stomp.over(SockJS);
    stompClientBooking.connect({}, onConnectedBooking, onError);
  };
  const onConnectedPrint = () => {
    console.log('connected print');
    stompClientPrint.subscribe(
      '/topic/messages/cuts/' + organizationId,
      onPrintMessageReceived
    );
  };
  const onConnectedBooking = () => {
    console.log('connected booking');
    stompClientBooking.subscribe(
      '/topic/messages/' + point.id,
      onBookingMessageReceived
    );
  };
  const onBookingMessageReceived = () => {
    fetchNotificationsByPoint(point.id, dates[0], dates[6]);
  };
  const onPrintMessageReceived = () => {
    dispatch(loadCutsNumber(organizationId));
  };
  const onError = (err) => {
    console.log(err);
  };
  return (
    <Drawer
      className={classes.drawer}
      variant={'persistent'}
      anchor={'left'}
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Grid
        container={true}
        direction={'column'}
        style={{ height: '100%', flexWrap: 'nowrap' }}
      >
        <Grid item={true}>
          <Grid container={true} className={classes.userNameContainer}>
            {username}
          </Grid>
        </Grid>
        <Grid item={true} xs={true}>
          <List
            className={clsx({
              [classes.userMenuList]: roles.includes('ROLE_USER')
            })}
          >
            {roles.includes('ROLE_USER') ? (
              <React.Fragment>
                <PointItem />
                <MainMenuItem
                  key={'main'}
                  icon={<HomeTwoToneIcon />}
                  text={translate('header.menu.main')}
                  selected={location.pathname.startsWith('/category')}
                  onClick={() => menuAction(() => history.push('/category'))}
                />
                {roles.includes('ROLE_BOOKING') &&
                <MainMenuItem
                  key={'booking/user'}
                  icon={
                    <Badge badgeContent={numberOfNotifications} color='secondary'>
                      <Event />
                    </Badge>
                  }
                  text={translate('header.menu.userBooking')}
                  selected={location.pathname === '/booking/user'}
                  onClick={() => {
                    menuAction(() => history.push('/booking/user'));
                  }}
                />}
                <MainMenuItem
                  key={'instructions'}
                  icon={<AssignmentIcon />}
                  text={translate('header.menu.instructions')}
                  selected={location.pathname === ('/instructions')}
                  onClick={() => {
                    menuAction(() => history.push('/instructions'));
                  }}
                />
                <MainMenuItem
                  key={'cutReports'}
                  icon={<AssignmentTwoToneIcon />}
                  text={translate('header.menu.cutReports')}
                  selected={location.pathname === '/cutReports'}
                  onClick={() => {
                    menuAction(() => history.push('/cutReports'))
                  }}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <MainMenuItem
                  key={'users'}
                  icon={<PeopleAltTwoToneIcon />}
                  text={translate('header.menu.users')}
                  selected={
                    location.pathname.startsWith('/user') ||
                    location.pathname.startsWith('/createUser')
                  }
                  onClick={() => menuAction(() => history.push('/users'))}
                />
                {roles.includes('ROLE_BOOKING') &&
                <MainMenuItem
                  key={'booking'}
                  icon={<Event />}
                  text={translate('header.menu.booking')}
                  selected={location.pathname === '/booking'}
                  onClick={() => menuAction(() => history.push('/booking'))}
                />}
                <MainMenuItem
                  key={'settings'}
                  icon={<BallotIcon />}
                  text={translate('header.menu.settings')}
                  selected={location.pathname === '/settings'}
                  onClick={() => menuAction(() => history.push('/settings'))}
                />
                <MainMenuItem
                  key={'reports'}
                  icon={<AssignmentTwoToneIcon />}
                  text={translate('header.menu.reports')}
                  selected={location.pathname === '/reports'}
                  onClick={() => menuAction(() => history.push('/reports'))}
                />
                {roles.includes('ROLE_RATING') && (
                  <MainMenuItem
                    key={'rating'}
                    icon={<InsertChartTwoToneIcon />}
                    text={translate('header.menu.rating')}
                    selected={location.pathname === '/rating'}
                    onClick={() => menuAction(() => history.push('/rating'))}
                  />
                )}
                <MainMenuItem
                  key={'balances'}
                  icon={<AccountBalanceWalletTwoToneIcon />}
                  text={translate('header.menu.balances')}
                  selected={location.pathname === '/balances'}
                  onClick={() => menuAction(() => history.push('/balances'))}
                />
                <MainMenuItem
                  key={'instructions'}
                  icon={<AssignmentIcon />}
                  text={translate('header.menu.instructions')}
                  selected={location.pathname === ('/instructions')}
                  onClick={() => {
                    menuAction(() => history.push('/instructions'));
                  }}
                />
              </React.Fragment>
            )}
          </List>
          {roles.includes('ROLE_USER') && roles.includes('ROLE_WARRANTY') && (
            <Grid
              container
              className={classes.menuButtonsContainer}
              spacing={2}
              direction={'column'}
              alignItems='stretch'
            >
              <Grid item>
                <Button
                  className={classes.menuButtons}
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => menuAction(() => setUseWarrantyShow(true))}
                >
                  <Translate content={'header.menu.useWarranty'} />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.menuButtons}
                  color={'primary'}
                  variant={'outlined'}
                  onClick={() => menuAction(() => setWarrantyShow(true))}
                >
                  <Translate content={'header.menu.warranty'} />
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Divider />
        <Grid item={true}>
          <List>
            <MainMenuItem
              key={'support'}
              icon={<HeadsetMicTwoToneIcon />}
              text={translate('header.menu.support')}
              onClick={() => setSupportDialogOpen(true)}
            />
            <MainMenuItem
              key={'language'}
              icon={<LanguageTwoToneIcon />}
              text={
                <Grid
                  container={true}
                  direction={'row'}
                  alignItems={'center'}
                  spacing={1}
                  style={{ flexWrap: 'nowrap' }}
                >
                  <Grid item={true}>
                    {translate('header.menu.language') + ':'}
                  </Grid>
                  <Grid item={true}>
                    <Select
                      disableUnderline={true}
                      value={currentLocale}
                      disabled={true}
                      className={classes.languageSelect}
                    >
                      {locales.map((lang: string) => (
                        <MenuItem key={lang} value={lang}>
                          {translate('label.language.' + lang)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              }
              onClick={() => setLanguageDialogOpen(true)}
            />
          </List>
        </Grid>
        <Divider />
        <Grid item={true}>
          <List>
            <Divider />
            <MainMenuItem
              key={'exit'}
              text={
                <Typography className={classes.logoutButton}>
                  <Translate content={'header.menu.logout'} />
                </Typography>
              }
              onClick={() => history.replace('/logout')}
            />
            <Divider />
          </List>
        </Grid>
      </Grid>
      <UseWarrantyModalWindow
        showUseWarranty={useWarrantyShow}
        handleShowUseWarranty={() => setUseWarrantyShow(false)}
      />
      <WarrantyModalWindow
        show={warrantyShow}
        handleShow={() => setWarrantyShow(false)}
      />
      <LanguageDialog
        open={languageDialogOpen}
        currentLocale={currentLocale}
        onSubmit={(locale: string) => {
          setLanguageDialogOpen(false);
          onLocaleChange(locale);
        }}
        onClose={() => setLanguageDialogOpen(false)}
      />
      <SupportDialog
        open={supportDialogOpen}
        onClose={() => setSupportDialogOpen(false)}
      />
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  username: state.authentication.username,
  roles: state.authentication.roles,
  point: state.points.selectedPoint,
  organizationId: state.authentication.organizationId,
  numberOfNotifications: state.notifications.all,
  notifications: state.notifications.items
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadCutsNumber: (organization) => {
      dispatch(loadCutsNumber(organization));
    },
    countNotificationsByPoint: (pointId, dateFrom, dateTo) => {
      dispatch(countNotificationsByPoint(pointId, dateFrom, dateTo));
    },
    fetchNotificationsByPoint: (pointId, dateFrom, dateTo) => {
      dispatch(fetchNotificationsByPoint(pointId, dateFrom, dateTo));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(MainMenu)))
);
