import translate from 'counterpart';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions
} from 'mui-datatables';
import * as React from 'react';
import {
  cellRender,
  csvDownload,
  textLabels
} from '../../../utils/table-utils';
import { mobileTranslate } from '../MobileTranslate';
import TablePaginationFooter from '../TablePaginationFooter';

const dateCellSort = (data, colIndex, order) => {
  return data.sort((a, b) => {
    if (colIndex === 0) {
      const aDateStr = a.data[colIndex].split('.');
      const bDateStr = b.data[colIndex].split('.');
      const aDate = new Date(
        aDateStr[2],
        aDateStr[1] - 1,
        aDateStr[0]
      ).getTime();
      const bDate = new Date(
        bDateStr[2],
        bDateStr[1] - 1,
        bDateStr[0]
      ).getTime();
      return (aDate < bDate ? -1 : 1) * (order === 'desc' ? 1 : -1);
    }
    return (
      (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
      (order === 'desc' ? 1 : -1)
    );
  });
};

interface BalancesTableProps {
  data?: any[];
  title?: string | React.ReactNode;
}

export const BalancesTable: React.FunctionComponent<BalancesTableProps> = (
  props: BalancesTableProps
) => {
  const { data, title } = props;
  const options: MUIDataTableOptions = {
    fixedHeader: false,
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true
    },
    filter: false,
    search: false,
    print: false,
    viewColumns: false,
    responsive: 'scrollMaxHeight',
    selectableRows: 'none',
    customSort: dateCellSort,
    onDownload: csvDownload,
    textLabels: textLabels(translate),
    customFooter: (
      rowCount: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (rowsPerPage: number) => void,
      changePage: any
    ) => (
      <TablePaginationFooter
        page={page}
        rowCount={rowCount}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
      />
    )
  };
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dateCreated',
      label: translate('report.label.colDateCreated'),
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: 'money',
      label: mobileTranslate('balances.label.money', 'balances.label.amount'),
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: 'cutPrice',
      label: mobileTranslate('balances.label.cutPrice', 'balances.label.price'),
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: 'cutsNumber',
      label: mobileTranslate(
        'balances.label.cutsNumber',
        'balances.label.count'
      ),
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: 'comment',
      label: translate('balances.label.comment'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender
      }
    }
  ];
  return (
    <MUIDataTable
      columns={columns}
      title={title}
      data={data}
      options={options}
    />
  );
};

export default BalancesTable;
