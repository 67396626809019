import { FilledTextFieldProps, TextField } from '@material-ui/core';
import * as React from 'react';

interface TextFieldProps extends FilledTextFieldProps {}

class FilledTextField extends React.Component<TextFieldProps> {
  render = (): React.ReactNode => {
    const { ...other } = this.props;
    return (
      <TextField
        fullWidth={true}
        variant={'filled'}
        size={'small'}
        {...other}
      />
    );
  };
}

export default FilledTextField;
