import * as React from 'react';
import Translate from 'react-translate-component';

export const AccessDenied: React.FunctionComponent = () =>
    <div className="content">
      <main className="main">
        <p><Translate content='access.denied'/></p>
      </main>
    </div>;

export default AccessDenied;
