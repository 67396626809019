import { Button, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as React from 'react';
import Translate from 'react-translate-component';

const styles = (theme: Theme) => createStyles({
  button: {
    color: theme.colors.gray,
    fontSize: '14px',
    lineHeight: '19px',
    textTransform: 'none'
  },
  icon: {
    color: theme.colors.lightGray
  }
});

interface BackButtonProps extends WithStyles<typeof styles> {
  onClick: (() => void);
}

const BackButton: React.FunctionComponent<BackButtonProps> = (props: BackButtonProps) => {
  const { classes, ...other } = props;
  return (
    <Button
      {...other}
      className={classes.button}
      startIcon={
        <ArrowBackIcon
          className={classes.icon} />
      }>
      <Translate content={'modelPage.back'} />
    </Button>
  );
};

export default withStyles(styles)(BackButton);
