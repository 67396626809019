import { Chip, createStyles, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { loadCutsNumber } from '../../../reducers/balances';
import Translate from 'react-translate-component';

const styles = ({ colors }: Theme) => createStyles({
  text: {
    fontSize: '14px',
    lineHeight: '24px',
    whiteSpace: 'nowrap'
  },
  chip: {
    height: 'auto',
    padding: '2px 8px'
  },
  chipLabel: {
    padding: 0,
    fontSize: '12px',
    lineHeight: '16px'
  },
  chipEmpty: {
    backgroundColor: colors.lightRed
  }
});

interface CutsCountProps extends WithStyles<typeof styles> {
  userOrgId: number;
  totalCuts: number;
  loadCutsNumber: ((organization) => any);
}

interface CutsCountState {
  showErrorModal: boolean;
}

export class CutsCount extends React.Component<CutsCountProps, CutsCountState> {

  interval: NodeJS.Timeout;

  readonly state: CutsCountState = {
    showErrorModal: false
  };

  componentDidMount = (): void => {
    this.props.loadCutsNumber(this.props.userOrgId);
  };

  render = (): React.ReactNode => {
    const { classes, totalCuts } = this.props;
    return (
      <Grid
        container={true}
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        style={{ flexWrap: 'nowrap' }}>
        <Grid item={true}>
          <Typography className={classes.text}>
            <Translate content={'header.cutsCount'}/>
          </Typography>
        </Grid>
        <Grid item={true}>
          <Chip
            color={'primary'}
            label={totalCuts}
            classes={{ label: classes.chipLabel }}
            className={classes.chip + ' ' + ((totalCuts <= 10) ? classes.chipEmpty : '')} />
        </Grid>
      </Grid>
    );
  };
}

const mapStateToProps = (state) => ({
  userOrgId: state.authentication.organizationId,
  totalCuts: state.balances.cutsNumber
});

const mapDispatchToProps = {
  loadCutsNumber
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CutsCount));
