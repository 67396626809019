import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import translate from 'counterpart';
import * as React from 'react';
import Translate from 'react-translate-component';
import AdaptiveDialog from '../AdaptiveDialog';
import moment from 'moment';
import { changeBookingTime, getAvailableTimes } from '../../../../reducers/booking';
import { connect } from 'react-redux';
import 'react-phone-input-2/lib/style.css';
import DayPicker from '../../field/DayPicker';
import TimePicker from '../../field/TimePicker';
import { CreateBookingDTO } from '../../../../models/CreateBookingDTO';
import { format, formatISO } from 'date-fns';
import { parseDateTime } from '../../../../utils/date-utils';
import { BookingType } from '../../../../models/BookingType';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      overflowY: 'visible',
      [theme.breakpoints.down('xs')]: {
        overflowY: 'auto'
      }
    },
    content: {
      padding: '16px 16px 32px',
      overflowY: 'visible'
    },
    contentTable: {
      display: 'flex',
      padding: 0
    },
    actions: {
      padding: '0px 16px 8px'
    },
    divider: {
      marginBottom: '4px'
    }
  });

interface Props extends WithStyles<typeof styles> {
  rowDate: Date;
  rowTime: string;
  bookingId: number;
  phone: string;
  type: string;
  pointId: number;
  show: boolean;
  handleShowSwapBooking: () => void;
  getAvailableTimes: ((date, pointId, type) => void);
  changeBookingTime: ((dto, bookingId, date) => void);
  times: string[],
  dateTime: Date
}

interface State {
  selectedDate: Date;
}

export class BookingSwapDialog extends React.Component<Props, State> {

  state: State = {
    selectedDate: new Date()
  };

  handleClose = (): void => {
    const { handleShowSwapBooking } = this.props;
    handleShowSwapBooking();
  };

  handleTime = (time: string) => {
    const { rowDate, rowTime, handleShowSwapBooking, bookingId, changeBookingTime, pointId } = this.props;
    const { selectedDate } = this.state;
    let year = selectedDate.getUTCFullYear();
    let month = selectedDate.getUTCMonth() + 1;
    let day = selectedDate.getUTCDate();
    let dateTime = new Date(moment(year + '-' + month + '-' + day + ' ' + time).format());
    const dto: CreateBookingDTO = {
      date: formatISO(parseDateTime(dateTime), { representation: 'date' }),
      time: format(parseDateTime(dateTime), 'HH:mm:ss'),
      tradePointId: pointId,
      type: null,
      modelServices: null,
      customerName: null,
      customerPhone: null,
      customerEmail: null,
      warrantyId: null
    };
    let y = rowDate.getUTCFullYear();
    let m = rowDate.getUTCMonth() + 1;
    let d = rowDate.getUTCDate();
    let date = new Date(moment(y + '-' + m + '-' + d + ' ' + rowTime).format());
    changeBookingTime(dto, bookingId, formatISO(parseDateTime(date), { representation: 'date' }));
    this.setState({selectedDate: new Date()})
    handleShowSwapBooking();
  };

  handleDate = (date: Date) => {
    const { getAvailableTimes, pointId, type } = this.props;
    this.setState({ selectedDate: date });
    getAvailableTimes(date, pointId, type);
  };

  render = (): React.ReactNode => {
    const { show, classes, rowTime, rowDate } = this.props;

    return (
      <AdaptiveDialog
        open={show}
        onClose={this.handleClose}
        title={translate('booking.dialog.addBooking') + ' ' + rowTime + ' ' + moment(rowDate).format('D MMMM, ddd')}
        closeButton={true}
        paperClass={classes.paper}
      >
        <DialogContent className={classes.content}>
          <Grid container={true}>
            <Grid container={true} direction={'column'} spacing={3}>
              <Grid item={true}>
                <DayPicker dateTime={this.props.dateTime} onChange={this.handleDate} />
              </Grid>
              <Grid item={true}>
                <Grid container={true} direction={'column'} spacing={1}>
                  <Grid item={true}>
                    <Typography variant={'body2'} color={'textSecondary'}>
                      {'Доступное время'}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <TimePicker times={this.props.times} onChange={this.handleTime} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider className={classes.divider} />
        <DialogActions className={classes.actions}>
          <Button
            variant={'contained'}
            color={'default'}
            type={'button'}
            onClick={this.handleClose}
          >
            <Translate content={'booking.dialog.close'} />
          </Button>
        </DialogActions>
      </AdaptiveDialog>
    );
  };
}

const mapDispatchToProps = {
  getAvailableTimes,
  changeBookingTime
};

const mapStateToProps = (state) => ({
  times: state.booking.times,
  dateTime: new Date()
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookingSwapDialog));
