import { Box, Button, createStyles, DialogActions, DialogContent, withStyles, WithStyles } from '@material-ui/core';
import translate from 'counterpart';
import { Form, Formik } from 'formik';
import * as React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import * as yup from 'yup';
import { clearItems, createWarranty, loadWarranty } from '../../../../reducers/warranty';
import AdaptiveDialog from '../AdaptiveDialog';
import WarrantyModalTable from './WarrantyModalTable';

const styles = createStyles({
  content: {
    padding: '40px 16px 24px',
    overflowY: 'inherit'
  },
  actions: {
    padding: '0px 16px 24px'
  }
});

interface WarrantyModalWindowProps extends WithStyles<typeof styles> {
  show: boolean;
  orgId: number;
  selectedPoint: any;
  items: any[];
  onSubmit: ((data) => void);
  clearItems: (() => void);
  handleShow: (() => void);
  loadWarranty: ((orgId, selectedPoint) => void);
}

interface WarrantyModalWindowState {
  selectedRows: any[];
}

export class WarrantyModalWindow extends React.Component<WarrantyModalWindowProps, WarrantyModalWindowState> {

  readonly state: WarrantyModalWindowState = {
    selectedRows: []
  };

  onSubmit = (values): void => {
    this.props.onSubmit(values);
    this.handleShow();
  };

  UNSAFE_componentWillReceiveProps = (nextProps: Readonly<WarrantyModalWindowProps>): void => {
    if (nextProps.show && !this.props.show) {
      this.props.loadWarranty(this.props.orgId, this.props.selectedPoint);
    }
  };

  handleShow = () => {
    if (this.props.show) {
      this.props.clearItems();
      this.setState({ selectedRows: [] });
    }
    this.props.handleShow();
  };

  handleRowSelect = (currentRowsSelected, allRowsSelected, setFieldValue) => {
    const { items } = this.props;
    const selectedTradeId = allRowsSelected.map(item => items[item.dataIndex].id);
    const selectedRows = allRowsSelected.map(item => item.dataIndex);
    setFieldValue('taskLogIds', selectedTradeId, true);
    this.setState({  selectedRows: selectedRows });
  };

  render = (): React.ReactNode => {
    const { items, classes } = this.props;
    const { selectedRows } = this.state;
    const originalDate = new Date();
    const initialValues = {
      phoneNumber: '',
      taskLogIds: []
    };
    const formSchema = yup.object({
      phoneNumber: yup.string().required(),
      taskLogIds: yup.array().required()
    });
    return (
      <AdaptiveDialog
        open={this.props.show}
        onClose={this.handleShow}
        title={translate('report.label.colDateCreated') + ': ' + originalDate.toLocaleDateString()}>
        <Formik
          onSubmit={this.onSubmit}
          isInitialValid={false}
          validationSchema={formSchema}
          initialValues={initialValues}>
          {props => {
            const { setFieldValue, values, isValid } = props;
            return (
              <Form>
                <DialogContent
                  className={classes.content}>
                  <Box marginBottom={3}>
                    <ReactPhoneInput
                      id={'phoneNumber'}
                      country={'ru'}
                      masks={{ th: '+.. ..........', lt: '+... ...-.-..-..' }}
                      value={values.phoneNumber}
                      onChange={(value) => setFieldValue('phoneNumber', value, true)} />
                  </Box>
                  <WarrantyModalTable
                    data={items}
                    selectedRows={selectedRows}
                    onRowsSelect={(currentRowsSelected, allRowsSelected) => this.handleRowSelect(currentRowsSelected, allRowsSelected, setFieldValue)} />
                </DialogContent>
                <DialogActions
                  className={classes.actions}>
                  <Button type={'submit'} variant={'contained'} color={'primary'} disabled={!isValid}>
                    <Translate content={'menu.warranty'} />
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </AdaptiveDialog>
    );
  };
}

const mapDispatchToProps = {
  loadWarranty,
  onSubmit: createWarranty,
  clearItems
};

const mapStateToProps = (state) => ({
  items: state.warranty.items,
  orgId: state.authentication.organizationId,
  selectedPoint: state.points.selectedPoint.id
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WarrantyModalWindow));
