import { createStyles, Grid, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as React from 'react';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import history from '../../../config/history';
import { activateUser, deactivateUser, fetchUsers, newUser, openUser, removeUser } from '../../../reducers/users';
import AdaptiveDialog from '../dialog/AdaptiveDialog';
import MobileTranslate from '../MobileTranslate';
import Page from '../Page';
import UserCard from './UserCard';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  titleContainer: {
    display: 'table',
    borderSpacing: theme.spacing(1)
  },
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  cardContainer: {
    height: 'min-content',
    [theme.breakpoints.down('xs')]: {
      margin: '-8px',
      width: 'calc(100% + 16px)'
    }
  },
  card: {
    [theme.breakpoints.down('xs')]: {
      padding: '8px !important'
    }
  },
  count: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.colors.brono,
    padding: '4px 8px',
    background: 'rgba(0, 182, 180, 0.1)',
    border: '1px solid ' + theme.colors.brono,
    borderRadius: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  }
});

interface UserListProps extends WithStyles<typeof styles> {
  orgId: number;
  fetchUsers: ((id) => void);
  removeUser: ((id) => void);
  openUser: ((id) => void);
  onActivateUser: ((id) => void);
  onDeactivateUser: ((id) => void);
  newUser: (() => void);
  items: {
    id: number;
    email: string;
    active: boolean;
    points: any[];
  }[];
}

interface UserListState {
  deleteModalOpen: boolean;
  userToDelete: number;
}

export class UserList extends React.Component<UserListProps, UserListState> {

  readonly state: UserListState = {
    deleteModalOpen: false,
    userToDelete: null
  };

  componentDidMount = (): void => {
    this.props.fetchUsers(this.props.orgId);
  };

  handleCloseDeleteModal = (): void => {
    this.setState({ deleteModalOpen: false, userToDelete: null });
  };

  handleOpenDeleteModal = (id): void => {
    this.setState({ deleteModalOpen: true, userToDelete: id });
  };

  deleteUser = (): void => {
    const { removeUser } = this.props;
    const { userToDelete } = this.state;
    if (userToDelete && removeUser) {
      removeUser(userToDelete);
    }
    this.handleCloseDeleteModal();
  };

  renderActionsBar = (): React.ReactNode => {
    const { items, classes, newUser } = this.props;
    return (
      <Grid
        container={true}
        direction={'row'}
        justify={'space-between'}
        alignItems={'center'}
        spacing={2}
        style={{ flexWrap: 'nowrap' }}>
        <Grid item={true}>
          <Grid
            container={true}
            direction={'row'}
            alignItems={'center'}
            spacing={1}
            style={{ flexWrap: 'nowrap' }}>
            <Grid item={true}>
              <Typography className={classes.title}>
                <Translate content={'users.users'} />
              </Typography>
            </Grid>
            <Grid item={true}>
              <div className={classes.count}>
                {items.length}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <Button
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={() => newUser()}>
            <MobileTranslate
              desktop={'users.addUser'}
              mobile={'users.add'} />
          </Button>
        </Grid>
      </Grid>
    );
  };

  render = (): React.ReactNode => {
    const { items, openUser, onActivateUser, onDeactivateUser, classes } = this.props;
    const { deleteModalOpen } = this.state;
    return (
      <Page actions={this.renderActionsBar()}>
        <Grid
          container={true}
          direction={'row'}
          spacing={3}
          className={classes.cardContainer}>
          {items.map((item) =>
            <Grid
              item={true}
              key={item.id}
              lg={4} sm={6} xs={12}
              className={classes.card}>
              <UserCard
                id={item.id}
                email={item.email}
                active={item.active}
                points={item.points}
                onActivateUser={onActivateUser}
                onDeactivateUser={onDeactivateUser}
                deleteUser={this.handleOpenDeleteModal}
                openUser={openUser} />
            </Grid>
          )}
        </Grid>
        <AdaptiveDialog
          closeButton
          open={deleteModalOpen}
          onClose={this.handleCloseDeleteModal}>
          <DialogContent>
            <DialogContentText>
              <Translate content={'users.deleteModal.deleteBody'} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDeleteModal} variant="outlined" color="primary">
              <Translate content={'users.deleteModal.cancel'} />
            </Button>
            <Button onClick={this.deleteUser} variant="contained" color="primary" autoFocus>
              <Translate content={'users.deleteModal.delete'} />
            </Button>
          </DialogActions>
        </AdaptiveDialog>
      </Page>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    items: state.users.items,
    orgId: state.authentication.organizationId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (id) => {
      dispatch(fetchUsers(id));
    },
    openUser: (id) => {
      dispatch(openUser(id));
    },
    newUser: () => {
      dispatch(newUser());
      history.push('createUser');
    },
    removeUser: (id) => {
      dispatch(removeUser(id));
    },
    onActivateUser: (id) => {
      dispatch(activateUser(id));
    },
    onDeactivateUser: (id) => {
      dispatch(deactivateUser(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserList));
