import { createStyles, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Translate from 'react-translate-component';
import { Booking } from '../../../models/Booking';
import { RootState } from '../../../reducers';
import { fetchAllBookings } from '../../../reducers/booking';
import Page from '../Page';
import BookingTable from './BookingTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '24px',
      lineHeight: '32px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        lineHeight: '24px'
      }
    },
    tableContainer: {
      flexWrap: 'nowrap',
      height: 'calc(100% + 24px)',
      [theme.breakpoints.down('xs')]: {
        margin: '-8px -8px -16px',
        width: 'calc(100% + 16px)'
      }
    },
    tableItem: {
      maxHeight: '100%',
      [theme.breakpoints.down('xs')]: {
        padding: '0px !important',
        marginBottom: '-8px',
        maxHeight: '100%'
      }
    }
  })
);

interface StateProps {
  bookings: Booking[];
}

const AdminBookingList: FC = () => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const { bookings } = useSelector<RootState, StateProps>(
    (state: RootState) => ({
      bookings: state.booking.items
    })
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllBookings(selectedDate));
  }, []);
  const handleDayChange = (date: Date): void => {
    setSelectedDate(date);
    dispatch(fetchAllBookings(date));
  };
  return (
    <Page
      actions={
        <Grid container={true} justify={'space-between'} alignItems={'center'}>
          <Grid item={true}>
            <Typography className={classes.title}>
              <Translate content={'booking.title'} />
            </Typography>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container={true}
        direction={'column'}
        spacing={3}
        className={classes.tableContainer}
      >
        <Grid item={true} xs={true} className={classes.tableItem}>
          <BookingTable
            dateTime={selectedDate}
            bookings={bookings}
            onDayChange={handleDayChange}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default AdminBookingList;
