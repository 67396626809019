import * as React from 'react';
import Translate from 'react-translate-component';
import AdaptiveDialog from './AdaptiveDialog';
import {
  Button,
  createStyles,
  DialogContent,
  Grid,
  WithStyles,
  withStyles
} from '@material-ui/core';
import ReactPlayer from 'react-player';

const styles = createStyles({
  button: {
    width: '100%'
  },
  content: {
    padding: '40px 16px 24px',
    width: '100%'
  },
});

interface VideoDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: (() => void);
  url: string;
}

const VideoDialog: React.FunctionComponent<VideoDialogProps> = (props: VideoDialogProps) => {
  const { url, open, onClose, classes } = props;

  return (
    <AdaptiveDialog open={open} onClose={onClose}>
      <DialogContent
        className={classes.content}>
        <Grid
          container
          direction={'column'}
          spacing={2}>
          <Grid item>
            <ReactPlayer
              url={url}
              controls={true}
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              color={'primary'}
              variant={'outlined'}
              onClick={() => onClose()}>
              <Translate content={'video.cancel'} />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </AdaptiveDialog>
  );
};

export default withStyles(styles)(VideoDialog);