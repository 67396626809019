import { Button, createStyles, DialogContent, Grid, withStyles, WithStyles } from '@material-ui/core';
import translate from 'counterpart';
import * as React from 'react';
import Translate from 'react-translate-component';
import { locales } from '../../../config/translation';
import AdaptiveDialog from './AdaptiveDialog';

const styles = createStyles({
  content: {
    padding: '40px 16px 24px'
  }
});

interface LanguageDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  currentLocale: string;
  onSubmit: ((locale: string) => void);
  onClose: (() => void);
}

const LanguageDialog: React.FunctionComponent<LanguageDialogProps> = (props: LanguageDialogProps) => {
  const { open, currentLocale, onClose, onSubmit, classes } = props;
  return (
    <AdaptiveDialog
      open={open}
      title={translate('label.language.select')}
      onClose={onClose}>
      <DialogContent
        className={classes.content}>
        <Grid
          container={true}
          direction={'column'}
          spacing={2}>
          {locales.map((locale: string) => (
            <Grid item={true} key={locale}>
              <Button
                color={'primary'}
                variant={currentLocale === locale ? 'contained' : 'outlined'}
                onClick={() => onSubmit(locale)}
                style={{ width: '100%' }}>
                <Translate content={'label.language.' + locale} />
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </AdaptiveDialog>
  );
};

export default withStyles(styles)(LanguageDialog);
