import { Button } from '@material-ui/core';
import translate from 'counterpart';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableMeta,
  MUIDataTableOptions
} from 'mui-datatables';
import * as React from 'react';
import Translate from 'react-translate-component';
import history from '../../../../config/history';
import { csvDownload, textLabels } from '../../../../utils/table-utils';

interface UseWarrantyModalTableProps {
  data?: any[];
  close: () => void;
}

export const UseWarrantyModalTable: React.FunctionComponent<UseWarrantyModalTableProps> = (
  props: UseWarrantyModalTableProps
) => {
  const { data, close } = props;

  const reDirect = (warranty) => {
    close();
    const categoryId = warranty[6];
    const brandId = warranty[4];
    const modelId = warranty[5];
    const warrantyId = warranty[0];
    const resType = warranty[8];
    history.push(`/category/${categoryId}/brand/${brandId}/model/${modelId}/templates?warrantyId=${warrantyId}&resType=${resType}`);
  };

  const typeCellRender = (
    value: any,
    tableMeta: MUIDataTableMeta
  ): React.ReactNode => (<div>
      {tableMeta.rowData[12] ?
        tableMeta.rowData[12] :
        value
          ? translate('side.template.' + value, { fallback: value }).toUpperCase()
          : null}
    </div>
  );

  const warrantyCellRender = (
    value: any,
    tableMeta: MUIDataTableMeta
  ): React.ReactNode => {
    if (tableMeta.rowData[1] == null) {
      return <Translate content="warranty.templateDeleted" />;
    }
    switch (value) {
      case 'NOT_STARTED': {
        return <Translate content="warranty.notStarted" />;
      }
      case 'ENDED': {
        return <Translate content="warranty.ended" />;
      }
      case 'ACTIVE': {
        return (
          <Button
            variant={'contained'}
            color={'primary'}
            size={'large'}
            onClick={() => reDirect(tableMeta.rowData)}
          >
            <Translate content="warranty.use" />
          </Button>
        );
      }
      case 'USED': {
        return (
          <span>
            <Translate content="warranty.usedWarranty" />
            {'\n' + tableMeta.rowData[2] + ', ' + tableMeta.rowData[3]}
          </span>
        );
      }
      default: {
        return '';
      }
    }
  };
  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    responsive: 'scrollFullHeight',
    selectableRows: 'none',
    fixedHeader: false,
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true
    },
    search: false,
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    onDownload: csvDownload,
    textLabels: textLabels(translate)
  };
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      options: {
        display: 'false'
      }
    },
    {
      name: 'templateId',
      options: {
        display: 'false'
      }
    },
    {
      name: 'dateUsed',
      options: {
        display: 'false'
      }
    },
    {
      name: 'tradePoint',
      options: {
        display: 'false'
      }
    },
    {
      name: 'brandId',
      options: {
        display: 'false'
      }
    },
    {
      name: 'modelId',
      options: {
        display: 'false'
      }
    },
    {
      name: 'categoryId',
      options: {
        display: 'false'
      }
    },
    {
      name: 'dateCreated',
      label: translate('warranty.dateCreated'),
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: 'modelName',
      label: translate('warranty.model'),
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: 'resType',
      label: translate('warranty.resType'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: typeCellRender
      }
    },
    {
      name: 'phone',
      label: translate('warranty.phone'),
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: 'status',
      label: translate('warranty.warranty'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: warrantyCellRender
      }
    },
    {
      name: 'templateCustomName',
      options: {
        display: 'false'
      }
    }
  ];
  return (
    <MUIDataTable title={''} columns={columns} data={data} options={options} />
  );
};

export default UseWarrantyModalTable;
