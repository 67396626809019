import { createStyles, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { findDevices } from '../../../../reducers/devices';
import translate from 'counterpart';
import FilledTextField from '../../field/FilledTextField';
import { Device } from '../../../../models/Device';
import BookingServiceCheckboxEdit from './BookingServiceCheckboxEdit';
import { TradePointService } from '../../../../models/TradePointService';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      boxShadow: 'none',
      margin: '0px'
    },
    services: {
      margin: '24px 0 0 0'
    }
  })
);

interface Props {
  onSelect?: (device: Device, index: number) => void;
  onSelectServices?: (deviceId: number, serviceIds: number[]) => void;
  device: Device;
  pointId: number;
  services: TradePointService[];
  selectedServices: number[];
  index: number;
}

interface StateProps {
  devices: Device[];
}

const BookingDeviceAutoEdit: React.FC<Props> = (props: Props) => {
  const defaultDevice: Device = { id: props.device.id, name: props.device.name };
  const pointId = props.pointId;
  const services = props.services;
  const selectedServices = props.selectedServices;
  const dispatch = useDispatch();
  const { onSelect, onSelectServices, index } = props;
  const classes = useStyles();
  const [device, setDevice] = useState<Device>(defaultDevice);
  const [service, setService] = useState<number[]>(null);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>(null);
  const { devices } = useSelector<RootState, StateProps>(
    (state: RootState) => ({
      devices: state.devices.items
    })
  );
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    if (timerId) {
      clearTimeout(timerId);
    }
    let newTimerId: NodeJS.Timeout = setTimeout(() => {
      dispatch(findDevices(value));
    }, 500);
    setTimerId(newTimerId);
  };
  const handleChange = (e: ChangeEvent<{}>, value: any): void => {
    setDevice(value);
    if (onSelect) {
      onSelect(value, index);
    }
  };
  const handleLoad = (): Device => {
    return device;
  };
  const handleSelectServices = (index: number, servicesIds: number[]): void => {
    onSelectServices(index, servicesIds);
  };
  return (
    <Grid>
      <Grid>
        <Autocomplete
          freeSolo={false}
          value={device ? device : {}}
          options={devices}
          defaultValue={handleLoad}
          filterOptions={(options: Device[]) => options}
          getOptionSelected={(option: Device, value: Device) => {
            return option.id === value.id;
          }}
          getOptionLabel={(option: Device) => option.name}
          noOptionsText={translate('booking.dialog.selectNoOptions')}
          onChange={handleChange}
          className={classes.paper}
          renderInput={(params: AutocompleteRenderInputParams) => {
            return (
              <FilledTextField
                {...params}
                variant={'filled'}
                label={translate('booking.dialog.device')}
                InputProps={{ ...params.InputProps }}
                onChange={handleInputChange}
              />
            );
          }}
        />
      </Grid>
      <Grid className={classes.services}>
        <BookingServiceCheckboxEdit onSelect={handleSelectServices}
                                    deviceId={device}
                                    storeId={pointId}
                                    services={services}
                                    selectedServices={selectedServices}
                                    index={index} />
      </Grid>
    </Grid>
  );
};

export default BookingDeviceAutoEdit;
