import { connect } from 'react-redux';
import { redirectToDenied, redirectToLoginWithMessage, redirectToMain } from '../reducers/authentication';
import { PrivateRoute, PrivateRouteProps } from './PrivateRoute';

interface HasCutsRouteProps extends PrivateRouteProps {
  totalCuts: number;
  redirectToMain: (() => void);
}

class HasCutsRoute extends PrivateRoute<HasCutsRouteProps> {

  redirectIfNot(): void {
    const { loading, totalCuts, redirectToMain } = this.props;
    super.redirectIfNot();
    if (!loading && totalCuts && !(totalCuts > 0)) {
      redirectToMain();
    }
  }
}

const mapStateToProps = (state) => ({
  roles: state.authentication.roles,
  loading: state.authentication.loading,
  isAuthenticated: state.authentication.isAuthenticated,
  totalCuts: state.balances.cutsNumber
});

const mapDispatchToProps = {
  redirectToMain,
  redirectToDenied,
  redirectToLoginWithMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(HasCutsRoute);
