import translate from 'counterpart';
import { DayOfWeek, Week, WorkInterval } from '../models/Schedule';
import { StringUtils } from './StringUtils';

export const isValidTime = (value: string): boolean => {
  if (StringUtils.isBlank(value)) {
    return true;
  }
  const rawValue: string = value.trim();
  if (!/^\d{2}:\d{2}(:\d{2})?$/.test(rawValue)) {
    return false;
  }
  const values: string[] = rawValue.split(':');
  if (values.length !== 2 && values.length !== 3) {
    return false;
  }
  const hours: number = Number(values[0]);
  if (hours < 0 || hours > 23) {
    return false;
  }
  const minutes: number = Number(values[1]);
  if (minutes < 0 || minutes > 59) {
    return false;
  }
  if (values.length === 3) {
    const seconds: number = Number(values[2]);
    if (seconds < 0 || seconds > 59) {
      return false;
    }
  }
  return true;
};

export const formatTime = (time: string): string => {
  if (!StringUtils.isBlank(time)) {
    const timeArray: string[] = time.split(':');
    if (timeArray.length > 1) {
      return timeArray[0] + ':' + timeArray[1];
    } else if (timeArray.length > 0) {
      return timeArray[0];
    }
  }
  return null;
};

export const formatWorkIntervals = (intervals: WorkInterval[]): string => {
  return intervals
    .map((interval: WorkInterval) => {
      return formatTime(interval.start) + '-' + formatTime(interval.end);
    })
    .join(', ');
};

export interface DayTime {
  day: DayOfWeek;
  time: string;
}

export interface WorkTime {
  shortName: string;
  time: string;
}

export const formatDay = (day: DayOfWeek): string => {
  switch (day) {
    case DayOfWeek.MONDAY: {
      return translate('picker.label.mon');
    }
    case DayOfWeek.TUESDAY: {
      return translate('picker.label.tue');
    }
    case DayOfWeek.WEDNESDAY: {
      return translate('picker.label.wed');
    }
    case DayOfWeek.THURSDAY: {
      return translate('picker.label.thu');
    }
    case DayOfWeek.FRIDAY: {
      return translate('picker.label.fri');
    }
    case DayOfWeek.SATURDAY: {
      return translate('picker.label.sat');
    }
    case DayOfWeek.SUNDAY: {
      return translate('picker.label.sun');
    }
  }
};

export const formatWeek = (week: Week) => {
  if (week) {
    const dayTimeArray: DayTime[] = [];
    for (let day in week) {
      if (week.hasOwnProperty(day)) {
        dayTimeArray.push({
          day: DayOfWeek[day],
          time: formatWorkIntervals(week[day])
        });
      }
    }
    dayTimeArray.filter((dayTime: DayTime) => {
      return !StringUtils.isBlank(dayTime.time);
    });
    const workTimeArray: WorkTime[] = [];
    for (let i = 0; i < dayTimeArray.length; i++) {
      workTimeArray.push({
        shortName: formatDay(dayTimeArray[i].day),
        time: dayTimeArray[i].time
      });
    }
    let output: string = '';
    for (let i = 0; i < workTimeArray.length; i++) {
      output += StringUtils.isBlank(workTimeArray[i].time)
        ? ''
        : workTimeArray[i].shortName + ': ' + workTimeArray[i].time;
      output += i === workTimeArray.length - 1 ? '' : '; ';
    }
    return output;
  }
  return null;
};
