import {
  createStyles,
  CssBaseline,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { connect } from 'react-redux';
import { getSession } from '../../reducers/authentication';
import { setLocale } from '../../reducers/locale';
import { choosePoint, fetchPointsList } from '../../reducers/points';
import Header from './header/Header';
import MainMenu from './menu/MainMenu';
import TradePointList from './TradePointList';

const drawerWidth = 260;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh'
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
      transition: theme.transitions.create(['margin', 'display'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    },
    drawerHeader: {
      minHeight: '48px'
    }
  });

interface AppProps extends WithStyles<typeof styles> {
  isAuthenticated: boolean;
  currentLocale: string;
  selectedPoint: any;
  tradePoints: any[];
  roles: string[];
  getSession: () => void;
  setLocale: (locale: string) => void;
  fetchPointsList;
  choosePoint;
  children: any;
}

interface AppState {
  open: boolean;
}

class App extends React.Component<AppProps, AppState> {
  readonly state: AppState = {
    open: true
  };

  componentDidMount() {
    this.props.getSession();
  }

  closeMenu = () => {
    this.setState({ open: false });
  };

  handleMenu = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  fetchPointsListWithRoles = () => {
    const { fetchPointsList, roles } = this.props;
    fetchPointsList(roles);
  }

  choosePointWithRoles = (id) => {
    const { choosePoint, roles } = this.props;
    choosePoint(id, roles);
  }

  render = (): React.ReactNode => {
    const {
      classes,
      currentLocale,
      setLocale,
      isAuthenticated,
      selectedPoint,
      tradePoints,
      roles,
      children
    } = this.props;
    const { open } = this.state;
    if (isAuthenticated) {
      return (
        <div className={classes.root}>
          <CssBaseline />
          {selectedPoint ? (
            <div>
              <Header handleMenu={this.handleMenu} />
              <MainMenu
                open={open}
                closeMenu={this.closeMenu}
                currentLocale={currentLocale}
                onLocaleChange={setLocale}
              />
            </div>
          ) : null}
          <main
            className={clsx({
              [classes.content]: selectedPoint,
              [classes.contentShift]: open && selectedPoint
            })}
            style={{ flexGrow: 1 }}
          >
            <div className={classes.drawerHeader} />
            {selectedPoint ? (
              children
            ) : (
              <TradePointList
                roles={roles}
                fetchPointsList={this.fetchPointsListWithRoles}
                choosePoint={this.choosePointWithRoles}
                items={tradePoints}
              />
            )}
          </main>
        </div>
      );
    } else {
      return <React.Fragment>{children}</React.Fragment>;
    }
  };
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  currentLocale: state.locale.currentLocale,
  selectedPoint: state.points.selectedPoint,
  roles: state.authentication.roles,
  tradePoints: state.points.items
});

const mapDispatchToProps = {
  getSession,
  setLocale,
  fetchPointsList,
  choosePoint
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App));
