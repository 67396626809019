import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ChangeEvent, useState } from 'react';

interface Service {
  id: number;
  name: string;
}

const useStyles = makeStyles({
  title: {
    marginBottom: '4px'
  },
  label: {
    alignItems: 'start',
    marginLeft: '-12px',
    marginRight: '12px',
    width: '100%'
  },
  box: {
    marginTop: '-10px'
  }
});

interface Props {
  defaultChecked?: boolean;
  service: Service;
  onChange?: (checked: boolean) => void;
}

const ServiceItem: React.FC<Props> = (props: Props) => {
  const { service, onChange, defaultChecked } = props;
  const [checked, setChecked] = useState<boolean>(defaultChecked);
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={{ root: classes.label }}
      control={
        <Checkbox
          checked={checked}
          color={'primary'}
          classes={{ root: classes.box }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setChecked(e.target.checked);
            if (onChange) {
              onChange(e.target.checked);
            }
          }}
        />
      }
      label={
        <Grid>
          <Typography variant={'body1'} className={classes.title}>
            {service.name}
          </Typography>
        </Grid>
      }
    />
  );
};

export default ServiceItem;