const FETCH_INSTRUCTIONS = 'instructions/FETCH';
const FETCH_INSTRUCTIONS_SUCCESS = 'instructions/FETCH_SUCCESS';
const FETCH_INSTRUCTIONS_FAIL = 'instructions/FETCH_FAIL';

export const initialState = {
  items: []
};

// Reducer

export default function InstructionsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INSTRUCTIONS:
      return {
        ...state,
        items: []
      };
    case FETCH_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        items: action.result.data
      };
    default:
      return state;
  }
}

// Actions

export function fetchInstructions() {
  return {
    types: [FETCH_INSTRUCTIONS, FETCH_INSTRUCTIONS_SUCCESS, FETCH_INSTRUCTIONS_FAIL],
    promise: client => client.get('/api/instructions/list')
  };
}

