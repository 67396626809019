import { createStyles, Dialog, IconButton, Theme, withStyles, WithStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import * as React from 'react';

const styles = (theme: Theme) => createStyles({
  dialogTitle: {
    minHeight: '40px',
    margin: 0,
    padding: '8px 56px 8px 16px',
    borderBottom: '1px solid ' + theme.colors.gray400
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.colors.gray
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      width: '100%',
      verticalAlign: 'bottom',
      borderRadius: 0,
      maxWidth: '100% !important'
    }
  }
});

interface AdaptiveDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  title?: string;
  onClose: (() => void);
  closeButton?: boolean;
  paperClass?: string;
  disableBackdropClick?: boolean;
}

export const AdaptiveDialog: React.FunctionComponent<AdaptiveDialogProps> = (props: AdaptiveDialogProps & { children?: React.ReactElement }) => {
  const { open, onClose, title, closeButton, classes, paperClass, children, disableBackdropClick } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={'body'}
      maxWidth={'xl'}
      disableEscapeKeyDown={disableBackdropClick ? disableBackdropClick : false}
      disableBackdropClick={disableBackdropClick ? disableBackdropClick : false}
      classes={{ paper: clsx(classes.paper, paperClass) }}>
      {(closeButton || title) && (
        <MuiDialogTitle
          disableTypography={true}
          className={classes.dialogTitle}>
          <Typography className={classes.title}>
            {title}
          </Typography>
          <IconButton
            aria-label={'close'}
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
      )}
      {children}
    </Dialog>
  );
};

export default withStyles(styles)(AdaptiveDialog);
