import { createStyles, Grid, Hidden, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import translate from 'counterpart';
import * as React from 'react';
import { connect } from 'react-redux';
import history from '../../../config/history';
import brand, { chooseBrand } from '../../../reducers/brand';
import { chooseModel, setSearchName } from '../../../reducers/model';
import BackButton from '../BackButton';
import BrandSearch, { searchModelConfiguration } from '../BrandSearch';
import Page from '../Page';
import ListItem from './ListItem';
import Translate from 'react-translate-component';

const styles = (theme: Theme) => createStyles({
  titleContainer: {
    display: 'table',
    borderSpacing: theme.spacing(1)
  },
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px'
    }
  }
});

interface SearchResultProps extends WithStyles<typeof styles> {
  items: any[];
  totalCuts: number;
  brands: any;
  setSearchName: (name: string) => void;
  searchName: string;
}

export class SearchResult extends React.Component<SearchResultProps> {

  handleBackClick = () => {
    history.push('/category');
    this.props.setSearchName('');
  }

  render = (): React.ReactNode => {
    const { items, searchName, setSearchName, totalCuts, brands, classes } = this.props;
    const renderActionsBar = (
      <Grid
        container={true}
        direction={'column'}
        spacing={2}
        style={{ margin: '0px -8px' }}>
        <Hidden smUp={true}>
          <Grid item={true}>
            <BrandSearch autoFocus />
          </Grid>
        </Hidden>
        <Grid item={true}>
          <Grid
            container={true}
            direction={'row'}
            justify={'space-between'}
            alignItems={'center'}
            spacing={2}
            style={{ flexWrap: 'nowrap' }}>
            <Grid item={true}>
              <Grid
                container={true}
                direction={'row'}
                alignItems={'center'}
                justify={'flex-start'}
                spacing={2}>
                <Grid item={true}>
                  <BackButton onClick={this.handleBackClick} />
                </Grid>
              </Grid>
            </Grid>
            <Hidden xsDown={true}>
              <Grid item={true}>
                <BrandSearch autoFocus />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    );

    const itemProps = {
      action: (id) => {
        let model = items.find(o => o.id == id);
        setSearchName('');
        model && history.push(`/category/${model.category.id}/brand/${model.brandId}/model/${id}/templates`);
      },
      totalCuts: totalCuts
    };
    const error: string = totalCuts <= 0
      ? translate('side.label.noCuts')
      : null;
    let brandItems = [];
    items.forEach((item) => {
      if (!item.active) {
        return;
      }

      if (!brandItems[item.brandId]) {
        brandItems[item.brandId] = { brand: null, items: [] };
      }

      brandItems[item.brandId]['brand'] = brands.find((brand) => brand.id == item.brandId);
      brandItems[item.brandId]['items'].push(item);
    });

    const renderMessage = (): React.ReactElement => {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          {(searchName && searchName.length >= searchModelConfiguration.startLength) &&
          <Typography className={classes.title}>
            <Translate content="brandPage.noSearchResult" with={{ searchName }} />
          </Typography>}
        </Grid>
      )
    }

    const renderList = (): React.ReactElement | null => {
      return (
        <Grid
          container={true}
          direction={'column'}
          spacing={3}
          style={{ height: 'min-content' }}>
          {brandItems.map((item) =>
            <Grid item key={item.brand.id}>
              <div className={classes.title}>
                {item.brand.name}
              </div>
              <Grid
                container={true}
                direction={'row'}
                spacing={3}
                style={{ height: 'min-content' }}>
                {item.items.map((item) =>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={item.id}>
                    <ListItem key={item.id} item={item} {...itemProps} />
                  </Grid>
                )}
              </Grid>
            </Grid>)}
        </Grid>
      );
    }

    return (
      <Page
        error={error}
        actions={renderActionsBar}>
        {brandItems.length ? renderList() : renderMessage()}
      </Page>
    );
  };
}

const mapStateToProps = (state) => ({
  items: state.model.items,
  searchName: state.model.searchName,
  brands: state.brand.items,
  totalCuts: state.balances.cutsNumber,
  currentLocale: state.locale.currentLocale
});

const mapDispatchToProps = {
  chooseBrand,
  chooseModel,
  setSearchName
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchResult));
