import {
  createStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  withStyles,
  WithStyles,
  Typography
} from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import * as React from 'react';
import { connect } from 'react-redux';
import {clearPoint} from '../../../reducers/points';
import Translate from 'react-translate-component';

const styles = ({ colors, spacing }: Theme) => createStyles({
  item: {
    minHeight: '48px',
    color: colors.lightGray,
    backgroundColor: '#F5F7FA',
    marginBottom: spacing(1),
    '&:hover': {
      color: colors.hover
    }
  },
  icon: {
    color: 'inherit'
  },
  point: {
    fontSize: 12,
    color: '#5F636B'
  },
  pointName: {
    fontSize: 14,
    color: '#050D29'
  }
});

interface PointItemProps extends WithStyles<typeof styles> {
  selectedPoint: any;
  clearPoint: (() => void);
}

const PointItem: React.FunctionComponent<PointItemProps> = (props) => {
    const { clearPoint, selectedPoint, classes } = props;

    return (
      <ListItem
        key={'tradePoint'}
        button={true}
        onClick={clearPoint}
        classes={{ root: classes.item }}>
        <ListItemText>
          <Typography className={classes.point}>
            <Translate content={'header.menu.tradePoint'}/>
          </Typography>
          <Typography className={classes.pointName}>
            {selectedPoint.name}
          </Typography>
        </ListItemText>
        <ListItemIcon className={classes.icon}>
          <SwapHorizIcon/>
        </ListItemIcon>
      </ListItem>
    );
};

const mapStateToProps = (state) => ({
  selectedPoint: state.points.selectedPoint
});

const mapDispatchToProps = {
  clearPoint
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PointItem));
