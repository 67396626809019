const LOAD = 'report/LOAD';
const LOAD_SUCCESS = 'report/LOAD_SUCCESS';
const LOAD_FAIL = 'report/LOAD_FAIL';

export const initialState = {
  items: [],
};

// Reducer

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        items: action.result.data
      };
    default:
      return state;
  }
}

// Actions

export function loadReport(organization, tradePoint, dateFrom, dateTo) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get('/api/reports/list', {
      params: {
        orgId: organization,
        tradePointId: tradePoint,
        dateFrom: dateFrom,
        dateTo: dateTo
      }
    })
  };
}
