import { applyMiddleware, compose, createStore, StoreEnhancer } from 'redux';
import loggerMiddleware from 'redux-logger';
import reducer from '../reducers';
import { loadState, saveState } from './localStorage';
import promiseMiddleware from './promiseMiddleware';

const initialize = () => {
  const persistedState = loadState();
  const middlewares = [promiseMiddleware, loggerMiddleware];
  const middlewareEnhancer: StoreEnhancer = applyMiddleware(...middlewares);
  const enhancers: StoreEnhancer[] = [middlewareEnhancer];
  const composedEnhancers: any = compose(...enhancers);
  const store = createStore(reducer, persistedState, composedEnhancers);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers');
      store.replaceReducer(nextReducer);
    });
  }

  store.subscribe(() => {
    const state = store.getState();
    const toSave = {
      authentication: state.authentication,
      routing: state.routing,
      reports: state.reports,
      category: state.category,
      brand: state.brand,
      locale: state.locale,
      model: state.model,
      model_name: state.model_name,
      points: state.points,
      side: state.side,
      template: state.template,
      users: state.users,
      cutReports: state.cutReports,
    };
    saveState(toSave);
  });
  return store;
};

export default initialize;
