import { createStyles, Grid, Hidden, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import translate from 'counterpart';
import * as React from 'react';
import { connect } from 'react-redux';
import history from '../../../config/history';
import { fetchCategories } from '../../../reducers/category';
import BrandSearch from '../BrandSearch';
import Page from '../Page';
import ListItem from './ListItem';
import Translate from 'react-translate-component';
import { fetchBrand } from '../../../reducers/brand';
import { boolean } from 'yup';
import WarningDialog from '../dialog/WarningDialog';

const styles = (theme: Theme) => createStyles({
  titleContainer: {
    display: 'table',
    borderSpacing: theme.spacing(1)
  },
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px'
    },
    whiteSpace: 'nowrap'
  },
  count: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.colors.brono,
    padding: '4px 8px',
    background: 'rgba(0, 182, 180, 0.1)',
    border: '1px solid ' + theme.colors.brono,
    borderRadius: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  }
});

interface CategoryListProps extends WithStyles<typeof styles> {
  error: boolean;
  totalCuts: number;
  items: any[];
  fetchCategories: () => void;
  fetchBrand: () => void;
}

interface CategoryListState {
  warningDialogOpen: boolean;
}

const initialState: CategoryListState = {
  warningDialogOpen: false
}

export class CategoryList extends React.Component<CategoryListProps, CategoryListState> {

  readonly state: CategoryListState = initialState;

  handleNavigate = (categoryId: number): void => {
    (this.props.totalCuts > 0) ?
      history.push(`/category/${categoryId}/brand`)
      :
      this.setState({...this.state, warningDialogOpen: true})
  };

  handleWarningDialogClose = () => {
    this.setState({...this.state, warningDialogOpen: false});
  }

  componentDidMount = (): void => {
    const { fetchCategories, fetchBrand } = this.props;
    fetchCategories();
    fetchBrand();
  };

  renderActionsBar = (): React.ReactNode => {
    const { classes, items } = this.props;
    return (
      <Grid
        container={true}
        direction={'column'}
        spacing={2}
        style={{ margin: '0px -8px' }}>
        <Hidden smUp={true}>
          <Grid item={true} xs={true}>
            <BrandSearch />
          </Grid>
        </Hidden>
        <Grid item={true}>
          <Grid
            container={true}
            direction={'row'}
            justify={'space-between'}
            alignItems={'center'}
            spacing={2}
            style={{ flexWrap: 'nowrap' }}>
            <Grid item={true}>
              <Grid
                container={true}
                direction={'row'}
                alignItems={'center'}
                justify={'flex-start'}
                spacing={2}>
                <Grid item={true}>
                  <Grid
                    container={true}
                    direction={'row'}
                    alignItems={'center'}
                    spacing={1}
                    style={{ flexWrap: 'nowrap' }}>
                    <Grid item={true}>
                      <Typography className={classes.title}>
                        <Translate content={'categoryPage.category'} />
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <div className={classes.count}>
                        {items.length}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Hidden xsDown={true}>
              <Grid item={true}>
                <BrandSearch />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderThis = (): React.ReactNode => {
    const { items, totalCuts, error } = this.props;
    const errors: string[] = [];
    if (error) {
      errors.push(translate('side.label.noCutsForWarranty'));
    }
    if (totalCuts <= 0) {
      errors.push(translate('side.label.noCuts'));
    }
    return (
      <Page error={errors} actions={this.renderActionsBar()}>
        <Grid
          container={true}
          direction={'row'}
          spacing={3}
          style={{ height: 'min-content' }}>
          {items.map((item) => (
            <Grid item={true} xs={12} sm={6} md={4} lg={3} xl={2} key={item.id}>
              <ListItem item={item} action={this.handleNavigate} />
            </Grid>
          ))}
        </Grid>
      </Page>
    );
  };

  render = (): React.ReactNode => {
    const { children } = this.props;
    return (
      <>
        {children || this.renderThis()}
        <WarningDialog
          open={this.state.warningDialogOpen}
          content={<Translate content={'error.paymentRequired'} />}
          onClose={this.handleWarningDialogClose}
        />
      </>
    );
  };
}

const mapStateToProps = (state, ownProps) => ({
  items: state.category.items,
  totalCuts: state.balances.cutsNumber,
  currentLocale: state.locale.currentLocale
});

const mapDispatchToProps = {
  fetchCategories,
  fetchBrand
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CategoryList));

