import React, { useState } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import VideoDialog from '../dialog/VideoDialog';

const styles = (theme: Theme) => createStyles({
  instructionDescription: {
    fontSize: '16px',
    wordBreak: 'break-all'

  },
  instructionDescriptionBox: {
    height: '80px',
    padding: '8px',
    color: 'black'
  }
});

interface InstructionsListProps extends WithStyles<typeof styles> {
  item: {
    id: number;
    link: string;
    description: string;
  }
}

function cutYouTubeVideoId(url) {
  let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = url.match(regExp);
  return (match&&match[7].length==11)? match[7] : false;
}

function setInstructionName(link, description) {
  if(description.length > 80){
    description = description.slice(0, 80) + '...';
  }
  return description;
}

const InstructionsItem: React.FunctionComponent<InstructionsListProps> = (props: InstructionsListProps) => {
  const { item, classes } = props;
  const [supportDialogOpen, setSupportDialogOpen] = useState<boolean>(false);
  return (
    <Grid>
      <Card>
        <CardActionArea
          onClick={() => setSupportDialogOpen(true)}
        >
          <CardMedia
            component='img'
            height='138'
            src={'https://img.youtube.com/vi/' + cutYouTubeVideoId(item.link) + '/hqdefault.jpg'}
          />
          <CardContent className={classes.instructionDescriptionBox}>
            <Typography variant='body2' component='p' className={classes.instructionDescription}>
              {setInstructionName(item.link, item.description)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <VideoDialog
        url={item.link}
        open={supportDialogOpen}
        onClose={() => setSupportDialogOpen(false)}
      />
    </Grid>
  );
};

export default withStyles(styles)(InstructionsItem);