import { createStyles, Grid, Hidden, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import translate from 'counterpart';
import * as React from 'react';
import { connect } from 'react-redux';
import history from '../../../config/history';
import { fetchCategoryBrand } from '../../../reducers/brand';
import { clearItems } from '../../../reducers/warranty';
import BrandSearch from '../BrandSearch';
import Page from '../Page';
import ListItem from './ListItem';
import { chooseCategory } from '../../../reducers/category';
import BackButton from '../BackButton';

const styles = (theme: Theme) => createStyles({
  titleContainer: {
    display: 'table',
    borderSpacing: theme.spacing(1)
  },
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px'
    },
    whiteSpace: 'nowrap'
  },
  count: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.colors.brono,
    padding: '4px 8px',
    background: 'rgba(0, 182, 180, 0.1)',
    border: '1px solid ' + theme.colors.brono,
    borderRadius: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  }
});

interface BrandListProps extends WithStyles<typeof styles> {
  error: boolean;
  totalCuts: number;
  category: any,
  categoryId: number,
  chooseCategory: (categoryId: number) => void;
  fetchCategoryBrand: (categoryId: number) => void;
  clearItems: () => void;
  items: any[];
  roles: string;
}

export class BrandList extends React.Component<BrandListProps> {

  handleNavigate = (brandId: number): void => {
    const { categoryId } = this.props;
    history.push(`/category/${categoryId}/brand/${brandId}/model`);
  };

  componentDidMount = (): void => {
    const { fetchCategoryBrand, categoryId, chooseCategory } = this.props;
    chooseCategory(categoryId);
    fetchCategoryBrand(categoryId);
  };

  renderActionsBar = (): React.ReactNode => {
    const { classes, category, categoryId } = this.props;
    const selectedCategory = category.items.find(x => x.id == categoryId);
    return (
      <Grid
        container={true}
        direction={'column'}
        spacing={2}
        style={{ margin: '0px -8px' }}>
        <Hidden smUp={true}>
          <Grid item={true}>
            <BrandSearch />
          </Grid>
        </Hidden>
        <Grid item={true}>
          <Grid
            container={true}
            direction={'row'}
            justify={'space-between'}
            alignItems={'center'}
            spacing={2}
            style={{ flexWrap: 'nowrap' }}>
            <Grid item={true}>
              <Grid
                container={true}
                direction={'row'}
                alignItems={'center'}
                spacing={1}
                style={{ flexWrap: 'nowrap' }}>
                <Grid item={true}>
                  <BackButton onClick={() => history.push('/category')} />
                </Grid>
                <Grid item={true}>
                  <Typography className={classes.title}>
                    {selectedCategory && selectedCategory.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Hidden xsDown={true}>
              <Grid item={true}>
                <BrandSearch />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderThis = (): React.ReactNode => {
    const { items, totalCuts, error } = this.props;
    let errors: string[] = [];
    if (error) {
      errors.push(translate('side.label.noCutsForWarranty'));
    }
    if (totalCuts <= 0) {
      errors.push(translate('side.label.noCuts'));
    }
    return (
      <Page
        actions={this.renderActionsBar()}
        error={errors}>
        <Grid
          container={true}
          direction={'row'}
          spacing={3}
          style={{ height: 'min-content' }}>
          {items.map((item) => (
            <Grid
              item={true} key={item.id}
              xs={12} sm={6} md={4} lg={3} xl={2}>
              <ListItem
                item={item}
                action={this.handleNavigate} />
            </Grid>
          ))}
        </Grid>
      </Page>
    );
  };

  render = (): React.ReactNode => {
    const { children } = this.props;
    return (
      <React.Fragment>
        {children || this.renderThis()}
      </React.Fragment>
    );
  };
}

const mapStateToProps = (state, ownProps) => ({
  error: state.warranty.error,
  items: state.brand.categoryItems,
  categoryId: ownProps.match.params.categoryId,
  category: state.category,
  roles: state.authentication.roles,
  totalCuts: state.balances.cutsNumber,
  currentLocale: state.locale.currentLocale
});

const mapDispatchToProps = {
  fetchCategoryBrand,
  chooseCategory,
  clearItems
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BrandList));
