const LOAD = 'warranty/LOAD';
const LOAD_SUCCESS = 'warranty/LOAD_SUCCESS';
const LOAD_FAIL = 'warranty/LOAD_FAIL';

const SEND_SMS = 'warranty/SEND_SMS';
const SEND_SMS_SUCCESS = 'warranty/SEND_SMS_SUCCESS';
const SEND_SMS_FAIL = 'warranty/SEND_SMS_FAIL';

const SEND_CONFIRM_CODE = 'warranty/SEND_CONFIRM_CODE';
const SEND_CONFIRM_CODE_SUCCESS = 'warranty/SEND_CONFIRM_CODE_SUCCESS';
const SEND_CONFIRM_CODE_FAIL = 'warranty/SEND_CONFIRM_CODE_FAIL';

const CREATE_WARRANTY = 'warranty/CREATE_WARRANTY';
const CREATE_WARRANTY_SUCCESS = 'warranty/CREATE_WARRANTY_SUCCESS';
const CREATE_WARRANTY_FAIL = 'warranty/CREATE_WARRANTY_FAIL';
const CLEAR_ITEMS = 'warranty/CLEAR_ITEMS';

export const initialState = {
  items: [],
  itemsWarranty: undefined,
  error: false,
  smsCodeError: false
};

// Reducer

export default function warrantyReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_WARRANTY: {
      return { ...state, error: false };
    }
    case CREATE_WARRANTY_SUCCESS: {
      return { ...state, items: [], itemsWarranty: undefined, error: false };
    }
    case CREATE_WARRANTY_FAIL: {
      return { ...state, error: true };
    }
    case LOAD: {
      return { ...state, items: [], itemsWarranty: undefined, error: false };
    }
    case LOAD_SUCCESS: {
      return {
        ...state,
        items: action.result.data,
        itemsWarranty: action.result.data,
        error: false
      };
    }
    case SEND_CONFIRM_CODE: {
      return { ...state, smsCodeError: false };
    }
    case SEND_CONFIRM_CODE_SUCCESS: {
      return { ...state, smsCodeError: false };
    }
    case SEND_CONFIRM_CODE_FAIL: {
      return { ...state, smsCodeError: true };
    }
    case CLEAR_ITEMS: {
      return { ...state, items: [], itemsWarranty: undefined, error: false };
    }
    default: {
      return { ...state };
    }
  }
}

export function loadWarranty(organization, tradePoint) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get('/api/warranty/sales/today/without_warranty', {
      params: {
        orgId: organization,
        tradePointId: tradePoint
      }
    })
  };
}

export function createWarranty(data) {
  return {
    types: [CREATE_WARRANTY, CREATE_WARRANTY_SUCCESS, CREATE_WARRANTY_FAIL],
    promise: (client) => client.post('/api/warranty', data)
  };
}

export function getWarranty(phone) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get('/api/warranty', {
      params: {
        phoneNumber: phone
      }
    })
  };
}

export function sendSmsConfirm(warrantyCodeDTO) {
  return {
    types: [SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_FAIL],
    promise: (client) => client.post('/api/warranty/confirm/send', warrantyCodeDTO)
  };
}

export function confirmWarranty(warrantyCodeDTO) {
  return {
    types: [SEND_CONFIRM_CODE, SEND_CONFIRM_CODE_SUCCESS, SEND_CONFIRM_CODE_FAIL],
    promise: (client) => client.post('/api/warranty/confirm', warrantyCodeDTO),
  };
}

export function clearItems() {
  return {
    type: CLEAR_ITEMS
  };
}
