const LOAD = 'rating/LOAD';
const LOAD_SUCCESS = 'rating/LOAD_SUCCESS';
const LOAD_FAIL = 'rating/LOAD_FAIL';

export const initialState = {
  items: [],
  currentOrg: null
};

// Reducer

export default function ratingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        items: action.result.data
      };
    default:
      return state;
  }
}

// Actions

export function loadRating() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get('/api/rating/list')
  };
}
