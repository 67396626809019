import { createStyles, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import { TradePoint } from '../../../models/TradePoint';
import { TradePointService } from '../../../models/TradePointService';
import { RootState } from '../../../reducers';
import { fetchPoints } from '../../../reducers/points';
import { fetchServices, fetchServicesTable } from '../../../reducers/services';
import Page from '../Page';
import TradePointTable from '../settings/TradePointTable';
import ServicesTable from './ServicesTable';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontSize: '24px',
      lineHeight: '32px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        lineHeight: '24px'
      }
    },
    tableContainer: {
      flexWrap: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        margin: '-16px -8px -16px',
        width: 'calc(100% + 16px)'
      }
    },
    tableItem: {
      [theme.breakpoints.down('xs')]: {
        padding: '0px !important',
        marginTop: '8px'
      }
    },
    tableTitle: {
      color: theme.colors.gray,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'bold',
      padding: '8px 0'
    }
  });

interface Props extends WithStyles<typeof styles> {
  services: TradePointService[];
  points: TradePoint[];
  items: any[];
  fetchServices: () => void;
  fetchPoints: () => void;
  fetchServicesTable: () => void;
}

export class Services extends React.Component<Props> {
  componentDidMount() {
    const { fetchServices, fetchPoints, fetchServicesTable } = this.props;
    fetchServices();
    fetchPoints();
    fetchServicesTable();
  }

  renderActionsBar = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Grid container={true} justify={'space-between'} alignItems={'center'}>
        <Grid item={true}>
          <Typography className={classes.title}>
            <Translate content={'servicesPage.title.services'} />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  render = (): React.ReactNode => {
    const { services, points, classes, items } = this.props;
    return (
      <Page actions={this.renderActionsBar()}>
        <Grid
          container={true}
          direction={'column'}
          wrap={'nowrap'}
          spacing={3}
          className={classes.tableContainer}
        >
          <Grid item={true} className={classes.tableItem}>
            <ServicesTable
              title={
                <Typography className={classes.tableTitle}>
                  <Translate content={'servicesPage.table.title'} />
                </Typography>
              }
              data={items}
              points={points}
            />
          </Grid>
          <Grid item={true} className={classes.tableItem}>
            <TradePointTable
              title={
                <Typography className={classes.tableTitle}>
                  <Translate content={'settingsPage.table.title'} />
                </Typography>
              }
              data={points}
            />
          </Grid>
        </Grid>
      </Page>
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  services: state.services.items,
  points: state.points.items,
  items: state.services.tableItems
});

const mapDispatchToProps = {
  fetchServices,
  fetchPoints,
  fetchServicesTable
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Services));
