import { connectRouter, RouterState } from 'connected-react-router';
import { routerReducer as routing } from 'react-router-redux';
import { AnyAction, CombinedState, combineReducers, Reducer } from 'redux';
import history from '../config/history';
import authentication, { LOGIN, LOGOUT_SUCCESS } from './authentication';
import balances, { initialState as balancesState } from './balances';
import {
  bookingReducer,
  BookingState,
  initialState as bookingState
} from './booking';
import brand, { initialState as brandState } from './brand';
import errors, { initialState as errorsState } from './errors';
import {
  initialState as localeState,
  localeReducer,
  LocaleState
} from './locale';
import modal from './modal';
import model, { initialState as modelState } from './model';
import devices, { initialState as devicesState } from './devices';
import {
  initialState as pointsState,
  pointsReducer,
  PointsState
} from './points';
import rating, { initialState as ratingState } from './rating';
import reports, { initialState as reportsState } from './reports';
import {
  initialState as servicesState,
  servicesReducer,
  ServicesState
} from './services';
import side, { initialState as sideState } from './side';
import template from './template';
import users, { initialState as usersState } from './users';
import warranty, { initialState as warrantyState } from './warranty';
import instructions, { initialState as instructionsState } from './instructions';
import notifications, { initialState as notificationsState } from './notifications';
import category, {initialState as categoryState} from './category';
import { DevicesState } from './devices';
import cutReports, {initialState as cutReportsState, State as CutReportsState} from "./cutReports";

export interface RootState {
  router?: RouterState;
  routing?: any;
  locale: LocaleState;
  brand: any;
  model: any;
  devices: DevicesState;
  side: any;
  users: any;
  instructions: any;
  services: ServicesState;
  reports: any;
  warranty: any;
  rating: any;
  balances: any;
  points: PointsState;
  template: any;
  authentication: any;
  errors: any;
  booking: BookingState;
  notifications: any;
  category: any;
  cutReports: CutReportsState;
}

const appReducer: Reducer<CombinedState<RootState>> = combineReducers({
  router: connectRouter(history),
  brand,
  model,
  side,
  devices,
  template,
  authentication,
  locale: localeReducer,
  routing,
  instructions,
  users,
  services: servicesReducer,
  reports,
  balances,
  points: pointsReducer,
  errors,
  modal,
  warranty,
  rating,
  booking: bookingReducer,
  notifications,
  category,
  cutReports,
});

const initialState: RootState = {
  brand: brandState,
  model: modelState,
  devices: devicesState,
  side: sideState,
  instructions: instructionsState,
  users: usersState,
  services: servicesState,
  reports: reportsState,
  warranty: warrantyState,
  rating: ratingState,
  balances: balancesState,
  points: pointsState,
  template: {},
  authentication: {},
  errors: errorsState,
  booking: bookingState,
  locale: localeState,
  notifications: notificationsState,
  category: categoryState,
  cutReports: cutReportsState,
};

const rootReducer: Reducer<CombinedState<RootState>> = (
  state: CombinedState<RootState>,
  action: AnyAction
): CombinedState<RootState> => {
  if (action.type === LOGOUT_SUCCESS || action.type === LOGIN) {
    const { routing, locale } = state;
    state = { ...state, ...initialState, routing, locale };
  }
  return appReducer(state, action);
};

export default rootReducer;
