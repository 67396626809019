import { createMuiTheme, fade, Theme } from '@material-ui/core';
import * as React from 'react';

const theme: Theme = createMuiTheme({
  colors: {
    black: '#050d29',
    brono: '#3be0e0',
    hover: '#737780',
    blue: '#0983fe',
    background: '#f5f7fa',
    gray: '#5f636b',
    gray100: '#e8e8e8',
    gray400: '#c6c6c6',
    lightGray: '#b9bec9',
    lightRed: '#fe4f78',
    red: '#fc4539',
    darkGreen: '#37a000'
  }
});

export default createMuiTheme({
  ...theme,
  palette: {
    primary: {
      main: theme.colors.brono
    },
    background: {
      default: theme.colors.background
    },
    text: {
      primary: theme.colors.black
    }
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: 14,
    subtitle1: {
      fontSize: 14
    }
  },
  overrides: {
    MuiTableRow: {
      root: {
        verticalAlign: 'top'
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '14px',
        lineHeight: '24px',
        padding: '4px 8px',
        '&:first-child': {
          paddingLeft: '16px'
        },
        '&:last-child': {
          paddingRight: '16px'
        }
      },
      head: {
        fontWeight: 600,
        backgroundColor: '#e8f8f7 !important',
        borderBottom: '1px solid ' + theme.colors.brono,
        maxWidth: '200px'
      },
      body: {},
      footer: {
        color: theme.colors.black
      }
    },
    MuiTableSortLabel: {
      icon: {
        color: theme.colors.brono + ' !important'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#ffffff'
      },
      root: {
        boxShadow: 'none !important',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.colors.gray100
      }
    },
    MuiToolbar: {
      root: {
        minHeight: '48px !important'
      },
      gutters: {
        paddingLeft: '16px !important',
        paddingRight: '16px !important'
      }
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 2px 8px rgba(138, 160, 181, 0.25) !important'
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: theme.colors.gray100
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: fade(theme.colors.black, 0.05),
        '&:hover': {
          backgroundColor: fade(theme.colors.black, 0.1)
        },
        '&$focused': {
          backgroundColor: fade(theme.colors.brono, 0.05)
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: theme.colors.gray
      }
    },
    MuiButton: {
      root: {
        minWidth: '32px',
        transition:
          'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        }
      },
      containedPrimary: {
        color: 'white'
      },
      containedSizeLarge: {
        fontSize: '14px',
        padding: '8px 16px'
      },
      outlinedSizeLarge: {
        fontSize: '14px',
        padding: '7px 15px'
      },
      text: {
        padding: '0 8px',
        margin: '0 -8px',
        fontWeight: 'bold'
      },
      textSizeLarge: {
        margin: '0px'
      }
    },
    MuiIconButton: {
      root: {
        padding: '8px',
        color: theme.colors.lightGray,
        '&:hover': {
          color: theme.colors.hover
        }
      }
    },
    MuiChip: {
      colorPrimary: {
        color: 'white'
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: fade(theme.colors.brono, 0.2),
          '&:hover': {
            backgroundColor: fade(theme.colors.brono, 0.2)
          }
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '32px',
        color: theme.colors.lightGray
      }
    },
    MuiSelect: {
      icon: {
        color: theme.colors.lightGray
      }
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          paddingTop: '16px'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        lineHeight: '1.5',
        marginTop: ' 4px',
        position: 'absolute',
        bottom: '-22px',
        left: 0,
        right: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: '16px'
        }
      }
    },
    // @ts-ignore
    MuiAlert: {
      standardError: {
        color: '#7e3b3a',
        backgroundColor: '#ffeceb'
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: 'white'
      },
      toolbarBtnSelected: {
        color: 'white'
      }
    },
    MuiPickersDay: {
      daySelected: {
        color: 'white !important'
      }
    },
    PrivateSwitchBase: {
      root: {
        padding: '8px'
      }
    },
    PaginationItem: {
      root: {
        color: theme.colors.black
      }
    },
    MUIDataTable: {
      paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative'
      },
      responsiveScrollMaxHeight: {
        maxHeight: 'unset !important'
      }
    },
    MUIDataTableToolbar: {
      root: {
        minHeight: 'auto !important',
        borderBottom: '1px dashed #8E8E8E',
        display: 'flex !important'
      },
      left: {
        padding: '0px !important',
        flex: '1 1 100%'
      },
      actions: {
        textAlign: 'right !important'
      },
      titleText: {
        fontSize: '14px',
        lineHeight: '24px',
        color: theme.colors.gray
      },
      icon: {
        '&:hover': {
          color: theme.colors.hover
        }
      }
    },
    MUIDataTableHeadCell: {
      data: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      sortAction: {
        maxWidth: '100%'
      },
      sortLabelRoot: {
        height: 'auto'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        '&:nth-of-type(2)': {
          paddingLeft: '16px'
        }
      }
    },
    MUIDataTableSelectCell: {
      checkboxRoot: {
        padding: '4px'
      },
      fixedHeaderCommon: {
        backgroundColor: 'unset'
      }
    },
    MUIDataTablePagination: {
      root: {
        '&:last-child': {
          padding: 0
        }
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiFilledInput-root"]': {
          paddingTop: 0,
          paddingLeft: 0,
          '& .MuiAutocomplete-input': {
            padding: '24px 12px 8px'
          }
        }
      }
    }
  }
});
