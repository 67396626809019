import { createStyles, Grid, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import { getAllTradePoints } from '../../../reducers/points';
import { loadRating } from '../../../reducers/rating';
import Page from '../Page';
import RatingTable from './RatingTable';

const styles = (theme: Theme) => createStyles({
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  tableContainer: {
    flexWrap: 'nowrap',
    height: 'calc(100% + 24px)',
    [theme.breakpoints.down('xs')]: {
      margin: '-8px -8px -16px',
      width: 'calc(100% + 16px)'
    }
  },
  tableItem: {
    maxHeight: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      marginBottom: '-8px',
      maxHeight: '100%'
    }
  }
});

interface RatingProps extends WithStyles<typeof styles> {
  items: any[];
  roles: any;
  rating: any[];
  fetchTradePoints: ((org) => void);
  loadRating: (() => void);
}

export class Rating extends React.Component<RatingProps> {

  componentDidMount = (): void => {
    this.props.fetchTradePoints(null);
    this.props.loadRating();
  };

  renderActionsBar = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Grid
        container={true}
        justify={'space-between'}
        alignItems={'center'}>
        <Grid item={true}>
          <Typography className={classes.title}>
            <Translate content={'rating.title'} />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  render = (): React.ReactNode => {
    const { rating, classes } = this.props;
    return (
      <Page actions={this.renderActionsBar()}>
        <Grid
          container={true}
          direction={'column'}
          spacing={3}
          className={classes.tableContainer}>
          <Grid
            item={true} xs={true}
            className={classes.tableItem}>
            <RatingTable data={rating} />
          </Grid>
        </Grid>
      </Page>
    );
  };
}

const mapStateToProps = (state) => ({
  items: state.points.allItems,
  roles: state.authentication.roles,
  rating: state.rating.items
});

const mapDispatchToProps = {
  fetchTradePoints: getAllTradePoints,
  loadRating
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Rating));
