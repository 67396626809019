import * as React from 'react';
import Translate from 'react-translate-component';
import AdaptiveDialog from './AdaptiveDialog';
import {
    Button,
    createStyles,
    DialogContent,
    Grid,
    Hidden,
    Typography,
    WithStyles,
    withStyles
} from '@material-ui/core';

const styles = createStyles({
    button: {
        width: '100%'
    },
    phone: {
        fontWeight: 'bold'
    },
    content: {
        padding: '40px 16px 24px'
    }
});

interface SupportDialogProps extends WithStyles<typeof styles>{
    open: boolean;
    onClose: (() => void);
}

const SupportDialog: React.FunctionComponent<SupportDialogProps> = (props: SupportDialogProps) => {
    const {open, onClose, classes} = props;

    return (
        <AdaptiveDialog open={open} onClose={onClose}>
            <DialogContent
                className={classes.content}>
                <Grid
                    container
                    direction={'column'}
                    spacing={2}
                    alignItems={'stretch'}>
                    <Grid item>
                        <Typography>
                            <Translate content={'support.supportPhone'}/>
                        </Typography>
                        <Typography className={classes.phone}>
                            <Translate content={'support.phone'}/>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            className={classes.button}
                            color={'primary'}
                            variant={'outlined'}
                            onClick={() => onClose()}>
                            <Translate content={'support.cancel'} />
                        </Button>
                    </Grid>
                    <Hidden smUp>
                        <Grid item>
                            <Button
                                className={classes.button}
                                color={'primary'}
                                variant={'contained'}
                                onClick={() => null}
                                href={'tel:+7-919-390-21-16'}>
                                <Translate content={'support.call'} />
                            </Button>
                        </Grid>
                    </Hidden>
                </Grid>
            </DialogContent>
        </AdaptiveDialog>
    );
};

export default withStyles(styles)(SupportDialog);