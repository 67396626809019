import { createStyles, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { findDevices } from '../../../../reducers/devices';
import translate from 'counterpart';
import FilledTextField from '../../field/FilledTextField';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      boxShadow: 'none',
      borderRadius: 'unset',
      margin: '0px',
      height: '10%'
    }
  })
);

interface Props {
  onSelect?: (device: Device) => void;
}

interface Device {
  id: number;
  name: string;
}

interface StateProps {
  devices: Device[];
}

const BookingDeviceAuto: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { onSelect } = props;
  const classes = useStyles();
  const [device, setDevice] = useState<Device>(null);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>(null);
  const { devices } = useSelector<RootState, StateProps>(
    (state: RootState) => ({
      devices: state.devices.items
    })
  );
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    if (timerId) {
      clearTimeout(timerId);
    }
    let newTimerId: NodeJS.Timeout = setTimeout(() => {
      dispatch(findDevices(value));
    }, 500);
    setTimerId(newTimerId);
  };
  const handleChange = (e: ChangeEvent<{}>, value: any): void => {
    setDevice(value);
    if (onSelect) {
      onSelect(value);
    }
  };
  return (
    <Autocomplete
      freeSolo={false}
      value={device}
      options={devices}
      filterOptions={(options: Device[]) => options}
      getOptionSelected={(option: Device, value: Device) => {
        return option.id === value.id;
      }}
      getOptionLabel={(option: Device) => option.name}
      noOptionsText={translate('booking.dialog.selectNoOptions')}
      onChange={handleChange}
      className={classes.paper}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <FilledTextField
            {...params}
            variant={'filled'}
            label={translate('booking.dialog.device')}
            InputProps={{ ...params.InputProps }}
            onChange={handleInputChange}
          />
        );
      }}
    />
  );
};

export default BookingDeviceAuto;
