import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import translate from 'counterpart';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { ChangeEvent } from 'react';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import * as yup from 'yup';
import { confirmWarranty, sendSmsConfirm } from '../../../../reducers/warranty';
import AdaptiveDialog from '../AdaptiveDialog';
import FilledTextField from '../../field/FilledTextField';
import InputMask from 'react-input-mask';
import { WarrantyConfirmationCode } from '../../../../models/WarrantyConfirmationCode';
import history from '../../../../config/history';

const styles = (theme: Theme) => createStyles({
  paper: {
    overflowY: 'visible',
    [theme.breakpoints.down('xs')]: {
      overflowY: 'auto'
    }
  },
  content: {
    padding: '40px 16px 24px',
    overflowY: 'visible'
  },
  contentTable: {
    display: 'flex',
    padding: 0
  },
  actions: {
    padding: '0px 16px 24px'
  },
  link: {
    fontSize: '12px',
    paddingTop: '4px'
  }
});

interface Props extends WithStyles<typeof styles> {
  warranty: any;
  open: boolean;
  close: () => void;
  sendSmsConfirm: (warrantyCodeDTO) => void;
  confirmWarranty: (warrantyCodeDTO) => void;
  error: boolean;
  setConfirmed: () => void;
}

interface State {
  sent: boolean;
}

export class UseWarrantyConfirmModalWindow extends React.Component<Props, State> {

  state: State = {
    sent: false
  };

  componentDidUpdate = (prevProps, prevState): void => {
    const { sent } = this.state;
    if (prevState.sent && !prevProps.open) {
      this.setState({ sent: false })
    }
  }

  closeModal = () => {
    const { error, close, setConfirmed } = this.props;
    if (error == false) {
      close();
      setConfirmed();
    }
  };

  handleCheckCode = (values) => {
    const { code } = values;
    const { warranty, confirmWarranty } = this.props;
    const warrantyCodeDTO: WarrantyConfirmationCode = {
      id: warranty,
      code: code
    };
    confirmWarranty(warrantyCodeDTO);
    setTimeout(() => this.closeModal(), 1500);
  };

  handleSend = () => {
    const { sendSmsConfirm, warranty } = this.props;
    const warrantyCodeDTO: WarrantyConfirmationCode = {
      id: warranty,
      code: null
    };
    this.setState({ sent: true });
    sendSmsConfirm(warrantyCodeDTO);
  };

  render = (): React.ReactNode => {
    const { warranty, open, classes, close, error } = this.props;
    const { sent } = this.state;
    const initialValues = { code: '' };
    const formSchema = yup.object({
      code: yup.string().matches(/^\d{4}$/).required()
    });
    return (
      <AdaptiveDialog
        open={open}
        onClose={close}
        title={translate('warranty.confirm')}
        closeButton={true}
        paperClass={classes.paper}>
        <Formik
          onSubmit={this.handleCheckCode}
          isInitialValid={false}
          validationSchema={formSchema}
          initialValues={initialValues}>
          {(props: FormikProps<any>) => {
            const { setFieldValue, values, isValid } = props;
            return (
              <Form>
                <DialogContent
                  className={classes.content}>
                  <FilledTextField
                    name={'code'}
                    variant={'filled'}
                    label={translate('warranty.code')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(e.target.name, e.target.value, true);
                    }}
                    InputProps={{
                      inputComponent: InputMask,
                      inputProps: {
                        mask: '9999',
                        maskChar: '_'
                      }
                    }}
                  />
                  {
                    sent ?
                      <Typography className={classes.link}>
                        {translate('warranty.sent')}
                      </Typography> : <div></div>
                  }
                  {
                    error ?
                      <Typography className={classes.link}>
                        {translate('warranty.wrongCode')}
                      </Typography> : <div></div>
                  }
                </DialogContent>
                <DialogActions
                  className={classes.actions}>
                  <Button variant={'contained'} color={'inherit'} onClick={this.handleSend} disabled={sent}>
                      <Translate content={'warranty.send'} />
                  </Button>
                  <Button variant={'contained'} color={'primary'} type={'submit'} disabled={!isValid}>
                    <Translate content={'warranty.use'} />
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </AdaptiveDialog>
    );
  };
}

const mapStateToProps = (state) => ({
  error: state.warranty.smsCodeError
});

const mapDispatchToProps = {
  sendSmsConfirm
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UseWarrantyConfirmModalWindow));
