import { createStyles, IconButton, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: 'black',
      backgroundColor: '#fff',
      borderColor: '#38B6B5FF',
      border: 'solid 1px',
      fontSize: '18px',
      lineHeight: '24px',
      padding: 'unset',
      width: '40px',
      height: '40px',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#38B6B5FF'
      }
    },
    primary: {
      border: 'solid 1px',
      color: '#fff',
      backgroundColor: '#38B6B5FF',
      borderColor: '#38B6B5FF',
      '&:hover': {
        backgroundColor: '#38B6B5FF'
      }
    }
  })
);

interface Props {
  content: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

const DayButton: React.FC<Props> = (props: Props) => {
  const { content, selected, onClick } = props;
  const classes = useStyles();
  const className: string =
    classes.button + (selected ? ' ' + classes.primary : '');
  return (
    <IconButton className={className} onClick={onClick}>
      <Typography variant={'body1'}>{content}</Typography>
    </IconButton>
  );
};

export default DayButton;
