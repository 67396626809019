import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core';
import translate from 'counterpart';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { ChangeEvent } from 'react';
import Translate from 'react-translate-component';
import AdaptiveDialog from '../AdaptiveDialog';
import FilledTextField from '../../field/FilledTextField';
import moment from 'moment';
import BookingDeviceAuto from './BookindDeviceAuto';
import BookingServiceCheckbox from './BookingServiceCheckbox';
import { parseDateTime } from '../../../../utils/date-utils';
import { CreateBookingDTO } from '../../../../models/CreateBookingDTO';
import { Device } from '../../../../models/Device';
import { format, formatISO } from 'date-fns';
import { BookingType } from '../../../../models/BookingType';
import { createBooking, lockTime } from '../../../../reducers/booking';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import 'react-phone-input-2/lib/style.css';
import * as yup from 'yup';
import * as Yup from 'yup';
import ReactPhoneInput from 'react-phone-input-2';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      overflowY: 'visible',
      [theme.breakpoints.down('xs')]: {
        overflowY: 'auto'
      }
    },
    content: {
      padding: '16px 16px 32px',
      overflowY: 'visible'
    },
    contentTable: {
      display: 'flex',
      padding: 0
    },
    actions: {
      padding: '0px 16px 8px'
    },
    divider: {
      marginBottom: '4px'
    }
  });

interface FormValues {
  name: string;
  phone: string;
}

interface Props extends WithStyles<typeof styles> {
  rowDate: Date;
  rowTime: any;
  pointId: number;
  show: boolean;
  handleShowCreateBooking: () => void;
  createBooking: ((dto) => void);
  lockTime: ((dto) => void);
}

interface State {
  show: boolean;
  device: number;
  services: number[];
}

export class BookingCreateDialog extends React.Component<Props, State> {
  state: State = {
    show: true,
    device: null,
    services: []
  };

  handleClose = (): void => {
    const { show, handleShowCreateBooking } = this.props;
    if (show) {
      this.setState({ show: true });
    }
    this.setState({ device: null, services: [] });
    handleShowCreateBooking();
  };

  getModelServices = (): { [x: number]: number[] } => {
    const services: { [x: number]: number[] } = {};
    this.state.services.forEach((ds: number) => {
      services[this.state.device] = this.state.services;
    });
    return services;
  };

  handleSubmit = (values: FormValues) => {
    const { rowTime, rowDate, pointId, handleShowCreateBooking, lockTime } = this.props;
    let year = rowDate.getUTCFullYear();
    let month = rowDate.getUTCMonth() + 1;
    let day = rowDate.getUTCDate();
    let dateTime = new Date(moment(year + '-' + month + '-' + day + ' ' + rowTime).format());
    const dto: CreateBookingDTO = {
      date: formatISO(parseDateTime(dateTime), { representation: 'date' }),
      time: format(parseDateTime(dateTime), 'HH:mm:ss'),
      tradePointId: pointId,
      type: BookingType.SALE,
      modelServices: this.getModelServices(),
      customerName: values.name,
      customerPhone: values.phone,
      customerEmail: null,
      warrantyId: null
    };
    lockTime(dto);
    this.setState({ device: null, services: [] });
    handleShowCreateBooking();
  };

  handleSelectDevice = (deviceId: Device): void => {
    this.setState({ device: deviceId?.id });
  };

  handleSelectServices = (servicesIds: number[]): void => {
    this.setState({ services: servicesIds });
  };

  render = (): React.ReactNode => {
    const { show, classes, rowTime, rowDate, pointId } = this.props;
    const initialValues: FormValues = {
      name: '',
      phone: ''
    };
    const formSchema = yup.object({
      name: yup.string().required(),
      phone: Yup.string().required()
        // .matches(
        //   /^\+\d{1,4}\s[\(\)\d{3}\s-]*$/
        // )
    });
    return (
      <AdaptiveDialog
        open={show}
        onClose={this.handleClose}
        title={translate('booking.dialog.addBooking') + ' ' + rowTime + ' ' + moment(rowDate).format('D MMMM, ddd')}
        closeButton={true}
        paperClass={classes.paper}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={formSchema}
          validateOnChange={true}
          isInitialValid={false}
          onSubmit={this.handleSubmit}
        >
          {({
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
              isValid
            }: FormikProps<FormValues>) => {
            return (
              <Form>
                <DialogContent className={classes.content}>
                  <Grid container={true} direction={'column'} spacing={2}>
                    <Grid item={true}>
                      <FilledTextField
                        name={'name'}
                        variant={'filled'}
                        required={true}
                        type={'string'}
                        value={values?.name}
                        label={translate('booking.dialog.customerName')}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name ? errors.name : ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setFieldTouched(e.target.name, true, false);
                          setFieldValue(e.target.name, e.target.value, true);
                        }}
                      />
                    </Grid>
                    <Grid item={true}>
                      <ReactPhoneInput
                        inputStyle={{ 'fontSize': '16px', 'width': '100%', 'height': '48px' }}
                        country={'ru'}
                        masks={{ th: '+.. ..........', lt: '+... ...-.-..-..' }}
                        value={values.phone ? String(values.phone) : ''}
                        onChange={(value: string) => {
                          setFieldValue('phone', value, true);
                        }} />
                    </Grid>
                    <Grid item={true}>
                      <BookingDeviceAuto onSelect={this.handleSelectDevice} />
                    </Grid>
                    <Grid item={true}>
                      <BookingServiceCheckbox onSelect={this.handleSelectServices} deviceId={this.state.device}
                                              storeId={pointId} />
                    </Grid>
                  </Grid>
                </DialogContent>
                <Divider className={classes.divider} />
                <DialogActions className={classes.actions}>
                  <Button
                    variant={'contained'}
                    color={'default'}
                    type={'button'}
                    disabled={false}
                    onClick={this.handleClose}
                  >
                    <Translate content={'booking.dialog.close'} />
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    disabled={!isValid}
                  >
                    <Translate content={'booking.dialog.add'} />
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </AdaptiveDialog>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    createBooking: (dto) => {
        dispatch(createBooking(dto));
    },
    lockTime: (dto) => {
      dispatch(lockTime(dto)).then((action: AnyAction) => {
        dispatch(createBooking(dto));
      });
    }
  };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(BookingCreateDialog));
