import { ButtonBase, createStyles, fade, Grid, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import Translate from 'react-translate-component';

const styles = (theme: Theme) => createStyles({
  container: {
    height: '136px',
    width: '100%',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px rgba(138, 160, 181, 0.25)',
    '&:hover': {
      boxShadow: '0px 4px 16px rgba(138, 160, 181, 0.45)',
      backgroundColor: '#ffffff'
    }
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '96px'
  },
  image: {
    height: '96px',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      maxHeight: '96px'
    }
  },
  titleContainer: {
    height: '40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.gray
  },
  titleContainerHovered: {
    backgroundColor: fade(theme.colors.lightGray, 0.2),
    color: theme.colors.black
  },
  title: {
    lineHeight: '24px',
    fontSize: '18px',
    color: 'inherit'
  }
});

interface ListItemProps extends WithStyles<typeof styles> {
  item: {
    id: number;
    name: string;
    logoEncode: string;
    logoFilename: string;
  },
  action: ((id) => void);
  device?: boolean;
  category?: boolean;
}

const ListItem: React.FunctionComponent<ListItemProps> = (props: ListItemProps) => {
  const { item, action, device, classes, category } = props;
  const [hovered, setHovered] = React.useState<boolean>(false);
  return (
    <ButtonBase
      className={classes.container}
      onClick={() => action(item.id)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <Grid
        container={true}
        direction={'column'}
        alignItems={'center'}
        style={{ height: device ? '136px' : '100%' }}>
        {!device && (
          <Grid item={true} xs={true} className={classes.imageContainer}>
            <Grid className={classes.image}>
              {item.logoEncode && (
                <img
                  src={'data:image/png;base64, ' + item.logoEncode}
                  alt={''}
                />
              )}
            </Grid>
          </Grid>
        )}
        <Grid
          item={true}
          style={{ height: device ? '100%' : '40px' }}
          className={clsx(classes.titleContainer, {
            [classes.titleContainerHovered]: hovered
          })}>
          {!category ?
            <Typography className={classes.title}>{item.name}</Typography> :
            <Typography className={classes.title}>
              <Translate  content={'categories.' + item?.name.toLowerCase()} />
            </Typography>
          }
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default withStyles(styles)(ListItem);
