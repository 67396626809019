import {
  Button,
  createStyles,
  DialogContent,
  Grid,
  Hidden,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import translate from 'counterpart';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import { loadBalances, loadCutsNumber } from '../../../reducers/balances';
import theme from '../../../theme/theme';
import AdaptiveDialog from '../dialog/AdaptiveDialog';
import Page from '../Page';
import BalancesTable from './BalancesTable';

const styles = (theme: Theme) => createStyles({
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  form: {
    width: '100%'
  },
  submitButton: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
      marginTop: '16px'
    }
  },
  tableContainer: {
    flexWrap: 'nowrap',
    height: 'calc(100% + 24px)',
    [theme.breakpoints.down('xs')]: {
      margin: '-8px -8px -16px',
      width: 'calc(100% + 16px)'
    }
  },
  tableItem: {
    maxHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      marginBottom: '-8px',
      maxHeight: '100%'
    }
  }
});

interface BalancesProps extends WithStyles<typeof styles> {
  items: any[];
  userOrgId: number;
  cutsNumber: number;
  loadBalances: ((organization, dateFrom, dateTo) => void);
  loadCutsNumber: ((organization) => void);
}

interface BalancesState {
  filterOpen: boolean;
}

export class Balances extends React.Component<BalancesProps, BalancesState> {

  readonly state: BalancesState = {
    filterOpen: false
  };

  handleFilterDialog = (open: boolean) => {
    this.setState({ filterOpen: open });
  };

  onSubmit = (values) => {
    let { start, end } = values;
    const { userOrgId, loadBalances } = this.props;
    start = start ? new Date(start.setHours(0, 0, 0, 0)) : start;
    end = end ? new Date(end.setHours(23, 59, 59, 999)) : end;
    loadBalances(userOrgId, start, end);
    this.setState({ filterOpen: false });
  };

  componentDidMount = (): void => {
    // const { userOrgId, loadBalances } = this.props;
    // loadBalances(userOrgId, null, null);
  };

  renderActionsBar = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Grid
        container={true}
        justify={'space-between'}
        alignItems={'center'}>
        <Grid item={true}>
          <Typography className={classes.title}>
            <Translate content={'balances.title'} />
          </Typography>
        </Grid>
        <Hidden smUp={true}>
          <Grid item={true}>
            <Button
              variant={'outlined'}
              color={'primary'}
              size={'large'}
              onClick={() => this.handleFilterDialog(true)}>
              <Translate content={'reportPage.title.filter'} />
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    );
  };

  renderFilterForm = (): React.ReactNode => {
    const { classes } = this.props;
    const initialValues = {
      start: null,
      end: null
    };
    return (
      <Formik
        onSubmit={(values) => {
          this.onSubmit(values);
        }}
        initialValues={initialValues}>
        {(props: FormikProps<any>) => {
          const { values, setFieldValue } = props;
          return (
            <Form className={classes.form}>
              <Grid
                container={true}
                direction={'row'}
                spacing={2}>
                <Grid item={true} sm={4} xs={12}>
                  <KeyboardDatePicker
                    autoOk={true}
                    clearable={true}
                    fullWidth={true}
                    size={'small'}
                    label={translate('reportPage.form.dateFrom')}
                    format={'DD/MM/YYYY'}
                    inputVariant={'filled'}
                    okLabel={translate('reportPage.form.ok')}
                    cancelLabel={translate('reportPage.form.cancel')}
                    clearLabel={translate('reportPage.form.clear')}
                    value={values.start}
                    onChange={date => {
                      setFieldValue('start', date ? date.toDate() : null, false);
                    }} />
                </Grid>
                <Grid item={true} sm={4} xs={12}>
                  <KeyboardDatePicker
                    autoOk={true}
                    clearable={true}
                    fullWidth={true}
                    size={'small'}
                    label={translate('reportPage.form.dateTo')}
                    format={'DD/MM/YYYY'}
                    inputVariant={'filled'}
                    okLabel={translate('reportPage.form.ok')}
                    cancelLabel={translate('reportPage.form.cancel')}
                    clearLabel={translate('reportPage.form.clear')}
                    value={values.end}
                    onChange={(date) => {
                      setFieldValue('end', date ? date.toDate() : null, false);
                    }} />
                </Grid>
                <Grid item={true} sm={4} xs={12}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    className={classes.submitButton}>
                    <Translate
                      content={'reportPage.form.apply'} />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render = (): React.ReactNode => {
    const { items, cutsNumber, classes } = this.props;
    const { filterOpen } = this.state;
    const tableTitle: React.ReactNode = cutsNumber ? (
      <Typography style={{ color: theme.colors.gray, fontSize: '14px', lineHeight: '24px' }}>
        <span>{translate('balances.label.totalCutsNumber') + ': '}</span>
        <strong>{cutsNumber}</strong>
      </Typography>
    ) : null;
    return (
      <Page actions={this.renderActionsBar()}>
        <Grid
          container={true}
          direction={'column'}
          spacing={3}
          className={classes.tableContainer}>
          <Hidden xsDown={true}>
            <Grid item={true}>
              {this.renderFilterForm()}
            </Grid>
          </Hidden>
          <Grid
            item={true} xs={true}
            className={classes.tableItem}>
            <BalancesTable
              data={items}
              title={tableTitle} />
          </Grid>
        </Grid>
        <AdaptiveDialog
          open={filterOpen}
          title={translate('reportPage.title.filter')}
          onClose={() => this.handleFilterDialog(false)}>
          <DialogContent
            style={{ padding: '16px 16px 24px' }}>
            {this.renderFilterForm()}
          </DialogContent>
        </AdaptiveDialog>
      </Page>
    );
  };
}

const mapStateToProps = (state) => ({
  items: state.balances.items,
  userOrgId: state.authentication.organizationId,
  cutsNumber: state.balances.cutsNumber,
  currentLocale: state.locale.currentLocale
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadBalances: (organization, dateFrom, dateTo) => {
      dispatch(loadBalances(organization, dateFrom, dateTo));
    },
    loadCutsNumber: (organization) => {
      dispatch(loadCutsNumber(organization));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Balances));
