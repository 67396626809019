import { Button, ButtonGroup, fade, Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import clsx from 'clsx';
import * as React from 'react';
import Translate from 'react-translate-component';
import { StringUtils } from '../../../utils/StringUtils';

const styles = (theme: Theme) =>
  createStyles({
    card: {
      height: '100%'
    },
    buttons: {
      height: '100%',
      width: '100%'
    },
    button: {
      color: theme.colors.lightGray,
      padding: '12px 16px 10px',
      textAlign: 'left',
      '&:hover': {
        color: theme.colors.hover
      }
    },
    editButton: {
      width: '100%'
    },
    hideButton: {},
    deleteButton: {
      '&:hover': {
        color: theme.colors.lightRed,
        backgroundColor: fade(theme.colors.lightRed, 0.2)
      }
    },
    title: {
      color: theme.colors.black,
      fontSize: '18px',
      lineHeight: '16px',
      fontWeight: 600,
      textTransform: 'none',
      marginBottom: '2px'
    },
    subtitle: {
      color: theme.colors.gray,
      fontSize: '12px',
      lineHeight: '16px',
      textTransform: 'none'
    },
    hidden: {
      color: theme.colors.lightGray
    }
  });

interface UserCardProps extends WithStyles<typeof styles> {
  id: number;
  email: string;
  points: any[];
  active: boolean;
  openUser: (id) => void;
  onActivateUser: (id) => void;
  onDeactivateUser: (id) => void;
  deleteUser: (id) => void;
}

export const UserCard: React.FunctionComponent<UserCardProps> = (props) => {
  const {
    id,
    email,
    points,
    active,
    openUser,
    onActivateUser,
    onDeactivateUser,
    deleteUser,
    classes
  } = props;
  return (
    <Paper className={classes.card}>
      <ButtonGroup
        size={'large'}
        color={'default'}
        variant={'text'}
        aria-label={'text default button group'}
        className={classes.buttons}
      >
        <Button
          endIcon={<EditTwoToneIcon />}
          className={clsx(classes.button, classes.editButton)}
          onClick={() => openUser(id)}
        >
          <Grid container={true} direction={'column'}>
            <Grid item={true}>
              <Typography
                className={clsx(classes.title, {
                  [classes.hidden]: !active
                })}
              >
                {StringUtils.isBlank(email) ? '—' : email}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                className={clsx(classes.subtitle, {
                  [classes.hidden]: !active
                })}
              >
                <Translate content={'users.tradePoints'} />:{' '}
                {points ? points.length : 0}
              </Typography>
            </Grid>
          </Grid>
        </Button>
        {active ? (
          <Button
            className={clsx(classes.button, classes.hideButton)}
            onClick={() => onDeactivateUser(id)}
          >
            <VisibilityTwoToneIcon />
          </Button>
        ) : (
          <Button className={classes.button} onClick={() => onActivateUser(id)}>
            <VisibilityOffTwoToneIcon />
          </Button>
        )}
        <Button
          className={clsx(classes.button, classes.deleteButton)}
          onClick={() => deleteUser(id)}
        >
          <DeleteTwoToneIcon />
        </Button>
      </ButtonGroup>
    </Paper>
  );
};

export default withStyles(styles)(UserCard);
