import { Button, createStyles, DialogActions, DialogContent, Theme, withStyles, WithStyles } from '@material-ui/core';
import translate from 'counterpart';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import * as yup from 'yup';
import { clearItems, getWarranty } from '../../../../reducers/warranty';
import AdaptiveDialog from '../AdaptiveDialog';
import UseWarrantyModalTable from './UseWarrantyModalTable';

const styles = (theme: Theme) => createStyles({
  paper: {
    overflowY: 'visible',
    [theme.breakpoints.down('xs')]: {
      overflowY: 'auto'
    }
  },
  content: {
    padding: '40px 16px 24px',
    overflowY: 'visible'
  },
  contentTable: {
    display: 'flex',
    padding: 0
  },
  actions: {
    padding: '0px 16px 24px'
  }
});

interface UseWarrantyModalWindowProps extends WithStyles<typeof styles> {
  items: any[];
  totalCuts?: number;
  getWarranty: ((phone) => void);
  clearItems: (() => void);
  showUseWarranty: boolean;
  handleShowUseWarranty: (() => void);
}

interface UseWarrantyModalWindowState {
  show: boolean;
}

export class UseWarrantyModalWindow extends React.Component<UseWarrantyModalWindowProps, UseWarrantyModalWindowState> {

  readonly state: UseWarrantyModalWindowState = {
    show: true
  };

  findWarranty = (values): void => {
    const { getWarranty } = this.props;
    const { phoneNumber } = values;
    getWarranty(phoneNumber);
    this.handleShow();
  };

  handleShow = (): void => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  handleClose = (): void => {
    const { showUseWarranty, clearItems, handleShowUseWarranty } = this.props;
    if (showUseWarranty) {
      this.setState({ show: true });
      clearItems();
    }
    handleShowUseWarranty();
  };

  render = (): React.ReactNode => {
    const { items, showUseWarranty, classes } = this.props;
    const { show } = this.state;
    const initialValues = { phoneNumber: '' };
    const formSchema = yup.object({
      phoneNumber: yup.string().required()
    });
    return (
      <AdaptiveDialog
        open={showUseWarranty}
        onClose={this.handleClose}
        title={show ? translate('menu.search') : ''}
        closeButton={true}
        paperClass={classes.paper}>
        {show ? (
          <Formik
            onSubmit={this.findWarranty}
            isInitialValid={false}
            validationSchema={formSchema}
            initialValues={initialValues}>
            {(props: FormikProps<any>) => {
              const { setFieldValue, values, isValid } = props;
              return (
                <Form>
                  <DialogContent
                    className={classes.content}>
                    <ReactPhoneInput
                      country={'ru'}
                      masks={{ th: '+.. ..........', lt: '+... ...-.-..-..' }}
                      value={values.phoneNumber}
                      onChange={(value) => setFieldValue('phoneNumber', value, true)} />
                  </DialogContent>
                  <DialogActions
                    className={classes.actions}>
                    <Button variant={'contained'} color={'primary'} type={'submit'} disabled={!isValid}>
                      <Translate content={'menu.search'} />
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <DialogContent
            className={classes.contentTable}>
            <UseWarrantyModalTable
              data={items}
              close={this.handleClose} />
          </DialogContent>
        )}
      </AdaptiveDialog>
    );
  };
}

const mapDispatchToProps = {
  getWarranty,
  clearItems
};

const mapStateToProps = (state) => ({
  items: state.warranty.itemsWarranty
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UseWarrantyModalWindow));
