const LOAD = 'balances/LOAD';
const LOAD_SUCCESS = 'balances/LOAD_SUCCESS';
const LOAD_FAIL = 'balances/LOAD_FAIL';
const LOAD_CUTS = 'balances/LOAD_CUTS';
const LOAD_CUTS_SUCCESS = 'balances/LOAD_CUTS_SUCCESS';
const LOAD_CUTS_FAIL = 'balances/LOAD_CUTS_FAIL';

export const initialState = {
  items: [],
  cutsNumber: undefined
};

// Reducer

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        items: action.result.data
      };
    case LOAD_CUTS_SUCCESS:
      return {
        ...state,
        cutsNumber: action.result.data
      };
    case LOAD_CUTS_FAIL:
      return {
        ...state,
        cutsNumber: undefined
      };
    default:
      return state;
  }
}

// Actions

export function loadBalances(organization, dateFrom, dateTo) {
  const url = organization === null ? '/api/balances' : '/api/balances/' + organization;
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get(url, {
    params: {
      start: dateFrom,
      end: dateTo
    }
  })
  };
}

export function loadCutsNumber(organization) {
  if(organization === ''){
    return {
      type: LOAD_CUTS_FAIL
    }
  }
  return {
    types: [LOAD_CUTS, LOAD_CUTS_SUCCESS, LOAD_CUTS_FAIL],
    promise: client => client.get('/api/balances/' + organization + '/cuts')
  };
}
