import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import {
  redirectToDenied,
  redirectToLoginWithMessage
} from '../reducers/authentication';

export interface PrivateRouteProps extends RouteProps {
  roles: string[];
  loading: boolean;
  isAuthenticated: boolean;
  allowedRoles: string[];
  redirectToDenied: () => void;
  redirectToLoginWithMessage: (message: string) => void;
  wrapper: any;
}

export class PrivateRoute<T extends PrivateRouteProps> extends React.Component<
  T
> {
  public componentDidMount = (): void => {
    this.redirectIfNot();
  };

  public componentDidUpdate = (): void => {
    this.redirectIfNot();
  };

  hasMatchedRoles = (): boolean => {
    const { roles, allowedRoles } = this.props;
    for (let i in allowedRoles) {
      if (!roles.includes(allowedRoles[i])) {
        return false;
      }
    }
    return true;
  };

  redirectIfNot(): void {
    const {
      loading,
      isAuthenticated,
      redirectToDenied,
      redirectToLoginWithMessage
    } = this.props;
    if (isAuthenticated) {
      if (!loading && !this.hasMatchedRoles()) {
        redirectToDenied();
      }
    } else {
      if (!loading) {
        redirectToLoginWithMessage('login.error.private');
      }
    }
  }

  render = (): React.ReactNode => {
    const { loading, isAuthenticated, wrapper: Wrapper } = this.props;
    return (
      <Route
        {...this.props}
        render={(other: RouteComponentProps) => {
          if (loading || !isAuthenticated) {
            return (
              <div className={'center loader'}>
                <div>Loading...</div>
              </div>
            );
          }
          return <Wrapper {...this.props} {...other} />;
        }}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  roles: state.authentication.roles,
  loading: state.authentication.loading,
  isAuthenticated: state.authentication.isAuthenticated
});

const mapDispatchToProps = {
  redirectToDenied,
  redirectToLoginWithMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
