import moment from 'moment';
import translate from 'counterpart';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export const parseDateTime = (dateTime: 'string' | Date): Date => {
  if (dateTime) {
    if (typeof dateTime == 'string') {
      if (dateTime.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)) {
        return moment(dateTime).toDate();
      } else if (dateTime.match(/^\d{2}:\d{2}:\d{2}(\.\d+)?$/)) {
        const time: string = dateTime.split('.')[0];
        return moment(time, 'HH:mm:ss').toDate();
      } else if (dateTime.match(/^\d{2}:\d{2}$/)) {
        return moment(dateTime, 'HH:mm').toDate();
      } else if (dateTime.match(/^\d{2}\.\d{2}\.\d{4}$/)) {
        return moment(dateTime, 'dd.MM.yyyy').toDate();
      } else {
        let date: Date;
        try {
          date = moment(dateTime).toDate();
        } catch (e) {
          date = null;
        }
        return date;
      }
    } else {
      return dateTime;
    }
  }
  return null;
};

export const generateWeek = (): Date[] => {
  let date: Date = new Date();
  const dates: Date[] = [new Date()];
  for (let i = 1; i < 7; i++) {
    date = moment(date).add(1, 'day').toDate();
    dates.push(date);
  }
  return dates;
};

export const getDayLabel = (day: number): string => {
  switch (day) {
    case 0: {
      return translate('picker.label.sun');
    }
    case 1: {
      return translate('picker.label.mon');
    }
    case 2: {
      return translate('picker.label.tue');
    }
    case 3: {
      return translate('picker.label.wed');
    }
    case 4: {
      return translate('picker.label.thu');
    }
    case 5: {
      return translate('picker.label.fri');
    }
    case 6: {
      return translate('picker.label.sat');
    }
    default: {
      return null;
    }
  }
};

export const formatTime = (date: Date): string => {
  if (date) {
    return format(date, 'HH:mm', { locale: ru });
  }
  return null;
};