import counterpart from 'counterpart';
import en from 'lang/en.json';
import ru from 'lang/ru.json';
import React from 'react';
import { setLocale } from 'reducers/locale';

const translations = { en, ru };

const locales = Object.keys(translations);

let currentLocale;
let savedLocale = localStorage.getItem('locale') || 'en';

const registerLocales = (store) => {
  locales.forEach(key => {
    counterpart.registerTranslations(key, translations[key]);
  });
  store.subscribe(() => {
    let previousLocale = currentLocale;
    currentLocale = store.getState().locale.currentLocale;
    if (previousLocale !== currentLocale) {
      localStorage.setItem('locale', currentLocale);
      counterpart.setLocale(currentLocale);
    }
  });
  store.dispatch(setLocale(savedLocale));
  return savedLocale;
};

export {
  locales,
  registerLocales
};
