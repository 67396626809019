import translate from 'counterpart';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions
} from 'mui-datatables';
import * as React from 'react';
import theme from '../../../theme/theme';
import {
  cellRender,
  csvDownload,
  textLabels
} from '../../../utils/table-utils';

const changePositionCellRender = (value: any): string | React.ReactNode => {
  if (value < 0) {
    return <div style={{ color: theme.colors.red }}>{value}</div>;
  }
  if (value > 0) {
    return <div style={{ color: theme.colors.darkGreen }}>{'+' + value}</div>;
  }
  if (value === 0) {
    return <div>{'-'}</div>;
  }
};

interface RatingTableProps {
  data?: any[];
  title?: string;
}

export const RatingTable: React.FunctionComponent<RatingTableProps> = (
  props: RatingTableProps
) => {
  const { data, title } = props;
  const options: MUIDataTableOptions = {
    fixedHeader: false,
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true
    },
    filter: false,
    search: false,
    print: false,
    download: false,
    pagination: false,
    viewColumns: false,
    responsive: 'scrollMaxHeight',
    selectableRows: 'none',
    onDownload: csvDownload,
    textLabels: textLabels(translate)
  };
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'rankRating',
      label: translate('rating.rank'),
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: 'city',
      label: translate('rating.city'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender
      }
    },
    {
      name: 'name',
      label: translate('rating.name'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender
      }
    },
    {
      name: 'changePosition',
      label: '+/-',
      options: {
        sort: true,
        filter: true,
        customBodyRender: changePositionCellRender
      }
    }
  ];
  return (
    <MUIDataTable
      columns={columns}
      title={title}
      data={data}
      options={options}
    />
  );
};

export default RatingTable;
