import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../assets/styles/styles.scss';
import history from './config/history';
import initStore from './config/store';
import { registerLocales } from './config/translation';
import { logout, redirectToLoginWithMessage } from './reducers/authentication';
import { setupAxiosInterceptors } from './rest/axios';
import Routes from './router/Routes';
import theme from './theme/theme';

const store = initStore();
registerLocales(store);

const actions = bindActionCreators(
  { redirectToLoginWithMessage, logout },
  store.dispatch
);
setupAxiosInterceptors(() =>
  actions.redirectToLoginWithMessage('login.error.unauthorized')
);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={'rootScreen'}>
        <Routes history={history} onLogout={actions.logout} />
      </div>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
