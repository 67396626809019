const COUNT_NOTIFICATIONS_BY_POINT = 'notifications/COUNT_NOTIFICATIONS_BY_POINT';
const COUNT_NOTIFICATIONS_BY_POINT_SUCCESS = 'notifications/COUNT_NOTIFICATIONS_BY_POINT_SUCCESS';
const COUNT_NOTIFICATIONS_BY_POINT_FAIL = 'notifications/COUNT_BY_NOTIFICATIONS_POINT_FAIL';

const FETCH_NOTIFICATIONS_BY_POINT = 'notifications/FETCH_NOTIFICATIONS_BY_POINT';
const FETCH_NOTIFICATIONS_BY_POINT_SUCCESS = 'notifications/FETCH_NOTIFICATIONS_BY_POINT_SUCCESS';
const FETCH_NOTIFICATIONS_BY_POINT_FAIL = 'notifications/FETCH_BY_NOTIFICATIONS_POINT_FAIL';

const MARK_NOTIFICATIONS_AS_READ = 'notifications/MARK_NOTIFICATIONS_AS_READ';
const MARK_NOTIFICATIONS_AS_READ_SUCCESS = 'notifications/MARK_NOTIFICATIONS_AS_READ_SUCCESS';
const MARK_NOTIFICATIONS_AS_READ_FAIL = 'notifications/MARK_NOTIFICATIONS_AS_READ_FAIL';

export const initialState = {
  all: 0,
  items: []
};

// Reducer

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case COUNT_NOTIFICATIONS_BY_POINT:
      return {
        ...state,
        all: 0
      };
    case COUNT_NOTIFICATIONS_BY_POINT_SUCCESS:
      return {
        ...state,
        all: action.result.data
      };
    case COUNT_NOTIFICATIONS_BY_POINT_FAIL:
      return {
        ...state,
        all: 0
      };
    case FETCH_NOTIFICATIONS_BY_POINT:
      return {
        ...state,
        items: []
      };
    case FETCH_NOTIFICATIONS_BY_POINT_SUCCESS:
      return {
        ...state,
        items: action.result.data
      };
    case FETCH_NOTIFICATIONS_BY_POINT_FAIL:
      return {
        ...state,
        items: []
      };
    default:
      return state;
  }
}

// Actions

export function countNotificationsByPoint(pointId, dateFrom, dateTo) {
  return {
    types: [COUNT_NOTIFICATIONS_BY_POINT, COUNT_NOTIFICATIONS_BY_POINT_SUCCESS, COUNT_NOTIFICATIONS_BY_POINT_FAIL],
    promise: client => client.get('/api/notifications/' + pointId + '/count', {
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo
      }
    })
  };
}

export function fetchNotificationsByPoint(pointId, dateFrom, dateTo) {
  return {
    types: [FETCH_NOTIFICATIONS_BY_POINT, FETCH_NOTIFICATIONS_BY_POINT_SUCCESS, FETCH_NOTIFICATIONS_BY_POINT_FAIL],
    promise: client => client.get('/api/notifications/' + pointId, {
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo
      }
    })
  };
}

export function markNotificationsAsRead(pointId, date, dateFrom, dateTo) {
  return {
    types: [MARK_NOTIFICATIONS_AS_READ, MARK_NOTIFICATIONS_AS_READ_SUCCESS, MARK_NOTIFICATIONS_AS_READ_FAIL],
    promise: client => client.get('/api/notifications/' + pointId + '/read', {
      params: {
        date: date,
      }
    }),
    afterSuccess: (dispatch) => {
      dispatch(fetchNotificationsByPoint(pointId, dateFrom, dateTo));
    }
  };
}

