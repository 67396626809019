import {
  Breadcrumbs,
  createStyles,
  Grid,
  Hidden,
  Link,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core';
import translate from 'counterpart';
import * as React from 'react';
import { connect } from 'react-redux';
import history from '../../../config/history';
import { chooseBrand } from '../../../reducers/brand';
import { fetchModels } from '../../../reducers/model';
import BackButton from '../BackButton';
import BrandSearch from '../BrandSearch';
import Page from '../Page';
import ListItem from './ListItem';
import { chooseCategory } from '../../../reducers/category';

const styles = (theme: Theme) => createStyles({
  titleContainer: {
    display: 'table',
    borderSpacing: theme.spacing(1)
  },
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  count: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.colors.brono,
    padding: '4px 8px',
    background: 'rgba(0, 182, 180, 0.1)',
    border: '1px solid ' + theme.colors.brono,
    borderRadius: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  }
});

interface ModelListProps extends WithStyles<typeof styles> {
  brandId: number;
  categoryId: number;
  category: any;
  totalCuts: number;
  items: any[];
  brand: any;
  model: any;
  chooseBrand: ((brandId) => void);
  chooseCategory: (categoryId) => void;
  fetchModels: ((brandId, categoryId) => void);
}

export class ModelList extends React.Component<ModelListProps> {

  componentDidMount = (): void => {
    const { chooseBrand, chooseCategory, fetchModels, brandId, categoryId } = this.props;
    chooseBrand(brandId);
    chooseCategory(categoryId);
    fetchModels(brandId, categoryId);
  };

  renderActionsBar = (): React.ReactNode => {
    const { classes, items, brand, brandId, categoryId, category } = this.props;
    const selectedBrand = brand.categoryItems.find(x => x.id == brandId);
    const selectedCategory = category.items.find(x => x.id == categoryId);
    return (
      <Grid
        container={true}
        direction={'column'}
        spacing={2}
        style={{ margin: '0px -8px' }}>
        <Hidden smUp={true}>
          <Grid item={true}>
            <BrandSearch />
          </Grid>
        </Hidden>
        <Grid item={true}>
          <Grid
            container={true}
            direction={'row'}
            justify={'space-between'}
            alignItems={'center'}
            spacing={2}
            style={{ flexWrap: 'nowrap' }}>
            <Grid item={true}>
              <Grid
                container={true}
                direction={'row'}
                alignItems={'center'}
                justify={'flex-start'}
                spacing={2}>
                <Grid item={true}>
                  <BackButton onClick={() => history.push(`/category/${categoryId}/brand`)} />
                </Grid>
                <Grid item={true}>
                  <Grid
                    container={true}
                    direction={'row'}
                    alignItems={'center'}
                    spacing={1}
                    style={{ flexWrap: 'nowrap' }}>
                    <Grid item={true}>
                      <Breadcrumbs className={classes.title}>
                        <Link
                          color={'textPrimary'}
                          href={`/category/${categoryId}/brand`}
                        >
                          {selectedCategory && selectedCategory.name}
                        </Link>
                        <Link
                          color={'inherit'}
                        >
                          {selectedBrand && selectedBrand.name}
                        </Link>
                      </Breadcrumbs>
                    </Grid>
                    <Grid item={true}>
                      <div className={classes.count}>
                        {items.length}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Hidden xsDown={true}>
              <Grid item={true}>
                <BrandSearch />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderThis = (): React.ReactNode => {
    const { brandId, categoryId, totalCuts, items } = this.props;
    const error: string = totalCuts <= 0
      ? translate('side.label.noCuts')
      : null;
    const itemProps = {
      action: (id) => history.push(`/category/${categoryId}/brand/${brandId}/model/${id}/templates`),
      totalCuts: totalCuts
    };
    return (
      <Page
        actions={this.renderActionsBar()}
        error={error}>
        <Grid
          container={true}
          direction={'row'}
          spacing={3}
          style={{ height: 'min-content' }}>
          {items.map((item) => (
            <Grid item={true} xs={12} sm={6} md={4} lg={3} xl={2} key={item.id}>
              <ListItem item={item} device={true} {...itemProps} />
            </Grid>
          ))}
        </Grid>
      </Page>
    );
  };

  render = (): React.ReactNode => {
    const { children } = this.props;
    return children ? children : this.renderThis();
  };
}

const mapStateToProps = (state, ownProps) => ({
  params: ownProps.match.params,
  brandId: ownProps.match.params.brandId,
  categoryId: ownProps.match.params.categoryId,
  category: state.category,
  items: state.model.items,
  brand: state.brand,
  totalCuts: state.balances.cutsNumber,
  currentLocale: state.locale.currentLocale
});

const mapDispatchToProps = {
  fetchModels,
  chooseBrand,
  chooseCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModelList));
