import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccessDenied from '../ui/component/AccessDenied';
import App from '../ui/component/App';
import Balances from '../ui/component/balance/Balances';
import AdminBookingList from '../ui/component/booking/AdminBookingList';
import UserBookingList from '../ui/component/booking/UserBookingList';
import BrandList from '../ui/component/brand/BrandList';
import ChooseTemplate from '../ui/component/brand/ChooseTemplate';
import ModelList from '../ui/component/brand/ModelList';
import SearchResult from '../ui/component/brand/SearchResult';
import LoginForm from '../ui/component/LoginForm';
import Rating from '../ui/component/rating/Rating';
import Report from '../ui/component/report/Report';
import Services from '../ui/component/services/Services';
import UserForm from '../ui/component/user/UserForm';
import UserList from '../ui/component/user/UserList';
import { LocalizedUtils } from '../utils/LocalizedUtils';
import HasCutsRoute from './HasCutsRoute';
import PrivateRoute from './PrivateRoute';
import Instructions from '../ui/component/instructions/Instructions';
import CategoryList from '../ui/component/brand/CategoryList';
import CutReports from "../ui/component/cutReports/CutReports";

const Routes = ({ history, onLogout, roles, locale }): React.ReactElement => (
  <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
    <ConnectedRouter history={history}>
      <App>
        <Switch>
          <PrivateRoute
            exact={true}
            path={'/settings'}
            wrapper={Services}
            allowedRoles={['ROLE_ADMIN']}
          />
          <Route
            path={'/instructions'}
            component={Instructions}
          />
          <PrivateRoute
            exact={true}
            path={'/booking'}
            wrapper={AdminBookingList}
            allowedRoles={['ROLE_ADMIN', 'ROLE_BOOKING']}
          />
          <PrivateRoute
            exact={true}
            path={'/booking/user'}
            wrapper={UserBookingList}
            allowedRoles={['ROLE_USER', 'ROLE_BOOKING']}
          />
          <PrivateRoute
            exact={true}
            path={'/category'}
            wrapper={CategoryList}
            allowedRoles={['ROLE_USER']}
          />
          <HasCutsRoute
            exact={true}
            path={'/category/:categoryId/brand'}
            wrapper={BrandList}
            allowedRoles={['ROLE_USER']}
          />
          <HasCutsRoute
            exact={true}
            path={'/category/:categoryId/brand/:brandId/model'}
            wrapper={ModelList}
            allowedRoles={['ROLE_USER']}
          />
          <HasCutsRoute
            exact={true}
            path={'/category/:categoryId/brand/:brandId/model/:modelId/templates'}
            wrapper={ChooseTemplate}
            allowedRoles={['ROLE_USER']}
          />
          <PrivateRoute
            exact={true}
            path={'/brand/models'}
            wrapper={SearchResult}
            allowedRoles={['ROLE_USER']}
          />
          <PrivateRoute
            path={'/rating'}
            wrapper={Rating}
            allowedRoles={['ROLE_ADMIN', 'ROLE_RATING']}
          />
          <PrivateRoute
            path={'/users'}
            wrapper={UserList}
            allowedRoles={['ROLE_ADMIN']}
          />
          <PrivateRoute
            path={'/createUser'}
            wrapper={UserForm}
            allowedRoles={['ROLE_ADMIN']}
          />
          <PrivateRoute
            path={'/reports'}
            wrapper={Report}
            allowedRoles={['ROLE_ADMIN']}
          />
          <PrivateRoute
            path={'/balances'}
            wrapper={Balances}
            allowedRoles={['ROLE_ADMIN']}
          />
          <PrivateRoute
            path={'/cutReports'}
            wrapper={CutReports}
            allowedRoles={['ROLE_USER']}
          />
          <Route path={'/login'} component={LoginForm} />
          <Route
            path={'/logout'}
            render={() => {
              onLogout();
              return <Redirect to={'/'} />;
            }}
          />
          <Route path={'/denied'} component={AccessDenied} />
          <Route
            exact={true}
            path={'/'}
            render={() => (
              <Redirect
                from={'/'}
                to={roles.includes('ROLE_ADMIN') ? 'users' : (roles.includes('ROLE_BOOKING') ? 'booking/user' : 'category')}
              />
            )}
          />
          <Route path={'*'} render={() => <Redirect to={'/'} />} />
        </Switch>
      </App>
    </ConnectedRouter>
  </MuiPickersUtilsProvider>
);

const mapStateToProps = (state) => {
  return {
    roles: state.authentication.roles,
    locale: state.locale.currentLocale
  };
};

export default connect(mapStateToProps)(Routes);
