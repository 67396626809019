import axios from 'axios';
import { showError } from '../reducers/errors';

const devMode = process.env.NODE_ENV !== 'production';

if (devMode) {
  axios.defaults.baseURL = 'http://vm-bronoskins.axmor.nsk:9080';
}

export default function promiseMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }
    const { promise, types, afterSuccess, ...rest } = action;
    if (!action.promise) {
      return next(action);
    }
    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, type: REQUEST });
    const onFulfilled = (result) => {
      next({ ...rest, result, type: SUCCESS });
      if (afterSuccess) {
        afterSuccess(dispatch, getState, result);
      }
    };
    const onRejected = (error) => {
      const errorMessage: string = error?.response?.data?.message;
      const errorId: string = error?.response?.data?.id;
      dispatch(showError(errorMessage, errorId));
      next({ ...rest, error, type: FAILURE });
    };
    return promise(axios)
      .then(onFulfilled, onRejected)
      .catch((error) => {
        console.error('MIDDLEWARE ERROR:', error);
        // onRejected(error)
      });
  };
}
