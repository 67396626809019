const FETCH_CATEGORIES = 'category/FETCH_CATEGORIES';
const FETCH_CATEGORIES_SUCCESS = 'category/FETCH_CATEGORIES_SUCCESS';
const FETCH_CATEGORIES_FAIL = 'category/FETCH_CATEGORIES_FAIL';

const FETCH_CATEGORY = 'category/FETCH_CATEGORY';
const FETCH_CATEGORY_SUCCESS = 'category/FETCH_CATEGORY_SUCCESS';
const FETCH_CATEGORY_FAIL = 'category/FETCH_CATEGORY_FAIL';

const CHOOSE_CATEGORY = 'category/CHOOSE_CATEGORY'

export const initialState = {
  items: [],
  item: []
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES: {
      return { ...state, items: [] };
    }
    case FETCH_CATEGORIES_SUCCESS: {
      return { ...state, items: action.result.data };
    }
    case FETCH_CATEGORIES_FAIL: {
      return { ...state, items: [] };
    }
    case CHOOSE_CATEGORY: {
      return {...state, categoryId: action.id}
    }
    case FETCH_CATEGORY: {
      return { ...state, item: '' };
    }
    case FETCH_CATEGORY_SUCCESS: {
      return { ...state, item: action.result.data };
    }
    case FETCH_CATEGORY_FAIL: {
      return { ...state, item: '' };
    }
    default: {
      return { ...state };
    }
  }
};

export function fetchCategories() {
  return {
    types: [FETCH_CATEGORIES, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAIL],
    promise: client => client.get('/api/category/list')
  };
}

export function getCategory(id: number) {
  return {
    types: [FETCH_CATEGORY, FETCH_CATEGORY_SUCCESS, FETCH_CATEGORY_FAIL],
    promise: client => client.get(`/api/category/${id}`)
  };
}

export function chooseCategory(id: number) {
  return {
    type: CHOOSE_CATEGORY,
    id
  };
}

export default categoryReducer;
