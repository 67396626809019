import { createStyles, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { FC } from 'react';
import WeekRow from './WeekRow';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flow-root',
      padding: '16px 0px 4px'
    },
    label: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600
    }
  })
);

interface Props {
  dateTime?: Date;
  onChange?: (date: Date) => void;
}

const BookingTableTitle: FC<Props> = (props: Props) => {
  const { dateTime, onChange } = props;
  const classes = useStyles();
  return (
    <Grid
      container={true}
      direction={'column'}
      spacing={1}
      className={classes.container}
    >
      <Grid item={true} style={{ display: 'flex' }}>
        <WeekRow dateTime={dateTime} onChange={onChange} />
      </Grid>
      <Grid item={true}>
        <Typography color={'textSecondary'} className={classes.label}>
          {moment(dateTime).format('D MMMM')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BookingTableTitle;
