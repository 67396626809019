import { useMediaQuery, useTheme } from '@material-ui/core';
import translate from 'counterpart';
import * as React from 'react';
import Translate from 'react-translate-component';

interface MobileTranslateProps {
  desktop: string;
  mobile: string;
}

export const MobileTranslate: React.FunctionComponent<MobileTranslateProps> = (props: MobileTranslateProps) => {
  const { desktop, mobile } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (<Translate content={isMobile ? mobile : desktop} />);
};

export const mobileTranslate = (desktop, mobile): string => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return translate(isMobile ? mobile : desktop);
};

export default MobileTranslate;
