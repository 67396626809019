import { createStyles, Grid, Hidden, Theme, withStyles, WithStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import * as React from 'react';
import CutsCount from './CutsCount';

const styles = (theme: Theme) => createStyles({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  }
});

interface HeaderProps extends WithStyles<typeof styles> {
  handleMenu: (() => void);
}

export const Header: React.FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  const { classes, handleMenu } = props;
  return (
    <AppBar
      position={'fixed'}
      className={classes.appBar}>
      <Toolbar>
        <Grid
          container={true}
          direction={'row'}
          alignItems={'center'}
          justify={'space-between'}>
          <Grid item={true}>
            <IconButton
              aria-label={'menu'}
              onClick={() => handleMenu()}>
              <MenuIcon />
            </IconButton>
          </Grid>
          <Hidden only={'xs'}>
            <Grid item={true}>
              <img
                src={'/images/logo.png'}
                alt={'logo'}
                style={{ height: '18.5px', width: '130px' }}
              />
            </Grid>
          </Hidden>
          <Grid item={true}>
            <CutsCount />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>);
};

export default withStyles(styles)(Header);
