import {
  createStyles,
  Hidden,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
import translate from 'counterpart';
import { ChangeEvent, default as React } from 'react';

const styles = (theme: Theme) => createStyles({
  tableRow: {
    verticalAlign: 'middle'
  },
  navigationButton: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  }
});

interface TablePaginationFooterProps extends WithStyles<typeof styles> {
  page: number;
  rowCount: number;
  rowsPerPage: number;
  changeRowsPerPage: ((rowsPerPage: number) => void);
  changePage: ((page: number) => void);
}

const TablePaginationFooter: React.FunctionComponent<TablePaginationFooterProps> = (props: TablePaginationFooterProps) => {
  const { page, rowCount, rowsPerPage, changeRowsPerPage, changePage, classes } = props;
  return (
    <TableFooter>
      <TableRow className={classes.tableRow}>
        <TablePagination
          count={rowCount}
          page={page}
          rowsPerPage={rowsPerPage}
          backIconButtonProps={{ className: classes.navigationButton }}
          nextIconButtonProps={{ className: classes.navigationButton }}
          labelRowsPerPage={translate('textLabels.pagination.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }: LabelDisplayedRowsArgs) => {
            return from + '-' + (to === -1 ? count : to)
              + ' ' + translate('textLabels.pagination.displayRows') + ' '
              + (count !== -1 ? count
                  : (translate('textLabels.pagination.moreThan') + ' ' + to)
              );
          }}
          onChangePage={(e: React.MouseEvent<HTMLButtonElement>, page: number) => {
            changePage(page);
          }}
          onChangeRowsPerPage={(e: ChangeEvent<HTMLInputElement>) => {
            changeRowsPerPage(Number(e.target.value));
          }} />
        <Hidden mdDown={true}>
          <TableCell align={'right'}>
            <Pagination
              page={page + 1}
              count={Math.ceil(rowCount / rowsPerPage)}
              showFirstButton={true}
              showLastButton={true}
              onChange={(e: object, page: number) => {
                changePage(page - 1);
              }} />
          </TableCell>
        </Hidden>
      </TableRow>
    </TableFooter>
  );
};

export default withStyles(styles)(TablePaginationFooter);
