import * as React from 'react';
import Translate from 'react-translate-component';
import AdaptiveDialog from '../AdaptiveDialog';
import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import translate from 'counterpart';
import moment from 'moment';
import { createBooking, unlockTime } from '../../../../reducers/booking';
import { useDispatch } from 'react-redux';
import { format, formatISO } from 'date-fns';
import { parseDateTime } from '../../../../utils/date-utils';
import { AnyAction } from 'redux';

const styles = (theme: Theme) => createStyles({
  paper: {
    overflowY: 'visible',
    [theme.breakpoints.down('xs')]: {
      overflowY: 'auto'
    }
  },
  content: {
    padding: '16px 16px 32px',
    overflowY: 'visible'
  },
  contentTable: {
    display: 'flex',
    padding: 0
  },
  search: {
    padding: '8px',
    backgroundColor: 'white',
    boxShadow: '0px 2px 8px rgba(138, 160, 181, 0.25)'
  },
  actions: {
    padding: '0px 16px 8px'
  },
  divider: {
    marginBottom: '4px'
  },
  text: {
    padding: '16px 16px 32px 8px'
  }
});

interface BookingDeleteDialogProps extends WithStyles<typeof styles> {
  rowDate: Date;
  rowTime: string;
  bookingId: number;
  phone: string;
  type: string;
  pointId: number;
  show: boolean;
  handleShowDeleteBooking: () => void;
}

const BookingDeleteDialog: React.FunctionComponent<BookingDeleteDialogProps> = (props: BookingDeleteDialogProps) => {
  const { show, classes, rowTime, rowDate } = props;
  const dispatch = useDispatch();

  function handleClose() {
    const { handleShowDeleteBooking } = props;
    handleShowDeleteBooking();
  }

  const handleDelete = async () => {
    const { handleShowDeleteBooking, bookingId, rowDate, rowTime, phone, type, pointId } = props;
    let year = rowDate.getUTCFullYear();
    let month = rowDate.getUTCMonth() + 1;
    let day = rowDate.getUTCDate();
    let dateTime = new Date(moment(year + '-' + month + '-' + day + ' ' + rowTime).format());
    const dto: any = {
      date: formatISO(parseDateTime(dateTime), { representation: 'date' }),
      time: format(parseDateTime(dateTime), 'HH:mm:ss'),
      tradePointId: pointId,
      type: type,
      modelServices: null,
      customerName: null,
      customerPhone: phone,
      customerEmail: null,
      warrantyId: null
    };
    dispatch(unlockTime(dto, bookingId, formatISO(parseDateTime(dateTime), { representation: 'date' })));
    handleShowDeleteBooking();
  };

  return (
    <AdaptiveDialog
      open={show}
      onClose={handleClose}
      paperClass={classes.paper}
    >
      <DialogContent className={classes.content}>
        <Grid container={true} direction={'column'} spacing={2}>
          <Typography className={classes.text}>
            {translate('booking.dialog.deleteBooking')}
            <strong>{' ' + translate('booking.dialog.booking') + ' ' + rowTime + ' ' + moment(rowDate).format('D MMMM, ddd') + '?'}</strong>
          </Typography>
        </Grid>
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.actions}>
        <Button
          variant={'contained'}
          color={'default'}
          type={'button'}
          disabled={false}
          onClick={handleClose}
        >
          <Translate content={'booking.dialog.close'} />
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          type={'submit'}
          onClick={handleDelete}
        >
          <Translate content={'booking.dialog.delete'} />
        </Button>
      </DialogActions>
    </AdaptiveDialog>
  );
};

export default withStyles(styles)(BookingDeleteDialog);