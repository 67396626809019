import {
  Button,
  createStyles,
  DialogContent,
  Grid,
  Hidden,
  MenuItem,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import translate from 'counterpart';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import { fetchTradePoints } from '../../../reducers/points';
import { loadReport } from '../../../reducers/reports';
import theme from '../../../theme/theme';
import AdaptiveDialog from '../dialog/AdaptiveDialog';
import FilledTextField from '../field/FilledTextField';
import Page from '../Page';
import ReportTable from './ReportTable';

const styles = (theme: Theme) => createStyles({
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  form: {
    width: '100%'
  },
  submitButton: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
      marginTop: '16px'
    }
  },
  tableContainer: {
    flexWrap: 'nowrap',
    height: 'calc(100% + 24px)',
    [theme.breakpoints.down('xs')]: {
      margin: '-8px -8px -16px',
      width: 'calc(100% + 16px)'
    }
  },
  tableItem: {
    maxHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      marginBottom: '-8px',
      maxHeight: '100%'
    }
  }
});

interface ReportProps extends WithStyles<typeof styles> {
  items: any[];
  userOrgId: number;
  roles: any;
  points: any[];
  currentLocale: string;
  loadReport: ((organization, tradePoint, dateFrom, dateTo) => void);
  fetchTradePoints: ((id) => void);
}

interface ReportState {
  filterOpen: boolean;
}

export class Report extends React.Component<ReportProps, ReportState> {

  readonly state: ReportState = {
    filterOpen: false
  };

  handleFilterDialog = (open: boolean) => {
    this.setState({ filterOpen: open });
  };

  onSubmit = (values) => {
    const { orgId, tradePointId, dateFrom, dateTo } = values;
    this.props.loadReport(orgId, tradePointId === '' ? null : tradePointId, dateFrom, dateTo);
    this.handleFilterDialog(false);
  };

  componentDidMount() {
    const { fetchTradePoints, userOrgId, loadReport } = this.props;
    fetchTradePoints(userOrgId);
    //loadReport(userOrgId, null, null, null);
  }

  renderActionsBar = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Grid
        container={true}
        justify={'space-between'}
        alignItems={'center'}>
        <Grid item={true}>
          <Typography className={classes.title}>
            <Translate content={'reportPage.title.reports'} />
          </Typography>
        </Grid>
        <Hidden smUp={true}>
          <Grid item={true}>
            <Button
              variant={'outlined'}
              color={'primary'}
              size={'large'}
              onClick={() => this.handleFilterDialog(true)}>
              <Translate content={'reportPage.title.filter'} />
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    );
  };

  renderFilterForm = (): React.ReactNode => {
    const { userOrgId, points, classes } = this.props;
    const initialValues = {
      orgId: userOrgId,
      tradePointId: '',
      dateFrom: null,
      dateTo: null
    };
    const tradePoints = new Array({
      id: '',
      name: translate('report.label.allColPoints')
    }).concat(points);
    return (
      <Formik
        onSubmit={(values) => {
          this.onSubmit(values);
        }}
        initialValues={initialValues}>
        {(props: FormikProps<any>) => {
          const { values, setFieldValue, handleChange } = props;
          return (
            <Form className={classes.form}>
              <Grid
                container={true}
                direction={'row'}
                spacing={2}>
                <Grid item={true} sm={3} xs={12}>
                  <FilledTextField
                    fullWidth={true}
                    select={true}
                    name={'tradePointId'}
                    value={values.tradePointId ? String(values.tradePointId) : ''}
                    variant={'filled'}
                    label={translate('reportPage.form.tradePoint')}
                    onChange={handleChange}>
                    {tradePoints.map((o) => (
                      <MenuItem key={o.id} value={String(o.id)}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </FilledTextField>
                </Grid>
                <Grid item={true} sm={3} xs={12}>
                  <KeyboardDatePicker
                    autoOk={true}
                    clearable={true}
                    fullWidth={true}
                    size={'small'}
                    label={translate('reportPage.form.dateFrom')}
                    format={'DD/MM/YYYY'}
                    inputVariant={'filled'}
                    okLabel={translate('reportPage.form.ok')}
                    cancelLabel={translate('reportPage.form.cancel')}
                    clearLabel={translate('reportPage.form.clear')}
                    value={values.dateFrom}
                    onChange={(date) => {
                      setFieldValue('dateFrom', date ? date.toDate() : null, false);
                    }} />
                </Grid>
                <Grid item={true} sm={3} xs={12}>
                  <KeyboardDatePicker
                    autoOk={true}
                    clearable={true}
                    fullWidth={true}
                    label={translate('reportPage.form.dateTo')}
                    format={'DD/MM/YYYY'}
                    size={'small'}
                    inputVariant={'filled'}
                    okLabel={translate('reportPage.form.ok')}
                    cancelLabel={translate('reportPage.form.cancel')}
                    clearLabel={translate('reportPage.form.clear')}
                    value={values.dateTo}
                    onChange={(date) => {
                      setFieldValue('dateTo', date ? date.toDate() : null, false);
                    }} />
                </Grid>
                <Grid item={true} sm={3} xs={12}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    className={classes.submitButton}>
                    <Translate
                      content={'reportPage.form.apply'} />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render = (): React.ReactNode => {
    const { items, classes, currentLocale, roles } = this.props;
    const { filterOpen } = this.state;

    let saleItem = items;
    let total;
    const totalIndex = saleItem.findIndex((item) => item.total == true);

    if (totalIndex != -1) {
      total = saleItem[totalIndex];
    }

    const tableTitle: React.ReactNode = total ? (
      <Typography style={{ color: theme.colors.gray, fontSize: '14px', lineHeight: '24px' }}>
        <span>{translate('reportPage.table.sales') + ': '}</span>
        <strong>{total.saleCount}</strong>
        {roles.includes('ROLE_WARRANTY') &&
          <span style={{ marginLeft: '16px' }}>{translate('reportPage.table.replacement') + ': '}</span>}
        <strong>{total.replacementCount}</strong>
      </Typography>
    ) : null;
    return (
      <Page
        actions={this.renderActionsBar()}>
        <Grid
          container={true}
          direction={'column'}
          spacing={3}
          className={classes.tableContainer}>
          <Hidden xsDown={true}>
            <Grid item={true}>
              {this.renderFilterForm()}
            </Grid>
          </Hidden>
          <Grid
            item={true} xs={true}
            className={classes.tableItem}>
            <ReportTable
              roles={roles}
              title={tableTitle}
              data={items}
              currentLocale={currentLocale} />
          </Grid>
        </Grid>
        <AdaptiveDialog
          open={filterOpen}
          title={translate('reportPage.title.filter')}
          onClose={() => this.handleFilterDialog(false)}>
          <DialogContent
            style={{ padding: '16px 16px 24px' }}>
            {this.renderFilterForm()}
          </DialogContent>
        </AdaptiveDialog>
      </Page>
    );
  };
}

const mapStateToProps = (state) => ({
  items: state.reports.items,
  userOrgId: state.authentication.organizationId,
  roles: state.authentication.roles,
  points: state.points.items,
  currentLocale: state.locale.currentLocale
});

const mapDispatchToProps = {
  loadReport,
  fetchTradePoints
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Report));
