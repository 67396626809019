import moment from 'moment';
import { AnyAction, Reducer } from 'redux';

export interface LocaleState {
  currentLocale: 'en' | 'ru';
}

export const initialState: LocaleState = {
  currentLocale: 'en'
};

// Reducer

const reducer: Reducer<LocaleState> = (
  state: LocaleState = initialState,
  action: AnyAction
): LocaleState => {
  switch (action.type) {
    case SET_LOCALE: {
      moment.locale(action.locale);
      return { ...state, currentLocale: action.locale };
    }
    default: {
      return state;
    }
  }
};

export { reducer as localeReducer };

// Actions

const SET_LOCALE = 'locale/SET_LOCALE';

export const setLocale = (locale: 'en' | 'ru') => ({
  type: SET_LOCALE,
  locale
});
