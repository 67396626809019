import {CutReportRecordDTO} from "../models/CutReportRecordDTO";

const FETCH_CUT_REPORTS = 'cutReports/FETCH_CUT_REPORTS';
const FETCH_CUT_REPORTS_SUCCESS = 'cutReports/FETCH_CUT_REPORTS_SUCCESS';
const FETCH_CUT_REPORTS_FAIL = 'cutReports/FETCH_CUT_REPORTS_FAIL';

export interface State {
  items: CutReportRecordDTO[];
  loading: boolean;
}

export const initialState: State = {
  items: [],
  loading: false,
};

const cutReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUT_REPORTS: {
      return { ...state, items: [], loading: true };
    }
    case FETCH_CUT_REPORTS_SUCCESS: {
      return { ...state, items: action.result.data, loading: false };
    }
    case FETCH_CUT_REPORTS_FAIL: {
      return { ...state, items: [], loading: false };
    }
    default: {
      return state;
    }
  }
}

export const fetchCutReports = (tradePointId: number) => {
  return {
    types: [FETCH_CUT_REPORTS, FETCH_CUT_REPORTS_SUCCESS, FETCH_CUT_REPORTS_FAIL],
    promise: client => client.get(`/api/reports/cuts/list?tradePointId=${tradePointId}`)
  }
}

export default cutReportsReducer;