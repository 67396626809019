import React from "react";
import {createStyles, Grid, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {CutReportRecordDTO} from "../../../models/CutReportRecordDTO";
import {fetchCutReports} from "../../../reducers/cutReports";
import {connect} from "react-redux";
import Translate from "react-translate-component";
import Page from "../Page";
import MUIDataTable, {MUIDataTableColumn} from "mui-datatables";
import translate from "counterpart";

const styles = (theme: Theme) => createStyles({
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  tableContainer: {
    width: '100%',
    ['& > div']: {
      width: '100%',
      maxHeight: '100%',
      ['& table']: {
        width: '100%',
        maxHeight: '100%'
      }
    },
    [theme.breakpoints.down('xs')]: {
      margin: '-8px -8px -16px',
      width: 'calc(100% + 16px)',
      maxHeight: 'calc(100% - 8px)',
    },
  },
})

interface Props extends WithStyles<typeof styles> {
  items: CutReportRecordDTO[];
  loading: boolean;
  selectedTradePointId: number;
  fetchCutReports: (tradePointId: number) => void;
}

class CutReports extends React.Component<Props> {

  componentDidMount() {
    const { fetchCutReports, selectedTradePointId } = this.props;
    fetchCutReports(selectedTradePointId);
  }

  render() {
    const { classes, items } = this.props;
    const getColLabel = (key: string) => translate(`cutReportsPage.table.${key}`);
    const columns: Array<MUIDataTableColumn & { name: keyof CutReportRecordDTO }> = [
      {
        name: 'date',
        label: getColLabel('colDate'),
        options: {
          customBodyRender: value => (value && new Date(value).toLocaleString()) || '-',
        },
      },
      {
        name: 'model',
        label: getColLabel('colModel'),
      },
      {
        name: 'template',
        label: getColLabel('colTemplate'),
      },
    ]
    return (
      <Page
        actions={
          <Typography className={classes.title}>
            <Translate content={'cutReportsPage.title'}/>
          </Typography>
        }
      >
        <Grid container className={classes.tableContainer}>
          <MUIDataTable
            columns={columns}
            data={items}
            title={<></>}
            options={{
              filter: false,
              search: false,
              print: false,
              sort: false,
              viewColumns: false,
              responsive: 'scrollMaxHeight',
              selectableRows: 'none',
              download: false,
              pagination: false,
            }}
          />
        </Grid>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  items: state.cutReports.items,
  loading: state.cutReports.loading,
  selectedTradePointId: state.points.selectedPoint.id,
});

const mapDispatchToProps = {
  fetchCutReports,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CutReports));