export abstract class StringUtils {

  static isBlank = (value: string): boolean => {
    let length;
    if (value == null || (length = value.length) === 0) {
      return true;
    }
    for (let i = 0; i < length; i++) {
      if (!(value.charAt(i) === ' ')) {
        return false;
      }
    }
    return true;
  };

  /**
   * Функция возвращает окончание для множественного числа слова на основании числа и массива окончаний.
   * @param  iNumber Integer Число на основе которого нужно сформировать окончание
   * @param  aEndings Array Массив слов или окончаний для чисел (1, 4, 5),
   *         например ['яблоко', 'яблока', 'яблок']
   * @return String
   */
  static getNumEnding = (iNumber: number, aEndings: string[]): string => {
    let sEnding, i;
    iNumber = iNumber % 100;
    if (iNumber >= 11 && iNumber <= 19) {
      sEnding = aEndings[2];
    } else {
      i = iNumber % 10;
      switch (i) {
        case (1):
          sEnding = aEndings[0];
          break;
        case (2):
        case (3):
        case (4):
          sEnding = aEndings[1];
          break;
        default:
          sEnding = aEndings[2];
      }
    }
    return sEnding;
  };

  static insertString = (sourceString: string, insertString: string, index: number): string => {
    return sourceString.slice(0, index) + insertString + sourceString.slice(index);
  };
}
