import { createStyles, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';
import translate from 'counterpart';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions
} from 'mui-datatables';
import React, { FC, Fragment, ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BookingType } from '../../../models/BookingType';
import { Schedule, Week } from '../../../models/Schedule';
import { TradePoint } from '../../../models/TradePoint';
import { updateTradePointSchedule } from '../../../reducers/points';
import { formatWeek } from '../../../utils/schedule-utils';
import {
  cellRender,
  csvDownload,
  textLabels
} from '../../../utils/table-utils';

const useStyles = makeStyles(() =>
  createStyles({
    schedule: {
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
      minWidth: '120px'
    }
  })
);

interface Props {
  title?: string | ReactNode;
  data: TradePoint[];
}

const TradePointTable: FC<Props> = (props: Props) => {
  const { title, data } = props;
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [tradePoint, setTradePoint] = useState<TradePoint>(null);
  const [bookingType, setBookingType] = useState<BookingType>(null);
  const dispatch = useDispatch();
  const handleDialogOpen = (
    tradePoint: TradePoint,
    type: BookingType
  ): void => {
    setTradePoint(tradePoint);
    setBookingType(type);
    setDialogOpen(true);
  };
  const handleDialogClose = (): void => {
    setTradePoint(null);
    setBookingType(null);
    setDialogOpen(false);
  };
  const handleDialogSubmit = (week: Week): void => {
    const schedule: Schedule = { ...tradePoint?.schedule, [bookingType]: week };
    dispatch(updateTradePointSchedule(tradePoint?.id, schedule));
    handleDialogClose();
  };
  const scheduleRender = (id: number, type: BookingType): ReactNode => {
    const tradePoint: TradePoint = data?.find((tradePoint: TradePoint) => {
      return tradePoint.id === id;
    });
    const week: Week = tradePoint?.schedule?.[type];
    return (
      <Grid
        container={true}
        direction={'row'}
        justify={'space-between'}
        wrap={'nowrap'}
        spacing={1}
      >
        <Grid item={true}>
          <Typography className={classes.schedule}>
            {cellRender(formatWeek(week))}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const options: MUIDataTableOptions = {
    fixedHeader: false,
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true
    },
    filter: false,
    search: false,
    print: false,
    download: false,
    pagination: false,
    viewColumns: false,
    responsive: 'scrollMaxHeight',
    selectableRows: 'none',
    onDownload: csvDownload,
    textLabels: textLabels(translate)
  };
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'name',
      label: translate('settingsPage.table.name'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender
      }
    },
    {
      name: 'city',
      label: translate('settingsPage.table.city'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender
      }
    },
    {
      name: 'address',
      label: translate('settingsPage.table.address'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender
      }
    },
    {
      name: 'id',
      label: translate('settingsPage.table.sale'),
      options: {
        sort: false,
        filter: false,
        customBodyRender: (id: number) => {
          return scheduleRender(id, BookingType.SALE);
        }
      }
    },
    {
      name: 'id',
      label: translate('settingsPage.table.warranty'),
      options: {
        sort: false,
        filter: false,
        customBodyRender: (id: number) => {
          return scheduleRender(id, BookingType.WARRANTY);
        }
      }
    }
  ];
  return (
    <Fragment>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};

export default TradePointTable;
