import { AnyAction, Reducer } from 'redux';
import { TradePointService } from '../models/TradePointService';

const LOAD = 'services/LOAD';
const LOAD_SUCCESS = 'services/LOAD_SUCCESS';
const LOAD_FAIL = 'services/LOAD_FAIL';

const LOAD_TABLE = 'services/LOAD_TABLE';
const LOAD_TABLE_SUCCESS = 'services/LOAD_TABLE_SUCCESS';
const LOAD_TABLE_FAIL = 'services/LOAD_TABLE_FAIL';

const UPDATE_SERVICE = 'services/UPDATE_SERVICE';
const UPDATE_SERVICE_SUCCESS = 'services/UPDATE_SERVICE_SUCCESS';
const UPDATE_SERVICE_FAIL = 'services/UPDATE_SERVICE_FAIL';

const GET_SERVICES = 'services/GET_SERVICES';
const GET_SERVICES_SUCCESS = 'services/GET_SERVICES_SUCCESS';
const GET_SERVICES_FAIL = 'services/GET_SERVICES_FAIL';

export interface ServicesState {
  items: TradePointService[];
  tableItems: any[];
}

export const initialState: ServicesState = {
  items: [],
  tableItems: []
};

// Reducer

const reducer: Reducer<ServicesState> = (
  state: ServicesState = initialState,
  action: AnyAction
): ServicesState => {
  switch (action.type) {
    case LOAD_SUCCESS: {
      return { ...state, items: action.result.data };
    }
    case LOAD_TABLE_SUCCESS: {
      return { ...state, tableItems: action.result.data };
    }
    case GET_SERVICES: {
      return { ...state, items: [] };
    }
    case GET_SERVICES_SUCCESS: {
      return { ...state, items: action.result.data };
    }
    default:
      return state;
  }
};

export { reducer as servicesReducer };

// Actions

export const fetchServices = () => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get('/api/services')
});

export const fetchServicesTable = () => ({
  types: [LOAD_TABLE, LOAD_TABLE_SUCCESS, LOAD_TABLE_FAIL],
  promise: (client) => client.get('/api/services/table')
});

export const updateServicePrice = (id: number, tradePointService: any) => ({
  types: [UPDATE_SERVICE, UPDATE_SERVICE_SUCCESS, UPDATE_SERVICE_FAIL],
  promise: (client) =>
    client.put('/api/services/update/' + id, tradePointService, {
      headers: { 'Content-Type': 'application/json' }
    }),
  afterSuccess: (dispatch) => {
    dispatch(fetchServicesTable());
  }
});

export const getServices = (storeId: number, deviceId: number) => ({
  types: [GET_SERVICES, GET_SERVICES_SUCCESS, GET_SERVICES_FAIL],
  promise: (client) => client.get('/api/points/' + storeId + '/models/' + deviceId + '/services')
});