import { createStyles, Grid, ListItem, ListItemIcon, Theme, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

const styles = ({ colors }: Theme) => createStyles({
  item: {
    minHeight: '48px',
    color: colors.lightGray,
    '&:hover': {
      color: colors.hover
    }
  },
  text: {
    fontSize: '14px',
    color: colors.black
  },
  icon: {
    color: 'inherit'
  },
  iconSelected: {
    color: colors.brono
  }
});

interface MainMenuItemProps extends WithStyles<typeof styles> {
  key: string;
  icon?: React.ReactElement;
  text: React.ReactNode;
  selected?: boolean;
  onClick?: (() => void);
}

class MainMenuItem extends React.Component<MainMenuItemProps> {

  render = (): React.ReactNode => {
    const { icon, text, key, selected, onClick, classes } = this.props;
    return (
      <ListItem
        key={key}
        button={true}
        selected={selected}
        onClick={onClick}
        classes={{ root: classes.item }}>
        {icon && (
          <ListItemIcon
            className={clsx(classes.icon, {
              [classes.iconSelected]: selected
            })}>
            {icon}
          </ListItemIcon>
        )}
        <Grid className={classes.text}>{text}</Grid>
      </ListItem>
    );
  };
}

export default withStyles(styles)(MainMenuItem);
