import { AnyAction, Reducer } from 'redux';
import history from '../config/history';
import { Schedule } from '../models/Schedule';
import { TradePoint } from '../models/TradePoint';

const FETCH_POINTS = 'points/FETCH_POINTS';
const FETCH_POINTS_SUCCESS = 'points/FETCH_POINTS_SUCCESS';
const FETCH_POINTS_FAIL = 'points/FETCH_POINTS_FAIL';
const CHOOSE_POINT = 'points/CHOOSE_POINT';
const CHOOSE_POINT_SUCCESS = 'points/CHOOSE_POINT_SUCCESS';
const CHOOSE_POINT_FAIL = 'points/CHOOSE_POINT_FAIL';
const NO_POINTS = 'points/NO_POINTS';

const GET_POINTS = 'points/GET_POINTS';
const GET_POINTS_SUCCESS = 'points/GET_POINTS_SUCCESS';
const GET_POINTS_FAIL = 'points/GET_POINTS_FAIL';

const CLEAR_POINT = 'points/CLEAR_POINT';

export interface PointsState {
  items: TradePoint[];
  allItems: TradePoint[];
  selectedPoint: TradePoint;
}

export const initialState: PointsState = {
  items: [],
  allItems: [],
  selectedPoint: undefined
};

// Reducer

const reducer: Reducer<PointsState> = (
  state: PointsState = initialState,
  action: AnyAction
): PointsState => {
  switch (action.type) {
    case GET_POINTS: {
      return { ...state, allItems: [] };
    }
    case GET_POINTS_SUCCESS: {
      return { ...state, allItems: action.result.data };
    }
    case GET_POINTS_FAIL: {
      return { ...state, allItems: [] };
    }
    case FETCH_POINTS: {
      return { ...state, items: [] };
    }
    case FETCH_POINTS_SUCCESS: {
      return { ...state, items: action.result.data };
    }
    case FETCH_POINTS_FAIL: {
      return { ...state, items: [] };
    }
    case CHOOSE_POINT_SUCCESS: {
      return { ...state, selectedPoint: action.result.data };
    }
    case NO_POINTS: {
      return { ...state, selectedPoint: action.select };
    }
    case CLEAR_POINT: {
      return { ...state, selectedPoint: undefined };
    }
    default: {
      return state;
    }
  }
};

export { reducer as pointsReducer };

// Actions

export function fetchTradePoints(id) {
  return {
    types: [FETCH_POINTS, FETCH_POINTS_SUCCESS, FETCH_POINTS_FAIL],
    promise: (client) => client.get('/api/org/' + id + '/points')
  };
}

export const fetchPointsList = (roles) => ({
  types: [FETCH_POINTS, FETCH_POINTS_SUCCESS, FETCH_POINTS_FAIL],
  promise: (client) => client.get('/api/users/points'),
  afterSuccess: (dispatch, getState, response) => {
    let items = response.data;
    if (items.length === 0) {
      dispatch(choosePoint(undefined, roles));
      const pathname = roles.includes('ROLE_BOOKING') ? 'booking/user' : 'category';
      history.push(pathname);
    } else if (items.length === 1) {
      dispatch(choosePoint(items[0].id, roles));
    }
  }
});

export const fetchPoints = () => ({
  types: [FETCH_POINTS, FETCH_POINTS_SUCCESS, FETCH_POINTS_FAIL],
  promise: (client) => client.get('/api/users/points')
});

export const getAllTradePoints = (organizationId?: number) => {
  const url: string = !organizationId
    ? '/api/points/all'
    : '/api/points/all/' + organizationId;
  return {
    types: [GET_POINTS, GET_POINTS_SUCCESS, GET_POINTS_FAIL],
    promise: (client) => client.get(url)
  };
};

export function choosePoint(id, roles) {
  if (id) {
    const pathname = roles.includes('ROLE_BOOKING') ? 'booking/user' : 'category';
    return {
      types: [CHOOSE_POINT, CHOOSE_POINT_SUCCESS, CHOOSE_POINT_FAIL],
      promise: (client) => client.get('/api/points/' + id),
      afterSuccess: () => {
        history.push(pathname);
      }
    };
  } else {
    return {
      type: NO_POINTS,
      select: {}
    };
  }
}

export function clearPoint() {
  return {
    type: CLEAR_POINT
  };
}

const UPDATE_SCHEDULE = 'points/UPDATE_SCHEDULE';
const UPDATE_SCHEDULE_SUCCESS = 'points/UPDATE_SCHEDULE_SUCCESS';
const UPDATE_SCHEDULE_FAIL = 'points/UPDATE_SCHEDULE_FAIL';

export const updateTradePointSchedule = (
  tradePointId: number,
  schedule: Schedule
) => ({
  types: [UPDATE_SCHEDULE, UPDATE_SCHEDULE_SUCCESS, UPDATE_SCHEDULE_FAIL],
  promise: (client) => client.put('/api/points/' + tradePointId, schedule),
  afterSuccess: (dispatch) => {
    dispatch(fetchPoints());
  }
});
