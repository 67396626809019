import { BookingType } from './BookingType';

export interface Schedule {
  [BookingType.WARRANTY]?: Week;
  [BookingType.SALE]?: Week;
}

export interface Week {
  [DayOfWeek.MONDAY]?: WorkInterval[];
  [DayOfWeek.TUESDAY]?: WorkInterval[];
  [DayOfWeek.WEDNESDAY]?: WorkInterval[];
  [DayOfWeek.THURSDAY]?: WorkInterval[];
  [DayOfWeek.FRIDAY]?: WorkInterval[];
  [DayOfWeek.SATURDAY]?: WorkInterval[];
  [DayOfWeek.SUNDAY]?: WorkInterval[];
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export interface WorkInterval {
  start: string;
  end: string;
}
