import history from '../config/history';

const FETCH_DEVICE = 'device/FETCH';
const FETCH_DEVICE_SUCCESS = 'device/FETCH_SUCCESS';
const FETCH_DEVICE_FAIL = 'device/FETCH_FAIL';
const CHOOSE_DEVICE = 'device/CHOOSE_DEVICE';
const FIND_DEVICES = 'device/FIND_DEVICES';
const FIND_DEVICES_SUCCESS = 'device/FIND_DEVICES_SUCCESS';
const FIND_DEVICES_FAIL = 'device/FIND_DEVICES_FAIL';

interface Device {
  id: number;
  name: string;
}

export interface DevicesState {
  items: Device[];
}

export const initialState: DevicesState = {
  items: []
};

// Reducer

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEVICE:
      return {
        ...state,
        items: []
      };
    case FETCH_DEVICE_SUCCESS:
      return {
        ...state,
        items: action.result.data
      };
    case FIND_DEVICES:
      return {
        ...state,
        items: []
      };
    case FIND_DEVICES_SUCCESS:
      return {
        ...state,
        items: action.result.data
      };
    case CHOOSE_DEVICE:
      return {
        ...state,
        deviceId: action.id
      };
    default:
      return state;
  }
}

// Actions

export function chooseDevice(id) {
  return {
    type: CHOOSE_DEVICE,
    id
  };
}

export function findDevices(name) {
  return {
    types: [FIND_DEVICES, FIND_DEVICES_SUCCESS, FIND_DEVICES_FAIL],
    promise: client => client.get('/api/model/withServices/like/' + name)
  };
}
