import { Button, Grid, Paper, Typography, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Translate from 'react-translate-component';
import Page from './Page';

const styles = createStyles({
  title: {
    textAlign: 'center'
  },
  button: {
    height: 136,
    width: '100%'
  }
});

interface TradePointListProps extends WithStyles<typeof styles> {
  items: any[];
  roles: string[];
  choosePoint: (id) => void;
  fetchPointsList: () => any;
}

export class TradePointList extends React.Component<TradePointListProps> {
  componentDidMount() {
    this.props.fetchPointsList();
  }

  render() {
    const { items, choosePoint, classes, roles } = this.props;

    if (items && items.length > 0 && roles.includes('ROLE_ADMIN')) {
      choosePoint(items[0].id);
    }

    return (
      <Page>
        <Grid
          container={true}
          direction={'row'}
          justify={'center'}
          alignItems={'center'}
          spacing={3}
        >
          <Grid item={true} xs={12} className={classes.title}>
            <Typography variant={'h3'}>
              <Translate content={'tradePoint.hint'} />
            </Typography>
          </Grid>
          <Grid item={true} container={true} xs={12} sm={6} spacing={3}>
            {items.map((item) => {
              return (
                <Grid item={true} xs={6} key={item.id}>
                  <Paper>
                    <Button
                      size={'large'}
                      className={classes.button}
                      onClick={() => choosePoint(item.id)}
                    >
                      {item.name}
                    </Button>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Page>
    );
  }
}

export default withStyles(styles)(TradePointList);
