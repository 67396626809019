import * as React from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import ServiceItem from './ServiceItem';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useParams } from 'react-router';
import { RootState } from '../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useEffect, useState } from 'react';
import { getServices } from '../../../../reducers/services';
import translate from 'counterpart';
import { TradePointService } from '../../../../models/TradePointService';
import { Device } from '../../../../models/Device';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scroll: {
      position: 'absolute',
      top: 'calc(48px + 48px + 40px)',
      left: 0,
      right: 0,
      overflowY: 'auto',
      bottom: '88px'
    },
    service: {
      padding: '12px 16px'
    },
    title: {
      marginBottom: '4px'
    },
    titleNoDevice: {
      marginTop: '4px',
      marginBottom: '32px',
      color: 'lightgrey'
    },
    label: {
      alignItems: 'start',
      marginLeft: '-12px',
      marginRight: '12px',
      width: '100%'
    },
    box: {
      marginTop: '-10px'
    }
  })
);

interface Props {
  onSelect?: (index: number, services: number[]) => void;
  deviceId: Device;
  storeId: number;
  services: TradePointService[];
  selectedServices: number[];
  index: number;
}

interface StateProps {
  services: TradePointService[];
}

const BookingServiceCheckbox: React.FC<Props> = (props: Props) => {
  const { deviceId, onSelect, services, selectedServices, index } = props;
  const classes = useStyles();
  const [servicesIds, setServicesIds] = useState<number[]>(selectedServices);

  const handleSelect = (serviceId: number) => (checked: boolean): void => {
    if (checked) {
      servicesIds.push(serviceId);
    } else {
      const serviceIndex: number = servicesIds.indexOf(serviceId);
      servicesIds.splice(serviceIndex, 1);
    }
    if (onSelect && deviceId) {
      onSelect(index, servicesIds);
    }
  };

  const handleChecked = (service: TradePointService) => {
      let checked = selectedServices?.find(checkedService => checkedService === service.id);
      return !!checked;
  }

  if (deviceId && deviceId?.id) {
    return (
      <Grid
        container={true}
        direction={'column'}
      >
        <Typography className={classes.title}>
          {translate('booking.services') + ':'}
        </Typography>
        {services?.map((service: TradePointService) => (
          <Grid item={true} key={service.id} className={classes.service}>
            <ServiceItem
              defaultChecked={handleChecked(service)}
              service={service}
              onChange={handleSelect(service.id)}
            />
          </Grid>
        ))}
      </Grid>
    );
  } else {
    return (
      <Grid
        container={true}
        direction={'column'}
      >
        <Typography className={classes.title}>
          {translate('booking.services') + ':'}
        </Typography>
        <Typography className={classes.titleNoDevice} color={'primary'}>
          {translate('booking.dialog.noDeviceSelected')}
        </Typography>
      </Grid>
    );
  }

};

export default BookingServiceCheckbox;