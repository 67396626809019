import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import translate from 'counterpart';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import * as React from 'react';
import { FC, Fragment, ReactNode, useState } from 'react';
import Translate from 'react-translate-component';
import { TradePointService } from '../../../models/TradePointService';
import { cellRender, csvDownload, textLabels } from '../../../utils/table-utils';
import ServiceEditDialog from './ServiceEditDialog';
import { TradePoint } from '../../../models/TradePoint';

interface Props {
  data?: any[];
  points?: TradePoint[];
  title?: string | ReactNode;
}

const ServicesTable: FC<Props> = (props: Props) => {
  const { data, title, points } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [service, setService] = useState(null);

  const handleDialogOpen = (service: TradePointService): void => {
    setService(service);
    setDialogOpen(true);
  };
  const editRender = (id: number): ReactNode => {
    const service: any = data?.find(
      (service: TradePointService) => {
        return service.id === id;
      }
    );
    if (service.services.length > 0) {
      return (
        <Button
          variant={'text'}
          color={'primary'}
          onClick={() => {
            handleDialogOpen(service);
          }}
        >
          <Translate content={'servicesPage.table.edit'} />
        </Button>
      );
    }
    return (
      <div></div>
    );
  };

  const options: MUIDataTableOptions = {
    fixedHeader: false,
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true
    },
    filter: false,
    search: false,
    print: false,
    download: false,
    pagination: false,
    viewColumns: false,
    responsive: 'scrollMaxHeight',
    selectableRows: 'none',
    onDownload: csvDownload,
    textLabels: textLabels(translate),
    expandableRows: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      if (data && data[dataIndex].services.length > 0) {
        return true;
      }
      return false;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>
                        <Translate content={'settingsPage.table.name'} />
                      </TableCell>
                      <TableCell align='left'>
                        <Translate content={'servicesPage.table.price'} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData[3].map(row => (
                      <TableRow key={row.pointName}>
                        <TableCell align='left'>{row.pointName}</TableCell>
                        <TableCell align='left'>{row.price}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>`
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'name',
      label: translate('servicesPage.table.name'),
      options: {
        sort: true,
        filter: true
      }
    },
    {
      name: 'time',
      label: translate('servicesPage.table.time'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender
      }
    },
    {
      name: 'id',
      label: translate('servicesPage.table.edit'),
      options: {
        sort: false,
        filter: false,
        customBodyRender: editRender
      }
    },
    {
      name: 'services',
      options: {
        display: 'false'
      }
    }
  ];
  return (
    <Fragment>
      <MUIDataTable
        columns={columns}
        title={title}
        data={data}
        options={options}
      />
      <ServiceEditDialog
        service={service}
        show={dialogOpen}
        handleShowEditService={() => setDialogOpen(false)}
      />
    </Fragment>
  );
};

export default ServicesTable;
