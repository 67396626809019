import { Button, createStyles, TableCell, TableRow, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, DeleteOutlined, EditOutlined, ExpandMore, SwapVertOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import translate from 'counterpart';
import moment, { Moment } from 'moment';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import React, { FC, Fragment, ReactNode, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import Translate from 'react-translate-component';
import { Booking } from '../../../models/Booking';
import { BookingService } from '../../../models/BookingService';
import { cellRender, csvDownload, textLabels } from '../../../utils/table-utils';
import BookingTableTitle from './BookingTableTitle';
import BookingCreateDialog from '../dialog/booking/BookingCreateDialog';
import BookingDeleteDialog from '../dialog/booking/BookingDeleteDialog';
import { CreateBookingDTO } from '../../../models/CreateBookingDTO';
import BookingSwapDialog from '../dialog/booking/BookingSwapDialog';
import { fetchBooking, getAvailableTimes } from '../../../reducers/booking';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import BookingEditDialog from '../dialog/booking/BookingEditDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButton: {
      textAlign: 'left',
      alignItems: 'end',
      fontWeight: 400
    },
    buttonHidden: {
      display: 'none'
    },
    addIcon: {
      marginRight: '4px'
    },
    link: {
      color: theme.colors.blue
    },
    paddingCheckbox: {
      width: '8px',
      padding: '0px !important'
    },
    table: {
      '&& .MuiIconButton-root': {
        display: 'none'
      },
      '&& .MuiTableCell-paddingCheckbox': {
        width: '8px',
        padding: '0px'
      }
    },
    moreButton: {
      textTransform: 'none',
      marginLeft: '4px'
    },
    moreIcon: {
      transition: 'transform 250ms',
      marginLeft: '4px'
    },
    moreIconExpanded: {
      transform: 'rotate(180deg)'
    }

  })
);

interface Data {
  time: string;
  booking?: Booking;
}

interface Props {
  bookings: Booking[];
  dateTime: Date;
  pointId?: number;
  onDayChange: (date: Date) => void;
  enableLinks?: boolean;
}

const BookingTable: FC<Props> = (props: Props) => {
    const { pointId, bookings, dateTime, onDayChange, enableLinks } = props;
    const classes = useStyles();
    const [rowsExpanded, setRowsExpanded] = useState<number[]>([]);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [swapDialogOpen, setSwapDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [rowDate, setDate] = useState(null);
    const [rowTime, setTime] = useState(null);
    const [phone, setPhone] = useState(null);
    const [type, setType] = useState(null);
    const [bookingPointId, setPointId] = useState(null);
    const [bookingId, setBookingId] = useState(null);
    const dispatch = useDispatch();

    const handleCreateDialogOpen = (rowDate: Date, rowTime: string, bookingPointId: number): void => {
      setCreateDialogOpen(true);
      setDate(rowDate);
      setTime(rowTime);
      setPointId(bookingPointId);
    };
    const handleDeleteDialogOpen = (rowDate: Date, booking: Booking, bookingPointId: number): void => {
      setDeleteDialogOpen(true);
      setTime(booking.startTime);
      setDate(rowDate);
      setBookingId(booking.id);
      setPhone(booking.customerPhone);
      setType(booking.type);
      setPointId(bookingPointId);
    };
    const handleSwapDialogOpen = (rowDate: Date, booking: Booking, bookingPointId: number): void => {
      dispatch(getAvailableTimes(new Date(), bookingPointId, booking.type));
      setSwapDialogOpen(true);
      setTime(booking.startTime);
      setDate(rowDate);
      setBookingId(booking.id);
      setPhone(booking.customerPhone);
      setType(booking.type);
      setPointId(bookingPointId);
    };
    const handleEditDialogOpen = async (bookingPointId: number, rowDate: Date, rowTime: string, bookingId: number, type: string) => {
      dispatch(fetchBooking(bookingId));
      setPointId(bookingPointId);
      setDate(rowDate);
      setTime(rowTime);
      setType(type)
      setBookingId(bookingId);
      await timeout(1000);
      setEditDialogOpen(true);
    };

    function timeout(delay: number) {
      return new Promise(res => setTimeout(res, delay));
    }

    const handleDayChange = (date: Date): void => {
      setRowsExpanded([]);
      onDayChange(date);
    };
    const customRender = (
      value: any,
      tableMeta: MUIDataTableMeta
    ): string | ReactNode => {
      return tableMeta.rowData[1] ? cellRender(value) : value;
    };
    const customerRender = (
      value: any,
      tableMeta: MUIDataTableMeta
    ): string | ReactNode => {
      if (tableMeta.rowData[1]) {
        return cellRender(value);
      } else {
        return enableLinks ? (
          <Button
            variant={'text'}
            color={'primary'}
            className={classes.addButton}
            onClick={() => {
              handleCreateDialogOpen(dateTime, tableMeta.rowData[0], pointId);
            }}
          >
            <Add className={classes.addIcon} />
            <Translate content={'userBooking.addBooking'} />
          </Button>
        ) : (
          <Button
            variant={'text'}
            color={'primary'}
            className={classes.buttonHidden}
          />
        );
      }
    };
    const modelRender = (
      bookingId: number,
      tableMeta: MUIDataTableMeta
    ): string | ReactNode => {
      const booking: Booking = bookings?.find((booking: Booking) => {
        return booking.id === bookingId;
      });
      if (booking?.services?.length > 0) {
        const service: BookingService = booking?.services[0];
        const brandId: number = service?.model?.brandId;
        const modelId: number = service?.model?.id;
        const categoryId: number = service?.model?.category?.id;
        return enableLinks ? (
          <Link
            className={classes.link}
            to={`/category/${categoryId}/brand/${brandId}/model/${modelId}/templates`}
          >
            {customRender(service?.model?.name, tableMeta)}
          </Link>
        ) : (
          customRender(service?.model?.name, tableMeta)
        );
      } else if (booking?.warranty) {
        const brandId: number = booking?.warranty?.brandId;
        const modelId: number = booking?.warranty?.modelId;
        const brandName: string = booking?.warranty?.brandName;
        const modelName: string = booking?.warranty?.modelName;
        const warrantyId: number = booking?.warranty?.id;
        const categoryId: number = booking?.warranty?.categoryId;
        const resType: string = booking?.warranty?.resType;
        return enableLinks ? (
          <Link
            className={classes.link}
            to={`/category/${categoryId}/brand/${brandId}/model/${modelId}/templates?warrantyId=${warrantyId}&resType=${resType}`}
          >
            {customRender(brandName + ' ' + modelName, tableMeta)}
          </Link>
        ) : (
          customRender(brandName + ' ' + modelName, tableMeta)
        );
      }
      return customRender(null, tableMeta);
    };
    const serviceRender = (
      bookingId: number,
      tableMeta: MUIDataTableMeta
    ): string | ReactNode => {
      const booking: Booking = bookings?.find((booking: Booking) => {
        return booking.id === bookingId;
      });
      if (booking?.services?.length > 0) {
        const service: BookingService = booking?.services[0];
        return customRender(service?.service?.name, tableMeta);
      } else if (booking?.warranty) {
        return customRender(translate('userBooking.warranty'), tableMeta);
      }
      return customRender(null, tableMeta);
    };
    const buttonsRender = (
      services: BookingService[],
      tableMeta: MUIDataTableMeta
    ): string | ReactNode => {
      const booking: Booking = bookings?.find((booking: Booking) => {
        return booking.id === tableMeta.rowData[1];
      })
      if (tableMeta.rowData[1]) {
        return enableLinks ? (
          <span>
            <Tooltip title={translate('booking.dialog.swap')}>
              <Button
                color={'primary'}
                variant={'text'}
                className={classes.moreButton}
                onClick={() => {
                  handleSwapDialogOpen(dateTime, booking, pointId);
                }}
              >
                <SwapVertOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={translate('booking.dialog.edit')}>
              <Button
                color={'primary'}
                variant={'text'}
                className={classes.moreButton}
                onClick={() => {
                  handleEditDialogOpen(pointId, dateTime, tableMeta.rowData[0], tableMeta.rowData[1], booking?.type);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={translate('booking.dialog.delete')}>
              <Button
                color={'primary'}
                variant={'text'}
                className={classes.moreButton}
                onClick={() => {
                  handleDeleteDialogOpen(dateTime, booking, pointId);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
        </span>
        ) : (
          <Button
            variant={'text'}
            color={'primary'}
            className={classes.buttonHidden}
          />
        );
      }
      return null;
    };
    const moreRender = (
      services: BookingService[],
      tableMeta: MUIDataTableMeta
    ): string | ReactNode => {
      const expanded: boolean = rowsExpanded.includes(tableMeta.rowIndex);
      if (services?.length > 1) {
        return (
          <Button
            color={'primary'}
            variant={'text'}
            className={classes.moreButton}
          >
            {`${translate('userBooking.more')} +${services?.length - 1}`}
            <ExpandMore
              className={clsx(classes.moreIcon, {
                [classes.moreIconExpanded]: expanded
              })}
            />
          </Button>
        );
      }
      return null;
    };

    const renderExpandableRow = (rowData: string[]): ReactNode => {
      const bookingId: number = Number(rowData[1]);
      if (bookingId) {
        const services: BookingService[] =
          JSON.parse(JSON.stringify(rowData[5])) || [];
        if (services.length > 1) {
          const rows: ReactNode[] = [];
          for (let i = 1; i < services.length; i++) {
            const service: BookingService = services[i];
            const categoryId: number = service?.model?.category?.id;
            const brandId: number = service?.model?.brandId;
            const modelId: number = service?.model?.id;
            rows.push(
              <TableRow key={service?.id}>
                <TableCell className={clsx(classes.paddingCheckbox)} />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  {enableLinks ? (
                    <Link
                      className={classes.link}
                      to={`/category/${categoryId}/brand/${brandId}/model/${modelId}/templates`}
                    >
                      {cellRender(service?.model?.name)}
                    </Link>
                  ) : (
                    cellRender(service?.model?.name)
                  )}
                </TableCell>
                <TableCell>{cellRender(service?.service?.name)}</TableCell>
                <TableCell />
              </TableRow>
            );
          }
          return <Fragment>{rows}</Fragment>;
        }
      }
    };
    const handleRowsExpand = (
      currentRowsExpanded: { index: number; dataIndex: number }[],
      allRowsExpanded: { index: number; dataIndex: number }[]
    ): void => {
      setRowsExpanded(
        allRowsExpanded.map((row: { index: number; dataIndex: number }) => {
          return row.index;
        })
      );
    };
    const generateData = (bookings: Booking[]): Data[] => {
      const tableData: Data[] = [];
      let start: Moment = moment(dateTime).hour(10).minute(0).second(0);
      const end: Moment = moment(dateTime).hour(22).minute(0).second(0);
      while (start < end) {
        const time: string = start.format('HH:mm');
        bookings
          ?.filter((booking: Booking) => {
            return booking.startTime === time;
          })
          ?.forEach((booking: Booking) => {
            tableData.push({ time: time, booking: booking });
          });
        const data: Data = tableData?.find((data: Data) => {
          return data.time === time;
        });
        if (!data) {
          tableData.push({ time: time });
        }
        start.add(30, 'minutes');
      }
      return tableData;
    };
    const data: Data[] = generateData(bookings);
    const options: MUIDataTableOptions = {
      fixedHeader: false,
      fixedHeaderOptions: {
        xAxis: false,
        yAxis: true
      },
      filter: false,
      search: false,
      print: false,
      download: false,
      pagination: false,
      viewColumns: false,
      responsive: 'scrollMaxHeight',
      selectableRows: 'none',
      expandableRows: true,
      expandableRowsOnClick: true,
      renderExpandableRow: renderExpandableRow,
      rowsExpanded: rowsExpanded,
      onRowsExpand: handleRowsExpand,
      onDownload: csvDownload,
      textLabels: textLabels(translate)
    };
    const columns: MUIDataTableColumnDef[] = [
      {
        name: 'time',
        label: translate('userBooking.time'),
        options: {
          sort: true,
          filter: true
        }
      },
      {
        name: 'booking.id',
        options: {
          display: 'false'
        }
      },
      {
        name: 'booking.customerName',
        label: translate('userBooking.customerName'),
        options: {
          sort: true,
          filter: true,
          customBodyRender: customerRender
        }
      },
      {
        name: 'booking.customerPhone',
        label: translate('userBooking.customerPhone'),
        options: {
          sort: true,
          filter: true,
          customBodyRender: customRender
        }
      },
      {
        name: 'booking.customerEmail',
        label: translate('userBooking.customerEmail'),
        options: {
          sort: true,
          filter: true,
          customBodyRender: customRender
        }
      },
      {
        name: 'booking.services',
        options: {
          display: 'false'
        }
      },
      {
        name: 'booking.id',
        label: translate('userBooking.model'),
        options: {
          sort: true,
          filter: true,
          customBodyRender: modelRender
        }
      },
      {
        name: 'booking.id',
        label: translate('userBooking.service'),
        options: {
          sort: false,
          filter: false,
          customBodyRender: serviceRender
        }
      },
      {
        name: 'booking.services',
        label: ' ',
        options: {
          sort: false,
          filter: false,
          customBodyRender: moreRender
        }
      },
      {
        name: 'booking.services',
        label: ' ',
        options: {
          sort: false,
          filter: false,
          customBodyRender: buttonsRender
        }
      }
    ];
    return (
      <Fragment>
        <MUIDataTable
          data={data}
          title={
            <BookingTableTitle dateTime={dateTime} onChange={handleDayChange} />
          }
          columns={columns}
          options={options}
          // @ts-ignore
          className={classes.table}
        />
        <BookingCreateDialog
          show={createDialogOpen}
          handleShowCreateBooking={() => setCreateDialogOpen(false)}
          rowDate={rowDate}
          rowTime={rowTime}
          pointId={bookingPointId}
        />
        <BookingDeleteDialog
          show={deleteDialogOpen}
          handleShowDeleteBooking={() => setDeleteDialogOpen(false)}
          rowTime={rowTime}
          rowDate={rowDate}
          bookingId={bookingId}
          phone={phone}
          type={type}
          pointId={pointId}
        />
        <BookingSwapDialog
          show={swapDialogOpen}
          handleShowSwapBooking={() => setSwapDialogOpen(false)}
          rowTime={rowTime}
          rowDate={rowDate}
          bookingId={bookingId}
          phone={phone}
          type={type}
          pointId={pointId}
        />
        <BookingEditDialog
          show={editDialogOpen}
          handleShowCreateBooking={() => setEditDialogOpen(false)}
          rowDate={rowDate}
          rowTime={rowTime}
          pointId={bookingPointId}
          bookingId={bookingId}
          type={type}
        />
      </Fragment>
    );
  }
;

export default BookingTable;
