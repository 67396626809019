import { createStyles, Divider, Grid, Theme, Toolbar, withStyles, WithStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';

const styles = (theme: Theme) => createStyles({
  page: {
    height: 'calc(100% - 48px)'
  },
  appBar: {
    boxShadow: '0px 2px 8px rgba(138, 160, 181, 0.25) !important'
  },
  toolbar: {
    minHeight: '62px !important'
  },
  pageContent: {
    flexWrap: 'nowrap',
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 1)
    }
  },
  errorBar: {
    alignSelf: 'start',
    marginBottom: theme.spacing(3)
  }
});

interface PageProps extends WithStyles<typeof styles> {
  actions?: React.ReactNode;
  error?: (string | string[]);
}

export const Page: React.FunctionComponent<PageProps> = (props: PageProps & { children: React.ReactElement; }) => {
  const { classes, actions, children, error } = props;
  let errorBar;
  if (error && typeof error === 'string') {
    errorBar = (
      <div className={classes.errorBar}>
        <Alert severity={'error'}>{error}</Alert>
      </div>
    );
  } else if (error && Array.isArray(error)) {
    errorBar = error.map((item: string, index: number) => (
      <div className={classes.errorBar} key={index}>
        <Alert severity={'error'}>{item}</Alert>
      </div>
    ));
  }
  return (
    <Grid
      container={true}
      direction={'column'}
      className={classes.page}>
      {actions && (
        <Grid item={true}>
          <AppBar
            position={'relative'}
            className={classes.appBar}>
            <Divider />
            <Toolbar className={classes.toolbar}>
              {actions}
            </Toolbar>
          </AppBar>
        </Grid>
      )}
      <Grid
        item={true} xs={true}
        style={{ position: 'relative' }}>
        <Grid
          container={true}
          direction={'column'}
          className={classes.pageContent}>
          <Grid
            item={true}
            style={{ display: 'flex' }}>
            {errorBar}
          </Grid>
          <Grid
            item={true} xs={true}
            style={{ display: 'flex', maxHeight: '100%' }}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Page);
