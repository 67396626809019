import { Badge, Button, ButtonGroup, Chip, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { FC } from 'react';
import { generateWeek, getDayLabel } from '../../../utils/date-utils';
import { loadCutsNumber } from '../../../reducers/balances';
import {
  countNotificationsByPoint,
  fetchNotificationsByPoint,
  markNotificationsAsRead
} from '../../../reducers/notifications';
import { connect } from 'react-redux';

const useStyles = makeStyles(() =>
  createStyles({
    group: {
      width: '100%'
    },
    button: {
      textTransform: 'none',
      flex: 1
    },
    outlined: {
      padding: '5px 7px'
    },
    contained: {
      padding: '6px 8px'
    },
    chipLabel: {
      padding: 0,
      fontSize: '12px',
      lineHeight: '16px'
    },
    chip: {
      height: 'auto',
      padding: '2px 8px'
    },
    chipContainer: {
      marginLeft: '5px'
    }
  })
);

interface Props {
  dateTime: Date;
  onChange: (date: Date) => void;
  notifications: any;
  point: any;
  markAsRead: ((pointId, date, dateFrom, dateTo) => any);
  roles: string[];
}

const WeekRow: FC<Props> = (props: Props) => {
  const { dateTime, onChange, notifications, point, markAsRead, roles } = props;
  const classes = useStyles();
  const dates: Date[] = generateWeek();
  const handleClick = (date: Date, notificationsCount: number) => (): void => {
    onChange(date);
    if (notificationsCount > 0 && roles.includes('ROLE_USER')) {
      markAsRead(point.id, date, dates[0], dates[6]);
    }
  };
  return (
    <ButtonGroup
      color={'primary'}
      aria-label={'outlined primary button group'}
      className={classes.group}
    >
      {dates.map((date: Date) => {
        const selected: boolean = moment(dateTime).isSame(date, 'day');
        const isToday = moment(date).isSame(new Date(), 'day');
        let notificationsCount = 0;
        const content: string =
          getDayLabel(date.getDay()) + ', ' + date.getDate();
        notifications.map((notification: any) => {
          const isDay = moment(notification.date).isSame(date, 'day');
          if(isDay) {
            notificationsCount = notification.count;
          }
        })
        return (
          <Button
            key={content}
            variant={selected ? 'contained' : 'outlined'}
            className={classes.button}
            classes={{
              outlined: classes.outlined,
              contained: classes.contained
            }}
            onClick={handleClick(date, notificationsCount)}
          >
            <Grid item={true}>
              {content}
            </Grid>
            {
              notificationsCount > 0 ?
                <Grid item={true} className={classes.chipContainer}>
                  {isToday ?
                    <Chip
                      color={'secondary'}
                      label={notificationsCount}
                      classes={{ label: classes.chipLabel }}
                      className={classes.chip}/> :
                    <Chip
                      color={'primary'}
                      label={notificationsCount}
                      classes={{ label: classes.chipLabel }}
                      className={classes.chip}/>
                  }
                </Grid> : <div></div>
            }
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notifications.items,
  point: state.points.selectedPoint,
  roles: state.authentication.roles
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotificationsByPoint: (pointId, dateFrom, dateTo) => {
      dispatch(fetchNotificationsByPoint(pointId, dateFrom, dateTo))
    },
    markAsRead: (pointId, date, dateFrom, dateTo) => {
      dispatch(markNotificationsAsRead(pointId, date, dateFrom, dateTo));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WeekRow);
