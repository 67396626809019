import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

const useStyles = makeStyles({
  button: {
    width: '100%'
  }
});

interface Props {
  times: string[];
  onChange?: (time: string) => void;
}

const TimePicker: React.FC<Props> = (props: Props) => {
  const { times, onChange } = props;
  const classes = useStyles();
  const handleClick = (time: string) => (): void => {
    if (onChange) {
      onChange(time);
    }
  };
  return (
    <Grid
      container={true}
      direction={'row'}
      justify={'space-between'}
      spacing={1}
    >
      {times?.map((time: string) => (
        <Grid item={true} key={time} xs={3}>
          <Button
            variant={'contained'}
            className={classes.button}
            onClick={handleClick(time)}
          >
            {time}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default TimePicker;
