import { ReactNode } from 'react';
import { StringUtils } from './StringUtils';

export const csvDownload = (buildHead, buildBody, columns, data) => {
  return '\uFEFF' + buildHead(columns) + buildBody(data);
};

export const textLabels = (translate: (text: string) => string) => ({
  body: {
    noMatch: translate('textLabels.body.noMatch'),
    toolTip: translate('textLabels.body.toolTip')
  },
  pagination: {
    next: translate('textLabels.pagination.next'),
    previous: translate('textLabels.pagination.previous'),
    rowsPerPage: translate('textLabels.pagination.rowsPerPage'),
    displayRows: translate('textLabels.pagination.displayRows')
  },
  toolbar: {
    search: translate('textLabels.toolbar.search'),
    downloadCsv: translate('textLabels.toolbar.downloadCsv'),
    print: translate('textLabels.toolbar.print'),
    viewColumns: translate('textLabels.toolbar.viewColumns'),
    filterTable: translate('textLabels.toolbar.filterTable')
  },
  filter: {
    all: translate('textLabels.filter.all'),
    title: translate('textLabels.filter.title'),
    reset: translate('textLabels.filter.reset')
  },
  viewColumns: {
    title: translate('textLabels.viewColumns.title'),
    titleAria: translate('textLabels.viewColumns.titleAria')
  },
  selectedRows: {
    text: translate('textLabels.selectedRows.text'),
    delete: translate('textLabels.selectedRows.delete'),
    deleteAria: translate('textLabels.selectedRows.deleteAria')
  }
});

export const cellRender = (value: any): string | ReactNode => {
  const noValue: boolean =
    value === null || value === undefined || StringUtils.isBlank(String(value));
  return noValue ? '—' : value;
};
