import * as React from 'react';
import { connect } from 'react-redux';
import Page from '../Page';
import { createStyles, Grid, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import Translate from 'react-translate-component';
import InstructionsItem from './InstructionsItem';
import { fetchInstructions } from '../../../reducers/instructions';

const styles = (theme: Theme) => createStyles({
  titleContainer: {
    display: 'table',
    borderSpacing: theme.spacing(1)
  },
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px'
    },
    whiteSpace: 'nowrap',
  }
});

interface InstructionsProps extends WithStyles<typeof styles> {
  items: {
    id: number;
    link: string;
    description: string;
  }[],
  fetchInstructions: (() => any);
}


export class Instructions extends React.Component<InstructionsProps> {

  componentDidMount = (): void => {
    const { fetchInstructions } = this.props;
    fetchInstructions();
  };

  renderActionsBar = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Grid
        container={true}
        direction={'column'}
        spacing={2}
        style={{ margin: '0px -8px' }}>
        <Grid item={true}>
          <Grid
            container={true}
            direction={'row'}
            justify={'space-between'}
            alignItems={'center'}
            spacing={2}
            style={{ flexWrap: 'nowrap' }}>
            <Grid item={true}>
              <Grid
                container={true}
                direction={'row'}
                alignItems={'center'}
                spacing={1}
                style={{ flexWrap: 'nowrap' }}>
                <Grid item={true}>
                  <Typography className={classes.title}>
                    <Translate content={'instructionsPage.title.instructions'} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render = (): React.ReactNode => {
    const { items } = this.props;
    return (
      <Page actions={this.renderActionsBar()}>
        <Grid
          container={true}
          direction={'row'}
          spacing={3}
          style={{ height: 'min-content' }}>
          {items.map((item) => (
            <Grid
              item={true} key={item.id}
              xs={12} sm={6} md={4} lg={3} xl={2}>
              <InstructionsItem
                item = {item}
              />
            </Grid>
          ))}
        </Grid>
      </Page>
    );
  };
}

const mapStateToProps = (state) => ({
  items: state.instructions.items
});

const mapDispatchToProps = {
  fetchInstructions
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Instructions));