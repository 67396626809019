import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { addDays, format, isSameDay } from 'date-fns';
import { ru } from 'date-fns/locale';
import * as React from 'react';
import { useState } from 'react';
import DayButton from './DayButton';
import { generateWeek, getDayLabel, parseDateTime } from '../../../utils/date-utils';

const useStyles = makeStyles({
  prefix: {
    textTransform: 'capitalize'
  },
  label: {
    fontSize: '18px',
    lineHeight: '24px',
    paddingBottom: '12px'
  }
});

interface Props {
  dateTime?: Date;
  onChange?: (date: Date) => void;
}

const DayPicker: React.FC<Props> = (props: Props) => {
  const { dateTime, onChange } = props;
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState<Date>(
    parseDateTime(dateTime || new Date())
  );
  const dates: Date[] = generateWeek();
  const getPrefix = (date: Date): string => {
    if (isSameDay(date, new Date())) {
      return 'сегодня';
    } else if (isSameDay(date, addDays(new Date(), 1))) {
      return 'завтра';
    } else {
      return format(date, 'EEEE', { locale: ru });
    }
  };
  const getLabel = (date: Date): string => {
    return format(date, 'd MMMM', { locale: ru });
  };
  const handleClick = (date: Date) => (): void => {
    setSelectedDate(date);
    if (onChange) {
      onChange(date);
    }
  };
  return (
    <Grid container={true} direction={'column'} spacing={3}>
      <Grid item={true}>
        <Grid
          container={true}
          direction={'row'}
          spacing={1}
          justify={'space-between'}
        >
          {dates?.map((date: Date) => (
            <Grid item={true} key={date.getDay()}>
              <DayButton
                content={getDayLabel(date.getDay())}
                selected={isSameDay(selectedDate, date)}
                onClick={handleClick(date)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item={true}>
        <Typography className={classes.label} align={'center'}>
          <span className={classes.prefix}>{getPrefix(selectedDate)}</span>
          <span>{', '}</span>
          <span>{getLabel(selectedDate)}</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DayPicker;
