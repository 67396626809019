import { Button, Grid, Hidden, Theme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import translate from 'counterpart';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import { login } from '../../reducers/authentication';
import FilledTextField from './field/FilledTextField';

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0
    }
  },
  logoContainer: {
    alignSelf: 'center',
    marginTop: 54,
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      marginTop: 86
    }
  },
  logo: {
    height: '18.5px',
    width: '130px'
  },
  login: {
    maxWidth: '376px',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      maxWidth: '100%'
    }
  },
  error: {
    borderRadius: 'unset',
    boxShadow: 'none !important',
    padding: '6px 24px'
  },
  support: {
    padding: '8px 24px',
    borderTop: '1px solid ' + theme.colors.gray100,
    lineHeight: '24px'
  },
  phone: {
    textDecoration: 'none',
    color: theme.colors.blue,
    marginLeft: '4px'
  },
  rootContainer: {
    height: '100%'
  }
});

interface LoginFormValues {
  username: string;
  password: string;
}

interface LoginFormProps extends WithStyles<typeof styles> {
  errorMessage?: string;
  login: ((username, password) => void);
}

export const LoginForm: React.FunctionComponent<LoginFormProps> = (props: LoginFormProps) => {
  const { errorMessage, classes, login } = props;
  const initialValues: LoginFormValues = {
    username: '',
    password: ''
  };
  const handleSubmit = (e) => {
    login(e.username, e.password);
  };

  return (
    <Grid className={classes.container}>
      <Grid container={true} className={classes.login}>
        <Paper className={classes.paper}>
          <Grid
            className={classes.rootContainer}
            container={true}
            justify={'space-around'}
            direction={'column'}>
            <Grid
              item
              container
              justify={'space-around'}
              direction={'column'}>
              <Grid item={true} className={classes.logoContainer}>
                <img src={'/images/logo.png'} alt={'logo'} className={classes.logo} />
              </Grid>
              <Grid item={true} style={{ margin: '0px 24px' }}>
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={initialValues}>
                  {(props: FormikProps<LoginFormValues>) => (
                    <Form>
                      <Grid
                        container={true}
                        direction={'column'}
                        spacing={2}>
                        <Grid item={true}>
                          <FilledTextField
                            name={'username'}
                            variant={'filled'}
                            label={translate('login.label.login')}
                            onChange={props.handleChange} />
                        </Grid>
                        <Grid item={true}>
                          <FilledTextField
                            name={'password'}
                            type={'password'}
                            variant={'filled'}
                            label={translate('login.label.password')}
                            onChange={props.handleChange} />
                        </Grid>
                        <Grid item={true} style={{ paddingTop: '16px' }}>
                          <Button
                            fullWidth={true}
                            color={'primary'}
                            variant={'contained'}
                            type={'submit'}
                            size={'large'}>
                            <Translate content={'login.button.login'} />
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
              <Grid item={true} style={{ padding: '16px 0px', minHeight: '48px' }}>
                {errorMessage && (
                  <Alert severity={'error'} className={classes.error}>
                    <Translate content={errorMessage} />
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={classes.support}>
              <Translate content={'support.support'} />
              <Hidden smUp={true}>
                <a className={classes.phone} href={'tel:+7-919-390-21-16'}>
                  <Translate content={'support.phone'} />
                </a>
              </Hidden>
              <Hidden xsDown={true}>
                <Translate className={classes.phone} content={'support.phone'} />
              </Hidden>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  errorMessage: state.authentication.errorMessage,
  currentLocale: state.locale.currentLocale
});

const mapDispatchToProps = {
  login
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginForm));
