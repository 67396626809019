import MomentUtils from '@date-io/moment';

export class LocalizedUtils extends MomentUtils {

  getWeekdays(): string[] {
    if (this.locale === 'ru') {
      return ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
    }
    return super.getWeekdays();
  }
}
