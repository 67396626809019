import { Button, createStyles, Grid, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import Translate from 'react-translate-component';
import UseWarrantyConfirmModalWindow from './dialog/warranty/UseWarrantyConfirmModalWindow';
import { useState } from 'react';

const styles = (theme: Theme) => createStyles({
  header: {
    fontSize: '24px',
    lineHeight: '32px'
  },
  dashed: {
    borderRadius: 4,
    background: 'rgba(142, 142, 142, 0.1)',
    fontSize: 16,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5)
  },
  listText: {
    fontSize: 14
  },
  printButton: {
    width: '100%'
  },
  confirmButton: {
    width: '100%',
    marginBottom: '4px'
  }
});

interface InfoBlockProps extends WithStyles<typeof styles> {
  template: any;
  sendTemplateDisabled: boolean;
  onSendTemplate: ((e) => void);
  openConfirmModal: () => void;
  warranty: any;
  confirmWarranty: (warrantyCodeDTO) => void;
  confirmed: boolean;
  setConfirmed: () => void;
}

const InfoBlock: React.FunctionComponent<InfoBlockProps> = (props: InfoBlockProps) => {
  const { template, sendTemplateDisabled, onSendTemplate, classes, warranty, confirmWarranty, confirmed, setConfirmed, openConfirmModal } = props;
  const type = template ? template.listTypeName : null;
  const orientation = template ? template.listOrientationName : null;
  const [modalShow, setModalShow] = useState(false);
  return (
    <Grid
      container={true}
      direction={'column'}
      spacing={3}>
      <Grid
        item={true}
        container={true}
        spacing={2}
        direction={'column'}
        justify={'center'}
        alignItems={'stretch'}>
        <Grid
          item={true}
          container={true}
          justify={'space-between'}
          alignItems={'center'}
          spacing={3}>
          <Grid item={true}>
            <span className={classes.listText}>1. </span>
            <Translate className={classes.listText} content={'side.info.1'} />
          </Grid>
          <Grid item={true}>
            <span className={classes.dashed}>
              {type}
            </span>
          </Grid>
        </Grid>
        <Grid
          item={true}
          container={true}
          justify={'space-between'}
          alignItems={'center'}
          spacing={3}>
          <Grid item={true}>
            <span className={classes.listText}>2. </span>
            <Translate className={classes.listText} content={'side.info.2'} />
          </Grid>
          <Grid item={true}>
            <span className={classes.dashed}>
              {orientation}
            </span>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          { warranty ?
            <Button
              disabled={true}
              onClick={() => setModalShow(true)}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              className={classes.confirmButton}>
              <Translate content={'warranty.confirm'} />
            </Button> : <div></div>
          }
          <Button
              onClick={openConfirmModal}
              disabled={sendTemplateDisabled}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              className={classes.printButton}>
            <Translate content={'side.label.print'} />
          </Button>
        </Grid>
      </Grid>
      <UseWarrantyConfirmModalWindow
        warranty={warranty}
        open={modalShow}
        close={() => setModalShow(false)}
        confirmWarranty={confirmWarranty}
        setConfirmed={setConfirmed}
      />
    </Grid>
  );
};

export default withStyles(styles)(InfoBlock);
