const FETCH_BRAND = 'brand/FETCH';
const FETCH_BRAND_SUCCESS = 'brand/FETCH_SUCCESS';
const FETCH_BRAND_FAIL = 'brand/FETCH_FAIL';

const FETCH_CATEGORY_BRAND = 'brand/FETCH_CATEGORY_BRAND';
const FETCH_CATEGORY_BRAND_SUCCESS = 'brand/FETCH_CATEGORY_BRAND_SUCCESS';
const FETCH_CATEGORY_BRAND_FAIL = 'brand/FETCH_CATEGORY_BRAND_FAIL';

const CHOOSE_BRAND = 'brand/CHOOSE_BRAND';

export const initialState = {
  items: [],
  categoryItems: []
};

// Reducer

export default function brandReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRAND:
      return {
        ...state,
        items: []
      };
    case FETCH_BRAND_SUCCESS:
      return {
        ...state,
        items: action.result.data
      };
    case CHOOSE_BRAND:
      return {
        ...state,
        brandId: action.id
      };
    case FETCH_CATEGORY_BRAND: {
      return {
        ...state,
        categoryItems: []
      }
    }
    case FETCH_CATEGORY_BRAND_SUCCESS: {
      return {
        ...state,
        categoryItems: action.result.data
      }
    }
    case FETCH_CATEGORY_BRAND_FAIL: {
      return {
        ...state,
        categoryItems: []
      }
    }
    default:
      return state;
  }
}

// Actions

export function fetchBrand() {
  return {
    types: [FETCH_BRAND, FETCH_BRAND_SUCCESS, FETCH_BRAND_FAIL],
    promise: client => client.get('/api/brand/list')
  };
}

export function fetchCategoryBrand(categoryId) {
  return {
    types: [FETCH_CATEGORY_BRAND, FETCH_CATEGORY_BRAND_SUCCESS, FETCH_CATEGORY_BRAND_FAIL],
    promise: client => client.get(`/api/brand/list?categoryId=${categoryId}`)
  }
}

export function chooseBrand(id) {
  return {
    type: CHOOSE_BRAND,
    id
  };
}

