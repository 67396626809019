import translate from 'counterpart';
import moment from 'moment';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableColumnDef,
  MUIDataTableMeta,
  MUIDataTableOptions
} from 'mui-datatables';
import * as React from 'react';
import {
  cellRender,
  csvDownload,
  textLabels
} from '../../../utils/table-utils';
import { mobileTranslate } from '../MobileTranslate';
import TablePaginationFooter from '../TablePaginationFooter';

const dateCellRender = (
  value: any,
  locale: string
): string | React.ReactNode => {
  const dateString: string = value
    ? moment(value).toDate().toLocaleString(locale, {})
    : '—';
  if (dateString === 'Invalid Date') {
    console.error('Invalid Date', value);
  }
  return <div>{dateString}</div>;
};

const typeCellRender = (
    value: any,
    tableMeta: MUIDataTableMeta
): string | React.ReactNode => (
    <div>
      {tableMeta.rowData[6] ?
          tableMeta.rowData[6] :
          value
              ? translate('side.template.' + value, { fallback: value }).toUpperCase()
              : null}
    </div>
);

interface ReportTableProps {
  data?: any[];
  currentLocale: string;
  title?: string | React.ReactNode;
  roles: string[];
}

export const ReportTable: React.FunctionComponent<ReportTableProps> = (
  props: ReportTableProps
) => {
  const { data, title, currentLocale, roles } = props;
  const options: MUIDataTableOptions = {
    fixedHeader: false,
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true
    },
    filter: false,
    search: false,
    print: false,
    viewColumns: false,
    responsive: 'scrollMaxHeight',
    selectableRows: 'none',
    onDownload: (
      buildHead: (columns: any) => string,
      buildBody: (data: any) => string,
      columns: MUIDataTableColumn[],
      data: { index: number; data: any[] }[]
    ): string | boolean => {
      const resTypeColumnIndex: number = columns.findIndex(
        (column: MUIDataTableColumn) => {
          return column.name === 'resType';
        }
      );
      const dateCreatedColumnIndex: number = columns.findIndex(
        (column: MUIDataTableColumn) => {
          return column.name === 'dateCreated';
        }
      );
      const mappedData: any[] = data?.map(
        (value: { index: number; data: any[] }) => {
          const resType: number = value?.data[resTypeColumnIndex];
          value.data[resTypeColumnIndex] = resType
            ? translate('side.template.' + resType, {
                fallback: resType
              }).toUpperCase()
            : resType;

          const dateCreated: number = value?.data[dateCreatedColumnIndex];
          value.data[dateCreatedColumnIndex] = dateCreated
            ? moment(dateCreated).toDate().toLocaleString(currentLocale, {})
            : '—';

          return value;
        }
      );
      return csvDownload(buildHead, buildBody, columns, mappedData);
    },
    textLabels: textLabels(translate),
    pagination: true,
    customFooter: (
      rowCount: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (rowsPerPage: number) => void,
      changePage: any
    ) => (
      <TablePaginationFooter
        page={page}
        rowCount={rowCount}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
      />
    )
  };
  let columns: MUIDataTableColumnDef[] = [
    {
      name: 'dateCreated',
      label: translate('report.label.colDateCreated'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value: any) => dateCellRender(value, currentLocale)
      }
    },
    {
      name: 'pointName',
      label: mobileTranslate('report.label.colPoint', 'report.label.ps'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender
      }
    },
    {
      name: 'userName',
      label: mobileTranslate('report.label.colUser', 'report.label.name'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender
      }
    },
    {
      name: 'model',
      label: translate('report.label.model'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender,
        setCellHeaderProps: () => ({
          style: {
            minWidth: '144px'
          }
        })
      }
    },
    {
      name: 'resType',
      label: translate('report.label.colResType'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: typeCellRender
      }
    },
    {
      name: 'saleCount',
      label: translate('report.label.colSales'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: cellRender
      }
    },
    {
      name: 'templateCustomName',
      options: {
        display: 'false'
      }
    }
  ];

  if (roles.includes('ROLE_WARRANTY')) {
    columns.push(
      {
        name: 'replacementCount',
        label: translate('report.label.colReplacementCount'),
        options: {
          sort: true,
          filter: true,
          customBodyRender: cellRender
        }
      },
      {
        name: 'replacementNumber',
        label: mobileTranslate(
          'report.label.colReplacementNumber',
          'report.label.colReplacementDate'
        ),
        options: {
          sort: true,
          filter: true,
          customBodyRender: cellRender
        }
      },
      {
        name: 'warrantyPointName',
        label: mobileTranslate(
          'report.label.tradePointCreated',
          'report.label.registrationWarranty'
        ),
        options: {
          sort: true,
          filter: true,
          customBodyRender: cellRender
        }
      }
    );
  }

  return (
    <MUIDataTable
      columns={columns}
      title={title}
      data={data}
      options={options}
    />
  );
};

export default ReportTable;
