import translate from 'counterpart';
import MUIDataTable, {
  MUIDataTableColumnDef, MUIDataTableMeta,
  MUIDataTableOptions
} from 'mui-datatables';
import * as React from 'react';
import { csvDownload, textLabels } from '../../../../utils/table-utils';

const typeCellRender = (
    value: any,
    tableMeta: MUIDataTableMeta
): string | React.ReactNode => (
  <div>
    {tableMeta.rowData[2] ?
        tableMeta.rowData[2] :
        value
            ? translate('side.template.' + value, { fallback: value }).toUpperCase()
            : null}
  </div>
);

interface WarrantyModalTableProps {
  data?: any[];
  selectedRows: number[];
  onRowsSelect: (currentRowsSelected: any[], allRowsSelected: any[]) => void;
}

export const WarrantyModalTable: React.FunctionComponent<WarrantyModalTableProps> = (
  props: WarrantyModalTableProps
) => {
  const { data, onRowsSelect, selectedRows } = props;
  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    responsive: 'scrollFullHeight',
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    print: false,
    search: false,
    download: false,
    filter: false,
    viewColumns: false,
    onRowsSelect: onRowsSelect,
    rowsSelected: selectedRows,
    onDownload: csvDownload,
    textLabels: textLabels(translate),
    customToolbarSelect: () => undefined
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'model',
      label: translate('report.label.model'),
      options: {
        sort: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            minWidth: '144px'
          }
        })
      }
    },
    {
      name: 'resType',
      label: translate('report.label.colResType'),
      options: {
        sort: true,
        filter: true,
        customBodyRender: typeCellRender
      }
    },
    {
      name: 'templateCustomName',
      options: {
        display: 'false'
      }
    }
  ];

  return (
    <MUIDataTable title={''} columns={columns} data={data} options={options} />
  );
};

export default WarrantyModalTable;
