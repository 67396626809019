import {
  Button,
  Checkbox,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import translate from 'counterpart';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import history from '../../../config/history';
import { fetchTradePoints } from '../../../reducers/points';
import { activateUser, createUser, deactivateUser, removeUser } from '../../../reducers/users';
import BackButton from '../BackButton';
import AdaptiveDialog from '../dialog/AdaptiveDialog';
import MobileTranslate from '../MobileTranslate';
import Page from '../Page';

const styles = (theme: Theme) => createStyles({
  form: {
    width: '100%'
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  subTitle: {
    fontSize: 24
  },
  allPoints: {
    borderBottom: 'solid 1px #C6C6C6'
  },
  menuButton: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%'
  }
});

interface UserFormProps extends WithStyles<typeof styles> {
  user: any;
  points: any[];
  orgId: number;
  roles: string[];
  fetchTradePoints: ((id) => void);
  onSubmit: ((data) => void);
  onCancel: (() => void);
  removeUser: ((id) => void);
  activateUser: ((id) => void);
  deactivateUser: ((id) => void);
}

interface UserFormState {
  deleteModalOpen: boolean;
}

export class UserForm extends React.Component<UserFormProps, UserFormState> {
  submitMyForm: (() => void) = null;

  readonly state: UserFormState = {
    deleteModalOpen: false
  };

  componentDidMount(): void {
    const { fetchTradePoints, orgId } = this.props;
    fetchTradePoints(orgId);
  }

  isPointChecked = (id, points) => {
    return !!points.find(p => p.id === id);
  };

  handlePointCheck = (id, selectedPoints: any[], setFieldValue) => {
    const { points } = this.props;

    if (id == -1 && selectedPoints.length != points.length) {
      setFieldValue('points', points.slice(), false);
      return;
    } else if (id == -1) {
      setFieldValue('points', [], false);
      return;
    }

    const index = selectedPoints.findIndex(item => item.id == id);
    let newPoints = selectedPoints;
    if (index == -1) {
      const point = points.find(item => item.id == id);
      newPoints = newPoints.concat(point);
    } else {
      newPoints.splice(index, 1);
    }

    setFieldValue('points', newPoints, false);
  };

  submit = (values) => {
    const { user, onSubmit, orgId } = this.props;
    let data = user ? user : values;

    if (user) {
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          data[key] = values[key];
        }
      }
    }

    data['organizationId'] = orgId;
    onSubmit(data);
    return false;
  };

  handleDeleteModal = () => {
    const { deleteModalOpen } = this.state;
    this.setState({
      deleteModalOpen: !deleteModalOpen
    });
  };

  handleDeleteUser = () => {
    const { user, removeUser } = this.props;
    removeUser(user.id);
    this.setState({
      deleteModalOpen: false
    });
    history.push('/users');
  };

  renderActionsBar = (): React.ReactNode => {
    const { user, classes } = this.props;
    const { deleteModalOpen } = this.state;
    return (
      <Grid
        container={true}
        direction={'row'}
        justify={'space-between'}
        alignItems={'center'}
        spacing={2}
        style={{ flexWrap: 'nowrap' }}>
        <Grid item={true}>
          <Grid
            container={true}
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            style={{ flexWrap: 'nowrap' }}>
            <Grid item={true}>
              <Hidden only={'xs'}>
                <BackButton
                  onClick={() => history.push('/users')} />
              </Hidden>
              <Hidden smUp={true}>
                <IconButton
                  edge={'start'}
                  color={'inherit'}
                  aria-label={'menu'}
                  size={'medium'}
                  onClick={() => history.push('/users')}>
                  <ArrowBackIcon />
                </IconButton>
              </Hidden>
            </Grid>
            <Grid item={true} className={classes.title}>
              <MobileTranslate
                desktop={'users.createUser.editUser'}
                mobile={'users.createUser.edit'} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <Grid
            container={true}
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            style={{ flexWrap: 'nowrap' }}>
            {user?.id && (
              <Hidden only={'xs'}>
                <Grid item={true}>
                  <Button
                    size={'large'}
                    color={'secondary'}
                    variant={'outlined'}
                    className={classes.menuButton}
                    onClick={this.handleDeleteModal}>
                    <Translate content={'users.createUser.deleteUser'} />
                  </Button>
                </Grid>
              </Hidden>
            )}
            <Grid item={true}>
              <Button
                size={'large'}
                color={'primary'}
                variant={'contained'}
                className={classes.menuButton}
                onClick={this.submitMyForm}>
                <MobileTranslate
                  desktop={'users.createUser.saveChanges'}
                  mobile={'users.createUser.save'} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <AdaptiveDialog
          closeButton
          open={deleteModalOpen}
          onClose={this.handleDeleteModal}>
          <DialogContent>
            <DialogContentText>
              <Translate content={'users.deleteModal.deleteBody'} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteModal} variant={'outlined'} color={'primary'}>
              <Translate content={'users.deleteModal.cancel'} />
            </Button>
            <Button onClick={this.handleDeleteUser} variant={'contained'} color={'primary'} autoFocus>
              <Translate content={'users.deleteModal.delete'} />
            </Button>
          </DialogActions>
        </AdaptiveDialog>
      </Grid>
    );
  };

  render = (): React.ReactNode => {

    const { points, user, classes, activateUser, deactivateUser } = this.props;

    const initialValues = user ? {
      lastName: user.lastName,
      firstName: user.firstName,
      email: user.email,
      phone: user.phone,
      password: user.password,
      role: user.role,
      organizationId: user.organizationId,
      points: user.points
    } : {
      lastName: '',
      firstName: '',
      email: '',
      phone: '',
      password: '',
      role: 'ROLE_USER',
      organizationId: '',
      points: []
    };

    return (
      <Page actions={this.renderActionsBar()}>
        <Formik
          onSubmit={(values) => {
            this.submit(values);
          }}
          initialValues={initialValues}>
          {props => {
            const { submitForm, setFieldValue, values } = props;
            this.submitMyForm = submitForm;
            return (
              <Form className={classes.form}>
                <Grid
                  container={true}
                  alignItems={'stretch'}
                  spacing={3}
                  style={{ height: 'calc(100% + 24px)' }}>
                  <Grid item={true} sm={6} xs={12}>
                    <Paper className={classes.paper}>
                      <Grid
                        container={true}
                        direction={'column'}
                        spacing={2}>
                        <Grid item={true} className={classes.subTitle}>
                          <Translate content={'users.createUser.information'} />
                        </Grid>
                        <Grid item={true}>
                          <TextField
                            size={'small'}
                            fullWidth={true}
                            name={'lastName'}
                            value={props.values.lastName}
                            variant={'filled'}
                            label={translate('user.label.lastName')}
                            onChange={props.handleChange} />
                        </Grid>
                        <Grid item={true}>
                          <TextField
                            size={'small'}
                            fullWidth={true}
                            name={'firstName'}
                            value={props.values.firstName}
                            variant={'filled'}
                            label={translate('user.label.name')}
                            onChange={props.handleChange} />
                        </Grid>
                        <Grid item={true}>
                          <TextField
                            fullWidth
                            name={'email'}
                            value={props.values.email}
                            variant={'filled'}
                            label={translate('user.label.email')}
                            onChange={props.handleChange} />
                        </Grid>
                        <Grid item={true}>
                          <TextField
                            size={'small'}
                            fullWidth={true}
                            name={'phone'}
                            value={props.values.phone}
                            variant={'filled'}
                            label={translate('user.label.phone')}
                            onChange={props.handleChange} />
                        </Grid>
                        <Grid item={true}>
                          <TextField
                            fullWidth
                            name={'password'}
                            value={props.values.password}
                            variant={'filled'}
                            label={translate('user.label.password')}
                            type={'password'}
                            onChange={props.handleChange} />
                        </Grid>
                        <Grid item={true}>
                          <TextField
                            size={'small'}
                            select={true}
                            fullWidth={true}
                            name={'role'}
                            value={props.values.role}
                            variant={'filled'}
                            label={translate('user.label.role')}
                            onChange={props.handleChange}>
                            <MenuItem key={'ROLE_USER'} value={'ROLE_USER'} selected>
                              {translate('user.roles.ROLE_USER')}
                            </MenuItem>
                          </TextField>
                        </Grid>
                        {user?.id && (
                          <Hidden smUp={true}>
                            <Grid
                              item={true}
                              container={true}
                              justify={'space-between'}
                              alignItems={'center'}>
                              <Grid item={true}>
                                <Typography>
                                  <Translate content={'users.createUser.' + (user.active ? 'deactivateUser' : 'activateUser')} />
                                </Typography>
                              </Grid>
                              <Grid item={true}>
                                <Switch
                                  color={'primary'}
                                  checked={user.active}
                                  onClick={() => user.active ? deactivateUser(user.id) : activateUser(user.id)} />
                              </Grid>
                            </Grid>
                          </Hidden>
                        )}
                        {user?.id && (
                          <Hidden smUp={true}>
                            <Grid item={true}>
                              <Button
                                size={'large'}
                                color={'secondary'}
                                variant={'outlined'}
                                className={classes.menuButton}
                                onClick={this.handleDeleteModal}>
                                <Translate content={'users.createUser.deleteUser'} />
                              </Button>
                            </Grid>
                          </Hidden>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item={true} sm={6} xs={12}>
                    <Paper className={classes.paper}>
                      <Grid
                        container={true}
                        direction={'column'}
                        spacing={2}>
                        <Grid item={true} className={classes.subTitle}>
                          <Translate content={'users.createUser.tradePoints'} />
                        </Grid>
                        <Grid item={true} className={classes.allPoints}>
                          <FormControlLabel
                            label={translate('users.createUser.all')}
                            control={
                              <Checkbox
                                color={'primary'}
                                checked={values?.points?.length === points?.length}
                                indeterminate={values?.points?.length !== points?.length && values?.points?.length > 0}
                                onChange={() => this.handlePointCheck('-1', values.points, setFieldValue)} />
                            } />
                        </Grid>
                        <Grid item={true}>
                          <Grid
                            container={true}
                            direction={'column'}
                            spacing={1}>
                            {points.map((item) => (
                              <Grid item={true} key={item.id}>
                                <FormControlLabel
                                  label={item.name}
                                  control={
                                    <Checkbox
                                      color={'primary'}
                                      checked={this.isPointChecked(item.id, values.points)}
                                      onChange={() => this.handlePointCheck(item.id, values.points, setFieldValue)} />
                                  } />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Page>
    );
  };
}

const mapStateToProps = (state) => ({
  user: state.users.selectedUser,
  points: state.points.items,
  orgId: state.authentication.organizationId,
  roles: state.authentication.roles,
  currentLocale: state.locale.currentLocale
});

const mapDispatchToProps = {
  fetchTradePoints,
  onSubmit: createUser,
  removeUser,
  onCancel: (() => history.push('users')),
  activateUser,
  deactivateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserForm));
