import moment from 'moment';
import { AnyAction, Reducer } from 'redux';
import { Booking } from '../models/Booking';
import { CreateBookingDTO } from '../models/CreateBookingDTO';
import { date } from 'yup';
import { format, formatISO } from 'date-fns';
import { BookingType } from '../models/BookingType';
import { parseDateTime } from '../utils/date-utils';
import { formatTime } from '../utils/date-utils';
import { fetchServices } from './services';

export interface BookingState {
  items: Booking[];
  bookingItem: any;
  times: string[];
  item: any;
}

export const initialState: BookingState = {
  items: [],
  bookingItem: {},
  times: [],
  item: ''
};

// Reducer

const reducer: Reducer<BookingState> = (
  state: BookingState = initialState,
  action: AnyAction
): BookingState => {
  switch (action.type) {
    case FETCH_BOOKINGS: {
      return { ...state, items: [] };
    }
    case FETCH_BOOKINGS_SUCCESS: {
      return { ...state, items: action.result.data };
    }
    case FETCH_BOOKINGS_FAIL: {
      return { ...state, items: [] };
    }
    case FETCH_ALL_BOOKINGS: {
      return { ...state, items: [] };
    }
    case FETCH_ALL_BOOKINGS_SUCCESS: {
      return { ...state, items: action.result.data };
    }
    case FETCH_ALL_BOOKINGS_FAIL: {
      return { ...state, items: [] };
    }
    case FETCH_BOOKING: {
      return { ...state, item: '' };
    }
    case FETCH_BOOKING_SUCCESS: {
      return { ...state, item: action.result.data };
    }
    case DELETE_BOOKINGS: {
      return { ...state, bookingItem: {} };
    }
    case DELETE_BOOKINGS_SUCCESS: {
      return { ...state, bookingItem: action.result.data };
    }
    case DELETE_BOOKINGS_FAIL: {
      return { ...state, bookingItem: {} };
    }
    case CREATE_BOOKING: {
      return { ...state };
    }
    case CREATE_BOOKING_SUCCESS: {
      return { ...state, items: [] };
    }
    case CREATE_BOOKING_FAIL: {
      return { ...state };
    }
    case LOCK_TIME: {
      return { ...state };
    }
    case LOCK_TIME_SUCCESS: {
      return { ...state };
    }
    case LOCK_TIME_FAIL: {
      return { ...state };
    }
    case GET_TIME: {
      return { ...state };
    }
    case GET_TIME_SUCCESS: {
      const dates: Date[] = action.result.data;
      const times: string[] = dates.map((date: Date) => {
        return formatTime(parseDateTime(date));
      });
      return { ...state, times: times };
    }
    case GET_TIME_FAIL: {
      return { ...state, times: [] };
    }
    default: {
      return state;
    }
  }
};

export { reducer as bookingReducer };

// Actions

const FETCH_ALL_BOOKINGS = 'booking/FETCH_ALL_BOOKINGS';
const FETCH_ALL_BOOKINGS_SUCCESS = 'booking/FETCH_ALL_BOOKINGS_SUCCESS';
const FETCH_ALL_BOOKINGS_FAIL = 'booking/FETCH_ALL_BOOKINGS_FAIL';

export const fetchAllBookings = (date: Date) => ({
  types: [
    FETCH_ALL_BOOKINGS,
    FETCH_ALL_BOOKINGS_SUCCESS,
    FETCH_ALL_BOOKINGS_FAIL
  ],
  promise: (client) =>
    client.get(
      `/api/booking/points/all`,
      date
        ? {
          params: {
            date: moment(date).format('YYYY-MM-DD')
          }
        }
        : {}
    )
});

const FETCH_BOOKINGS = 'booking/FETCH_BOOKINGS';
const FETCH_BOOKINGS_SUCCESS = 'booking/FETCH_BOOKINGS_SUCCESS';
const FETCH_BOOKINGS_FAIL = 'booking/FETCH_BOOKINGS_FAIL';

export const fetchBookings = (tradePointId: number, date: Date) => ({
  types: [FETCH_BOOKINGS, FETCH_BOOKINGS_SUCCESS, FETCH_BOOKINGS_FAIL],
  promise: (client) =>
    client.get(`/api/booking/points/${tradePointId}/all`, {
      params: {
        date: moment(date).format('YYYY-MM-DD')
      }
    })
});

const DELETE_BOOKINGS = 'booking/DELETE_BOOKINGS';
const DELETE_BOOKINGS_SUCCESS = 'booking/DELETE_BOOKINGS_SUCCESS';
const DELETE_BOOKINGS_FAIL = 'booking/DELETE_BOOKINGS_FAIL';

export const deleteBooking = (bookingId: number, date, tradePointId: number) => ({
  types: [DELETE_BOOKINGS, DELETE_BOOKINGS_SUCCESS, DELETE_BOOKINGS_FAIL],
  promise: (client) => client.delete('/api/booking/' + bookingId),
  afterSuccess: (dispatch) => {
    dispatch(fetchBookings(tradePointId, date));
  }
});

const CREATE_BOOKING = 'booking/CREATE_BOOKING';
const CREATE_BOOKING_SUCCESS = 'booking/CREATE_BOOKING_SUCCESS';
const CREATE_BOOKING_FAIL = 'booking/CREATE_BOOKING_FAIL';

export const createBooking = (dto) => ({
  types: [CREATE_BOOKING, CREATE_BOOKING_SUCCESS, CREATE_BOOKING_FAIL],
  promise: (client) => client.post('/api/booking', dto),
  afterSuccess: (dispatch) => {
    dispatch(fetchBookings(dto.tradePointId, dto.date));
  }
});

const UPDATE_BOOKING = 'booking/UPDATE_BOOKING';
const UPDATE_BOOKING_SUCCESS = 'booking/UPDATE_BOOKING_SUCCESS';
const UPDATE_BOOKING_FAIL = 'booking/UPDATE_BOOKING_FAIL';

export const updateBooking = (bookingId, dto, date) => ({
  types: [UPDATE_BOOKING, UPDATE_BOOKING_SUCCESS, UPDATE_BOOKING_FAIL],
  promise: (client) => client.put('/api/booking/edit/' + bookingId, dto),
  afterSuccess: (dispatch) => {
    dispatch(fetchBookings(dto.tradePointId, date));
  }
});

const FETCH_BOOKING = 'booking/FETCH_BOOKING';
const FETCH_BOOKING_SUCCESS = 'booking/FETCH_BOOKING_SUCCESS';
const FETCH_BOOKING_FAIL = 'booking/FETCH_BOOKING_FAIL';

export const fetchBooking = (id: number) => ({
  types: [FETCH_BOOKING, FETCH_BOOKING_SUCCESS, FETCH_BOOKING_FAIL],
  promise: (client) =>
    client.get(`/api/booking/table/${id}`)
});

const LOCK_TIME = 'booking/LOCK_TIME';
const LOCK_TIME_SUCCESS = 'booking/LOCK_TIME_SUCCESS';
const LOCK_TIME_FAIL = 'booking/LOCK_TIME_FAIL';

export const lockTime = (dto) => ({
  types: [LOCK_TIME, LOCK_TIME_SUCCESS, LOCK_TIME_FAIL],
  promise: (client) => client.post('/api/booking/timeslots/lock', dto)
});

const UNLOCK_TIME = 'booking/UNLOCK_TIME';
const UNLOCK_TIME_SUCCESS = 'booking/UNLOCK_TIME_SUCCESS';
const UNLOCK_TIME_FAIL = 'booking/UNLOCK_TIME_FAIL';

export const unlockTime = (dto, bookingId, date) => ({
  types: [UNLOCK_TIME, UNLOCK_TIME_SUCCESS, UNLOCK_TIME_FAIL],
  promise: (client) => client.post('/api/booking/timeslots/unlock', dto),
  afterSuccess: (dispatch) => {
    dispatch(deleteBooking(bookingId, date, dto.tradePointId));
  }
});

const GET_TIME = 'booking/GET_TIME';
const GET_TIME_SUCCESS = 'booking/GET_TIME_SUCCESS';
const GET_TIME_FAIL = 'booking/GET_TIME_FAIL';

export const getAvailableTimes = (
  date: Date,
  storeId: number,
  type: string
) => ({
  types: [
    GET_TIME,
    GET_TIME_SUCCESS,
    GET_TIME_FAIL
  ],
  promise: (client) => client.get('/api/booking/timeslots/', {
    params: {
      date: formatISO(parseDateTime(date), { representation: 'date' }),
      currentTime: formatISO(parseDateTime(date), { representation: 'time' }),
      tradePointId: storeId,
      type: type
    }
  })
});

const CHANGE_BOOKING_TIME = 'booking/CHANGE_BOOKING_TIME';
const CHANGE_BOOKING_TIME_SUCCESS = 'booking/CHANGE_BOOKING_TIME_SUCCESS';
const CHANGE_BOOKING_TIME_FAIL = 'booking/CHANGE_BOOKING_TIME';

export const changeBookingTime = (dto, id, date) => ({
  types: [
    CHANGE_BOOKING_TIME,
    CHANGE_BOOKING_TIME_SUCCESS,
    CHANGE_BOOKING_TIME_FAIL
  ],
  promise: (client) => client.put('/api/booking/changeTime/' + id, dto),
  afterSuccess: (dispatch) => {
    dispatch(fetchBookings(dto.tradePointId, date));
  }
});

