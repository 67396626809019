import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  Grid,
  List,
  Theme, Tooltip,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import translate from 'counterpart';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { ChangeEvent } from 'react';
import Translate from 'react-translate-component';
import AdaptiveDialog from '../AdaptiveDialog';
import FilledTextField from '../../field/FilledTextField';
import moment from 'moment';
import { UpdateBookingDTO } from '../../../../models/UpdateBookingDTO';
import { Device } from '../../../../models/Device';
import { DevicesServices } from '../../../../models/DevicesServices';
import { fetchBooking, updateBooking } from '../../../../reducers/booking';
import { connect } from 'react-redux';
import BookingDeviceAutoEdit from './BookingDeviceAutoEdit';
import PhoneInput from 'react-phone-input-2';
import { TradePointService } from '../../../../models/TradePointService';
import { DeviceService } from '../../../../models/DeviceService';
import { formatISO } from 'date-fns';
import { parseDateTime } from '../../../../utils/date-utils';
import { DeleteOutlined } from '@material-ui/icons';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      overflowY: 'visible',
      [theme.breakpoints.down('xs')]: {
        overflowY: 'auto'
      }
    },
    content: {
      padding: '16px 0px 32px',
      overflowY: 'visible'
    },
    internalContent: {
      padding: '0px 16px 0px'
    },
    contentTable: {
      display: 'flex',
      padding: 0
    },
    actions: {
      padding: '0px 16px 8px'
    },
    dividerText: {
      fontSize: 12
    },
    dividerButton: {
      width: '20px',
      height: '20px'
    },
    dividerButtonContainer: {
      textAlign: 'right'
    },
    devicesDivider: {
      margin: '0 0 16px'
    },
    listItem: {
      padding: '6px 16px 6px',
      minHeight: '20px',
      width: '100%',
      margin: '0 0 16px 0',
      backgroundColor: '#F5F7FA'
    }
  });

interface FormValues {
  name: string;
  phone: string;
}

interface Props extends WithStyles<typeof styles> {
  rowDate: Date;
  rowTime: any;
  bookingId: number;
  pointId: number;
  show: boolean;
  booking: any;
  type: string;
  handleShowCreateBooking: () => void;
  fetchBooking: ((bookingId) => void);
  updateBooking: ((bookingId, dto, date) => void);
}

interface State {
  show: boolean;
  name: string,
  phone: string,
  services: DeviceService[];
  devices: DevicesServices[];
}

export class BookingEditDialog extends React.Component<Props, State> {
  state: State = {
    show: true,
    name: null,
    phone: null,
    devices: null,
    services: null
  };

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    if (nextProps.booking && nextProps.booking?.id !== this.props.booking?.id) {
      let deviceServices: DeviceService[] = [];
      let devices: DevicesServices[] = [];
      nextProps.booking.devices.map(device => {
        let serviceIds: number[] = [];
        let stateServices: TradePointService[] = [];
        device.services.map(service => {
          let stateService: TradePointService = {
            id: service.id,
            name: service.name,
            price: null,
            time: null
          };
          stateServices.push(stateService);
        });
        device.selectedServices.map(service => {
          serviceIds.push(service.id);
        });
        let deviceService: DeviceService = {
          deviceId: device.id,
          serviceIds: serviceIds
        };
        deviceServices.push(deviceService);

        let stateDevice: DevicesServices = {
          device: {
            id: device.id,
            name: device.name
          },
          services: stateServices,
          selectedServices: serviceIds
        };
        devices.push(stateDevice);
      });

      this.setState({
        devices: devices,
        services: deviceServices,
        name: nextProps.booking.name,
        phone: nextProps.booking.phone
      });
    }
  }

  handleClose = (): void => {
    const { show, handleShowCreateBooking } = this.props;
    if (show) {
      this.setState({ show: true });
    }
    handleShowCreateBooking();
  };

  getModelServices = (): { [x: number]: number[] } => {
    const { services } = this.state;
    let newServices: DeviceService[] = services;
    let filteredServices: DeviceService[] = [];
    newServices.forEach((service) => {
      if (service.deviceId && service.serviceIds.length > 0) {
        filteredServices.push(service);
      }
    });
    const servicesMap: { [x: number]: number[] } = {};
    filteredServices.forEach((ds: DeviceService) => {
      servicesMap[ds.deviceId] = ds.serviceIds;
    });
    return servicesMap;
  };

  handleSubmit = (values: FormValues) => {
    const { rowTime, rowDate, pointId, handleShowCreateBooking, bookingId, updateBooking } = this.props;
    let year = rowDate.getUTCFullYear();
    let month = rowDate.getUTCMonth() + 1;
    let day = rowDate.getUTCDate();
    let dateTime = new Date(moment(year + '-' + month + '-' + day + ' ' + rowTime).format());
    const dto: UpdateBookingDTO = {
      tradePointId: pointId,
      modelServices: this.getModelServices(),
      customerName: values.name,
      customerPhone: values.phone
    };
    updateBooking(bookingId, dto, formatISO(parseDateTime(dateTime), { representation: 'date' }));
    handleShowCreateBooking();
  };

  handleSelectDevice = (newDevice, index: number): void => {
    const { services, devices } = this.state;
    let newDevices = devices;
    let newServices = services;
    if (newDevice) {
      newDevices[index].device = {
        id: newDevice?.id,
        name: newDevice?.name
      };
    } else {
      newDevices[index].device = { id: undefined, name: undefined };
    }
    newDevices[index].services = newDevice?.service ? newDevice?.service : [];
    newServices[index].deviceId = newDevice?.id;
    newDevices[index].selectedServices = [];
    newServices[index].serviceIds = [];
    this.setState({ devices: newDevices, services: newServices });
  };

  handleSelectServices = (index: number, servicesIds: number[]): void => {
    const { services } = this.state;
    let newServices: DeviceService[] = services;
    if (index >= 0) {
      newServices[index].serviceIds = servicesIds;
      this.setState({ services: newServices });
    }
  };

  handleDeleteDevice = (index: number) => {
    const { services, devices } = this.state;
    let newDevices = devices;
    let device = newDevices[index];
    let newServices = services;
    let servicesIndex = newServices.findIndex(service => service.deviceId === device.device.id);
    newDevices.splice(index, 1);
    newServices.splice(servicesIndex, 1);
    this.setState({ devices: newDevices, services: newServices });
  };

  handleAddDevice = () => {
    const { services, devices } = this.state;
    let newDevices = devices;
    let newDevice: DevicesServices = {
      device: {
        id: undefined,
        name: undefined
      },
      services: [],
      selectedServices: []
    };
    let newServices = services;
    let newService: DeviceService = {
      deviceId: undefined,
      serviceIds: []
    };
    newDevices.push(newDevice);
    newServices.push(newService);
    this.setState({ devices: newDevices, services: newServices });
  };

  render = (): React.ReactNode => {
    const { show, classes, rowTime, rowDate, pointId, type } = this.props;
    const { devices, name, phone } = this.state;
    const initialValues: FormValues = {
      name: name ? name : '',
      phone: phone ? phone : ''
    };
    return (
      <AdaptiveDialog
        open={show}
        onClose={this.handleClose}
        title={translate('booking.dialog.editBooking') + ' ' + rowTime + ' ' + moment(rowDate).format('D MMMM, ddd')}
        closeButton={true}
        paperClass={classes.paper}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={''}
          validateOnChange={true}
          onSubmit={this.handleSubmit}
        >
          {({
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
              isValid
            }: FormikProps<FormValues>) => {
            return (
              <Form>
                <DialogContent className={classes.content} >
                  <Grid container={true} direction={'column'} spacing={2} className={classes.internalContent}>
                    <Grid item={true}>
                      <FilledTextField
                        name={'name'}
                        variant={'filled'}
                        required={true}
                        type={'string'}
                        value={values?.name}
                        label={translate('booking.dialog.customerName')}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name ? errors.name : ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setFieldTouched(e.target.name, true, false);
                          setFieldValue(e.target.name, e.target.value, true);
                        }}
                      />
                    </Grid>
                    <Grid item={true}>
                      <PhoneInput
                        inputStyle={{ 'fontSize': '16px', 'width': '100%', 'height': '48px' }}
                        country={'ru'}
                        value={values?.phone}
                        onChange={(value) => {
                          setFieldTouched('phone', true, false);
                          setFieldValue('phone', value, true);
                        }} />
                    </Grid>
                  </Grid>
                  <Grid container={true} direction={'column'} spacing={2}>
                    <Grid item={true}>
                      {
                        devices.map((device, index) =>
                          <List key={index}>
                            {
                              devices.length > 1 ?
                                <Grid container className={classes.listItem}>
                                  <Grid item={true} xs={6}>
                                    <Typography className={classes.dividerText}>
                                      {translate('booking.dialog.device') + ' ' + (index + 1)}
                                    </Typography>
                                  </Grid>
                                  <Grid item={true} xs={6} className={classes.dividerButtonContainer}>
                                    {
                                      index > 0 ?
                                        <Tooltip title={translate('booking.dialog.delete')}>
                                          <Button
                                            type={'button'}
                                            onClick={() => {
                                              this.handleDeleteDevice(index);
                                            }}
                                          >
                                            <DeleteOutlined className={classes.dividerButton} />
                                          </Button>
                                        </Tooltip> : <div />
                                    }
                                  </Grid>
                                </Grid> : <div />
                            }
                            <Grid item={true} className={classes.internalContent}>
                              <Grid item={true}>
                                <BookingDeviceAutoEdit
                                  onSelect={this.handleSelectDevice}
                                  onSelectServices={this.handleSelectServices}
                                  device={device.device}
                                  services={device.services}
                                  selectedServices={device.selectedServices}
                                  pointId={pointId}
                                  index={index} />
                              </Grid>
                            </Grid>
                          </List>
                        )
                      }
                      {type === 'SALE' ?
                        <Grid item={true} className={classes.internalContent}>
                          <Button
                            style={{ marginTop: '8px' }}
                            color={'primary'}
                            variant={'outlined'}
                            onClick={() => {
                              this.handleAddDevice();
                            }}
                          >
                            <Translate content={'booking.dialog.addDevice'} />
                          </Button>
                        </Grid>
                        :
                        <div></div>
                      }
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.actions}>
                  <Button
                    variant={'contained'}
                    color={'default'}
                    type={'button'}
                    disabled={false}
                    onClick={this.handleClose}
                  >
                    <Translate content={'booking.dialog.close'} />
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    disabled={!isValid}
                  >
                    <Translate content={'booking.dialog.save'} />
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </AdaptiveDialog>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateBooking: (bookingId, dto, date) => {
      dispatch(updateBooking(bookingId, dto, date));
    },
    fetchBooking: (bookingId) => {
      dispatch(fetchBooking(bookingId));
    }
  };
};

const mapStateToProps = (state) => ({
  booking: state.booking.item
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookingEditDialog));
