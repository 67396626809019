import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import translate from 'counterpart';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import { findModels, setSearchName } from '../../reducers/model';
import WarningDialog from './dialog/WarningDialog';

interface BrandSearchValues {
  model: string;
}

interface BrandSearchProps {
  totalCuts: number;
  findModels: ((name: string) => any);
  setSearchName: (name: string) => void;
  searchName: string;
  autoFocus?: boolean;
}

interface BrandSearchState {
  warningDialogOpen: boolean;
  searchHandleTimer: NodeJS.Timer | null;
}

const initialState: BrandSearchState = {
  warningDialogOpen: false,
  searchHandleTimer: null
}

export const searchModelConfiguration = {
  startLength: 2,
  handleTimeout: 500
}

class BrandSearch extends React.Component<BrandSearchProps, BrandSearchState> {

  readonly state = initialState;

  clearSearchHandleTimer = () => {
    this.state.searchHandleTimer && clearTimeout(this.state.searchHandleTimer);
  }

  handleSearchNameChange = (value: string) => {
    this.props.setSearchName(value);
    this.clearSearchHandleTimer();
    if (value.length >= searchModelConfiguration.startLength) {
      this.setState({
        ...this.state,
        searchHandleTimer: setTimeout(this.handleSubmit, searchModelConfiguration.handleTimeout)
      });
    }
  }

  handleSubmit = (): void => {
    const { totalCuts, findModels, searchName } = this.props;
    this.clearSearchHandleTimer();
    if (totalCuts > 0) {
      searchName && findModels(searchName);
    } else {
      this.setState({ ...this.state, warningDialogOpen: true });
    }
  };

  handleWarningDialogClose = (): void => {
    this.setState({ ...this.state, warningDialogOpen: false });
  };

  render = (): React.ReactNode => {
    const { warningDialogOpen } = this.state;
    return (
      <React.Fragment>
        <Formik
          initialValues={{ model: this.props.searchName }}
          onSubmit={this.handleSubmit}>
          {() => (
            <Form>
              <TextField
                value={this.props.searchName}
                fullWidth={true}
                size={'small'}
                name={'model'}
                variant={'outlined'}
                autoFocus={this.props.autoFocus}
                placeholder={translate('brandPage.search')}
                onChange={e => this.handleSearchNameChange(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={'start'}>
                      <SearchIcon color={'disabled'} />
                    </InputAdornment>
                  )
                }} />
            </Form>
          )}
        </Formik>
        <WarningDialog
          open={warningDialogOpen}
          content={
            <Translate content={'error.paymentRequired'} />
          }
          onClose={this.handleWarningDialogClose} />
      </React.Fragment>
    );
  };
}

const mapStateToProps = (state) => ({
  totalCuts: state.balances.cutsNumber,
  currentLocale: state.locale.currentLocale,
  searchName: state.model.searchName
});

const mapDispatchToProps = {
  findModels,
  setSearchName
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandSearch);
