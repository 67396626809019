import {
  Breadcrumbs,
  Button,
  createStyles, DialogActions,
  Grid,
  Hidden,
  Link,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import clsx from 'clsx';
import translate from 'counterpart';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Translate from 'react-translate-component';
import history from '../../../config/history';
import { fetchModels } from '../../../reducers/model';
import { clearOnprint, fetchTemplates, getPreview, sendTemplate } from '../../../reducers/template';
import BackButton from '../BackButton';
import BrandSearch from '../BrandSearch';
import AdaptiveDialog from '../dialog/AdaptiveDialog';
import InfoBlock from '../InfoBlock';
import Page from '../Page';
import UseWarrantyConfirmModalWindow from '../dialog/warranty/UseWarrantyConfirmModalWindow';
import { confirmWarranty, sendSmsConfirm } from '../../../reducers/warranty';
import { fetchCategories } from '../../../reducers/category';

const styles = (theme: Theme) => createStyles({
  paper: {
    height: '100%',
    width: '100%'
  },
  titleContainer: {
    display: 'table',
    borderSpacing: theme.spacing(1)
  },
  title: {
    display: 'table-cell',
    fontSize: 24,
    marginRight: theme.spacing(1)
  },
  header: {
    fontSize: '24px',
    lineHeight: '32px'
  },
  headerDisabled: {
    fontSize: '24px',
    lineHeight: '32px',
    color: '#8e8e8e'
  },
  count: {
    display: 'table-cell',
    fontSize: 16,
    color: '#00b6b4',
    padding: '4px 8px',
    background: 'rgba(0, 182, 180, 0.1)',
    border: '1px solid #00B6B4',
    borderRadius: 12
  },
  template: {
    height: '100%',
    display: 'block',
    margin: 'auto',
    maxHeight: '30vh',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  templateButton: {
    width: '100%',
    color: '#8e8e8e',
    border: '2px solid #8e8e8e'
  },
  templateButtonSelected: {
    color: theme.colors.brono,
    border: '2px solid ' + theme.colors.brono,
    '&:hover': {
      border: '2px solid ' + theme.colors.brono
    }
  },
  templateButtonLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left'
  },
  endIcon: {
    marginTop: -24,
    marginRight: -8
  },
  dialog: {
    padding: theme.spacing(6)
  },
  mainBlock: {
    width: '100%',
    height: 'calc(100%)'
  },
  templatesBlock: {
    padding: theme.spacing(2),
    height: '100%',
    width: 'calc(100% - 336px)',
    borderRight: '1px solid #E8E8E8',
    display: 'inline-block',
    overflowY: 'auto'
  },
  actionsBlock: {
    width: '336px',
    height: '100%',
    display: 'inline-block',
    float: 'right'
  },
  previewBlock: {
    padding: theme.spacing(2),
    height: 'calc(100% - 160px)'
  },
  infoBlock: {
    padding: theme.spacing(1),
    borderTop: '1px solid #E8E8E8',
    height: '160px'
  },
  commentText: {
    padding: theme.spacing(2),
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    height: '60px',
    paddingBottom: '12px'
  },
  headerBlock: {
    padding: theme.spacing(2),
    height: '64px'
  },
  imageBlock: {
    padding: theme.spacing(2),
    height: 'calc(100% - 64px - 160px - 80px + 4px)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: '1px dashed #8e8e8e',
    position: 'relative'
  },
  buttonContainer: {
    width: 'calc(100% - 24px)'
  },
  buttonHeader: {
    fontSize: 18,
    color: 'black',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical'
  },
  buttonHeaderSelected: {
    fontSize: 18,
    color: theme.colors.brono,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical'
  },
  buttonComment: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    height: '24px',
    textTransform: 'none'
  }
});

interface ChooseTemplateProps extends WithStyles<typeof styles> {
  params: any;
  location: any;
  items: any[];
  template: any;
  onprint: any;
  brand: any;
  model: any;
  category: any;
  totalCuts: any;
  sendTemplate: ((t, guarranty) => void);
  fetchTemplates: ((id) => any);
  fetchCategories: () => void;
  getPreview: ((t) => void);
  clearOnprint: (() => void);
  fetchModels: (brandId, categoryId) => void;
  sendSmsConfirm: ((warrantyCodeDTO) => void);
  confirmWarranty: ((warrantyCodeDTO) => void);
}

interface ChooseTemplateState {
  selectedTemplate: any;
  warranty: string;
  confirmed: boolean;
  openConfirmModal: boolean;
}

export class ChooseTemplate extends React.Component<ChooseTemplateProps, ChooseTemplateState> {

  readonly state: ChooseTemplateState = {
    selectedTemplate: null,
    warranty: null,
    confirmed: false,
    openConfirmModal: false
  };

  componentDidMount = (): void => {
    const { fetchTemplates, params, fetchModels, fetchCategories } = this.props;
    fetchCategories();
    fetchModels(params.brandId, params.categoryId);
    fetchTemplates(params.modelId);
    const query = new URLSearchParams(this.props.location.search);
    const warranty = query.get('warrantyId') ? query.get('warrantyId') : '';
    this.setState({ warranty: warranty });
  };

  componentDidUpdate = (prevProps: Readonly<ChooseTemplateProps>, prevState): void => {
    const { location, getPreview, params, fetchTemplates, fetchModels, template, fetchCategories } = this.props;
    const { selectedTemplate } = this.state;
    const query: URLSearchParams = new URLSearchParams(location.search);
    const warranty = query.get('warrantyId') ? query.get('warrantyId') : '';
    if (prevProps.params.brandId !== params.brandId || prevProps.params.modelId !== params.modelId ||
      prevProps.params.categoryId !== params.categoryId || prevState.warranty !== warranty) {
      fetchCategories();
      fetchModels(params.brandId, params.categoryId);
      fetchTemplates(params.modelId);
      this.setState({ selectedTemplate: null, warranty: warranty, confirmed: false });
      if (!warranty) {
        this.setState({ confirmed: true })
      }
    }
    if ((template?.items?.length > 0) && !template?.template && query.get('resType')) {
      let newTemplate = template.items.find((t) => t.sideId == query.get('resType'));
      if (newTemplate && !selectedTemplate) {
        getPreview(newTemplate);
        this.setState({ selectedTemplate: newTemplate });
      }
    }
  };

  openConfirmModal = () => {
    this.setState({ openConfirmModal: !this.state.openConfirmModal })
  }

  sendTemplate = (e) => {
    e.preventDefault();
    const { selectedTemplate } = this.state;
    const query = new URLSearchParams(this.props.location.search);
    const warranty = query.get('warrantyId') ? query.get('warrantyId') : '';
    if (selectedTemplate) {
      this.props.sendTemplate(selectedTemplate, warranty);
      this.setState({ openConfirmModal: false });
    }
  };

  confirmWarranty = (warrantyCodeDTO): void => {
    const { confirmWarranty } = this.props;
    confirmWarranty(warrantyCodeDTO);
  }

  setConfirmed = (): void => {
    this.setState({ confirmed: true });
  }

  renderActionsBar = (): React.ReactNode => {
    const { params: {categoryId, brandId, modelId}, brand, model, category, classes } = this.props;
    const selectedCategory = category.items.find(x => x.id == categoryId);
    const selectedBrand = brand.categoryItems.find(x => x.id == brandId);
    const selectedModel = model.items.find(x => x.id == modelId);
    return (
      <Grid
        container={true}
        direction={'column'}
        spacing={2}
        style={{ margin: '0px -8px' }}>
        <Hidden smUp={true}>
          <Grid item={true}>
            <BrandSearch />
          </Grid>
        </Hidden>
        <Grid item={true}>
          <Grid
            container={true}
            direction={'row'}
            justify={'space-between'}
            alignItems={'center'}
            spacing={2}
            style={{ flexWrap: 'nowrap' }}>
            <Grid item={true}>
              <Grid
                container={true}
                direction={'row'}
                alignItems={'center'}
                spacing={2}
                style={{ flexWrap: 'nowrap' }}>
                <Grid item={true}>
                  <BackButton onClick={() => history.push(`/category/${categoryId}/brand/${brandId}/model`)} />
                </Grid>
                <Grid item={true}>
                  <Breadcrumbs className={classes.title}>
                    <Link
                      color={'inherit'}
                      href={`/category/${categoryId}/brand`}
                    >
                      {selectedCategory && selectedCategory.name}
                    </Link>
                    <Link
                      color={'inherit'}
                      href={`/category/${categoryId}/brand/${brandId}/model`}
                    >
                      {selectedBrand && selectedBrand.name}
                    </Link>
                    <Link color={'inherit'}>
                      {selectedModel && selectedModel.name}
                    </Link>
                  </Breadcrumbs>
                </Grid>
              </Grid>
            </Grid>
            <Hidden xsDown={true}>
              <Grid item={true}>
                <BrandSearch />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderPopup() {
    const { onprint, clearOnprint, classes } = this.props;
    return (
      <AdaptiveDialog open={this.state.openConfirmModal} onClose={this.openConfirmModal} disableBackdropClick={true}>
        <div className={classes.dialog}>
          <Grid
            container
            spacing={3}
            direction={'column'}
            justify={'center'}
            alignItems={'center'}>
            <Grid item>
              <img src={'/images/logo-round-big.png'} alt={'logo'} />
            </Grid>
            <Grid item>
              <Typography variant={'h6'}>
                <Translate content={'printPopup.text'} />
              </Typography>
            </Grid>
            <Grid item>
              <DialogActions className={classes.buttonContainer}>
                <Button variant={'contained'}  color={'primary'} onClick={this.sendTemplate}>
                  <Translate content={'printPopup.buttonConfirm'}/>
                </Button>
                <Button variant={'contained'} color={'default'} onClick={this.openConfirmModal}>
                  <Translate content={'printPopup.buttonCancel'}/>
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </div>
      </AdaptiveDialog>
    );
  }

  handleTemplate = (id) => {
    const { template, getPreview } = this.props;
    const selectedTemplate = template.items.find(t => t.id == id);
    getPreview(selectedTemplate);
    this.setState({ selectedTemplate: selectedTemplate });
  };

  render = (): React.ReactNode => {
    const { template, onprint, totalCuts, classes } = this.props;
    const { selectedTemplate } = this.state;
    const errors: string[] = [];
    if (template.errorKey) {
      errors.push(translate(template.errorKey));
    }
    if (totalCuts <= 0) {
      errors.push(translate('side.label.noCuts'));
    }
    const isChecked = (id) => selectedTemplate ? selectedTemplate.id == id : false;
    if (template.items) {
      template.items.sort((a, b) => {
        return a.sideId - b.sideId;
      });
    }
    return (
      <Page
        actions={this.renderActionsBar()}
        error={errors}>
        <Paper className={classes.paper}>
          <div className={classes.mainBlock}>
            <div className={classes.templatesBlock}>
              <Grid
                  container={true}
                  direction={'column'}
                  spacing={3}>
                <Grid item={true}>
                  <Typography className={classes.header}>
                    <Translate content={'side.hint1'} />
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Grid
                      container={true}
                      direction={'row'}
                      spacing={2}>
                    {template?.items?.filter((item) => item.active).map((item) => (
                        <Grid item={true} key={item.id} xs={12} sm={6}>
                          <Button
                              variant={'outlined'}
                              classes={{
                                root: clsx(classes.templateButton, {
                                  [classes.templateButtonSelected]: isChecked(item.id)
                                }),
                                endIcon: classes.endIcon,
                                label: classes.templateButtonLabel
                              }}
                              onClick={() => this.handleTemplate(item.id)}
                              color={isChecked(item.id) ? 'primary' : 'inherit'}
                              endIcon={isChecked(item.id) ? <CheckCircleOutlineIcon /> : null}>
                            <div className={classes.buttonContainer}>
                              <Typography classes={{
                                root: clsx(classes.buttonHeader, {
                                  [classes.buttonHeaderSelected]: isChecked(item.id)
                                })
                              }}>
                                {item.customName ? item.customName : item.name}
                              </Typography>
                              <Typography className={classes.buttonComment}>
                                {item?.comment}
                              </Typography>
                            </div>
                          </Button>
                        </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          <div className={classes.actionsBlock}>
            <div className={classes.headerBlock}>
              {selectedTemplate ?
              <Typography className={classes.header}>
                {selectedTemplate.customName ? selectedTemplate.customName : selectedTemplate.name}
              </Typography> :
                  <Typography className={classes.headerDisabled}>
                    <Translate content={'side.hint2'} />
                  </Typography>}
            </div>
            <div className={classes.imageBlock}>
              {this.props.template.preview != '' ?
                  <img
                      src={'data:image/png;base64, ' + this.props.template.preview}
                      className={classes.template} alt={''} /> : null}
            </div>
            <div className={classes.commentText}>
              {selectedTemplate &&
              <span>
                {selectedTemplate.comment}
              </span>}
            </div>
            <div className={classes.infoBlock}>
              <InfoBlock
                template={selectedTemplate}
                sendTemplateDisabled={!selectedTemplate || (totalCuts <= 0)}
                onSendTemplate={this.sendTemplate}
                warranty={this.state.warranty}
                confirmWarranty={this.confirmWarranty}
                confirmed={this.state.confirmed}
                setConfirmed={this.setConfirmed}
                openConfirmModal={this.openConfirmModal}
              />
            </div>
          </div>
          </div>
        {this.renderPopup()}
        </Paper>
      </Page>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: state.warranty.smsCodeError,
    params: ownProps.match.params,
    location: ownProps.location,
    items: state.side.items,
    template: state.template,
    onprint: state.template.onprint,
    brand: state.brand,
    model: state.model,
    category: state.category,
    totalCuts: state.balances.cutsNumber,
    currentLocale: state.locale.currentLocale
  };
};

const mapDispatchToProps = {
  sendTemplate,
  fetchTemplates,
  getPreview,
  clearOnprint,
  fetchModels,
  fetchCategories,
  sendSmsConfirm,
  confirmWarranty
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChooseTemplate)));
