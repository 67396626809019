import {
  Button,
  Checkbox,
  createStyles,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import translate from 'counterpart';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import * as Yup from 'yup';
import { TradePointService } from '../../../models/TradePointService';
import { updateServicePrice } from '../../../reducers/services';
import { clearItems, getWarranty } from '../../../reducers/warranty';
import AdaptiveDialog from '../dialog/AdaptiveDialog';
import FilledTextField from '../field/FilledTextField';
import { TradePoint } from '../../../models/TradePoint';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      overflowY: 'visible',
      [theme.breakpoints.down('xs')]: {
        overflowY: 'auto'
      }
    },
    content: {
      padding: '16px 16px 32px',
      overflowY: 'visible'
    },
    contentTable: {
      display: 'flex',
      padding: 0
    },
    actions: {
      padding: '0px 16px 24px'
    },
    points: {
      fontSize: '14px',
      lineHeight: '32px',
      padding: '16px 16px 4px 0px'
    },
    pointName: {
      fontSize: '12px',
      lineHeight: '12px'
    }
  });

interface FormValues {
  price: number;
}

interface Props extends WithStyles<typeof styles> {
  service: any;
  show: boolean;
  handleShowEditService: () => void;
  points: TradePoint[];
  updateServicePrice: (id: number, tradePointService: any) => void;
}

interface State {
  show: boolean;
  samePrice: boolean;
  price: number;
  tradePointPrices: {
    services: TradePointService[];
  };
}

export class ServiceEditDialog extends React.Component<Props, State> {
  readonly state: State = {
    show: true,
    samePrice: false,
    price: 0,
    tradePointPrices: {
      services: []
    }
  };

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    const { service } = nextProps;
    if (service) {
      let newTradePointServices = {
        services: []
      };
      service.services.map((price) => {
        let tradePointService: TradePointService = {
          id: service.id,
          name: service.name,
          price: price.price,
          time: service.time,
          tradePointId: price.tradePointId,
          parentId: price.parentId,
          pointName: price.pointName
        };
        newTradePointServices.services.push(tradePointService);
        this.setState({ tradePointPrices: newTradePointServices });
      });
      if (service.services) {
        let startPrice = service.services[0].price;
        if (service.services.every(price => price.price === startPrice)) {
          this.setState({ samePrice: true, price: startPrice });
        } else {
          this.setState({ samePrice: false, price: 0 });
        }
      }
    }
  }

  handleClose = (): void => {
    const { show, handleShowEditService } = this.props;
    if (show) {
      this.setState({ show: true });
    }
    this.setState({ samePrice: false, price: 0, tradePointPrices: { services: [] } });
    handleShowEditService();
  };

  handleSubmit = (values: FormValues) => {
    const { service, updateServicePrice, handleShowEditService, points } = this.props;
    const { tradePointPrices } = this.state;
    let newTradePointServices = {
      services: []
    };
    if (this.state.samePrice) {
      tradePointPrices.services.map((price) => {
        let tradePointService: TradePointService = {
          id: price.id,
          name: service.name,
          price: values.price,
          time: service.time,
          tradePointId: price.tradePointId,
          parentId: price.parentId
        };
        newTradePointServices.services.push(tradePointService);
      });
      updateServicePrice(service?.parentId || service?.id, newTradePointServices);
    } else {
      tradePointPrices.services.map((price) => {
        let tradePointService: TradePointService = {
          id: price.id,
          name: service.name,
          price: price.price,
          time: service.time,
          tradePointId: price.tradePointId,
          parentId: price.parentId
        };
        newTradePointServices.services.push(tradePointService);
      });
      updateServicePrice(service?.parentId || service?.id, newTradePointServices);
    }
    handleShowEditService();
  };

  handleCheck = () => {
    if (this.state.samePrice) {
      this.setState({ samePrice: false });
    } else {
      this.setState({ samePrice: true });
    }
  };

  handleChangePrice = (e, price) => {
    const { tradePointPrices } = this.state;
    let index = tradePointPrices.services.indexOf(price);
    let tradePointPrice = tradePointPrices;
    tradePointPrice.services[index] = {
      id: price.id,
      name: price.name,
      time: null,
      price: Number(e.target.value),
      tradePointId: price.tradePointId,
      pointName: price.pointName,
      parentId: price.parentId
    };
    this.setState({ tradePointPrices: tradePointPrice });
  };

  render = (): React.ReactNode => {
    const { service, show, classes, points } = this.props;
    const initialValues: FormValues = {
      price: this.state.price || 0
    };
    const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object({
      price: Yup.number().required(translate('error.required'))
    });
    return (
      <AdaptiveDialog
        open={show}
        onClose={this.handleClose}
        title={translate('servicesPage.editDialog.title')}
        closeButton={true}
        paperClass={classes.paper}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnChange={true}
          onSubmit={this.handleSubmit}
        >
          {({
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
              isValid
            }: FormikProps<FormValues>) => {
            return (
              <Form>
                <DialogContent className={classes.content}>
                  <Grid container={true} direction={'column'} spacing={2}>
                    <Grid item={true}>
                      <Typography>{service?.name}</Typography>
                    </Grid>
                    <Grid item={true}>
                      <FilledTextField
                        disabled={!this.state.samePrice}
                        name={'price'}
                        variant={'filled'}
                        required={true}
                        type={'number'}
                        value={values?.price}
                        label={translate('servicesPage.editDialog.price')}
                        error={touched.price && Boolean(errors.price)}
                        helperText={touched.price ? errors.price : ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setFieldTouched(e.target.name, true, false);
                          setFieldValue(e.target.name, e.target.value, true);
                        }}
                      />
                    </Grid>
                    <Grid item={true}>
                      <FormControlLabel
                        label={translate('servicesPage.editDialog.samePrice')}
                        control={
                          <Checkbox onChange={this.handleCheck} checked={this.state.samePrice} />
                        } />
                    </Grid>
                    {
                      this.state.samePrice == false ?
                        <Grid item={true}>
                          <Divider />
                          {this.state.tradePointPrices?.services?.map((price) => (
                            <Grid item={true} key={price.tradePointId}>
                              <Typography className={classes.points}>
                                {price.pointName}
                              </Typography>
                              <FilledTextField
                                name={'individualPrice'}
                                variant={'filled'}
                                required={true}
                                type={'number'}
                                value={price?.price}
                                label={translate('servicesPage.editDialog.price')}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  this.handleChangePrice(e, price);
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                        :
                        <Grid item={true}>
                        </Grid>
                    }
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.actions}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    disabled={!isValid}
                  >
                    <Translate content={'servicesPage.editDialog.save'} />
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </AdaptiveDialog>
    );
  };
}

const mapDispatchToProps = {
  getWarranty,
  clearItems,
  updateServicePrice
};

const mapStateToProps = (state) => ({
  items: state.warranty.itemsWarranty,
  points: state.points.items
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ServiceEditDialog));
