import { Button, createStyles, DialogContent, Grid, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import AdaptiveDialog from './AdaptiveDialog';

const styles = createStyles({
  content: {
    padding: '16px'
  }
});

interface WarningDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  content: React.ReactNode;
  onClose: (() => void);
}

const WarningDialog: React.FunctionComponent<WarningDialogProps> = (props: WarningDialogProps) => {
  const { open, content, onClose, classes } = props;
  return (
    <AdaptiveDialog
      open={open}
      onClose={onClose}>
      <DialogContent
        className={classes.content}>
        <Grid
          container={true}
          direction={'column'}
          spacing={3}>
          <Grid item={true}>
            {content}
          </Grid>
          <Grid
            item={true}
            style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={onClose}>
              {'Ok'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </AdaptiveDialog>
  );
};

export default withStyles(styles)(WarningDialog);
